import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeft, ChevronLeft, CredtCardIco, PixIco } from "../../../../components/Icons";

import style from './style.module.scss'

export function Payment() {
    return (
        <div>
            <>
                <main className={style.container}>
                    <div className={style.conteinerPaymentOptions}>
                        
                        <h1>Formas de pagamento</h1>
                        <div className={style.PaymentOption}>
                            <Link to="/pagamento" className={style.paimenType}>
                                <div>
                                    <CredtCardIco /> <span>Credito</span>
                                </div>
                                <button>
                                    <ArrowLeft />
                                </button>
                            </Link>
                        </div>
                        <div className={style.PaymentOption}>
                            <Link to="#!" className={style.paimenType}>
                                <div>
                                    <PixIco /> <span>Pix</span>
                                </div>
                                <button>
                                    <ArrowLeft />
                                </button>
                            </Link>
                        </div>
                    </div>

                </main>
            </>
        </div>
    )
}