import {useEffect, useState} from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import {Typography} from "@mui/material";
import {Order} from "../../../modelos/Order";
import {isAuthenticated} from "../../../services/AuthService";
import {getOrder} from "../../../services/OrderService";
import {Breadcrumbs} from "../../../components/my-account/breadcrumbs";
import {Sidebar} from "../../../components/my-account/sidebar";
import OrderPaymentResume from "../../../components/order/payment-resume/OrderPaymentResume";
import OrderItemResume from "../../../components/order/item-resume/OrderItemResume";
import {formatDateTime} from "../../../utils/utilities";
import OrderStatusStep from "../../../components/order/status-step/OrderStatusStep";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import myAccountStyles from '../styles.module.scss';
import styles from './styles.module.scss';

interface OrderIdParams {
    orderId: string
}

export default function MyOrder() {
    const { orderId } = useParams<OrderIdParams>();
    const [order, setOrder] = useState<Order>();
    const history = useHistory();
    
    useEffect(() => {
        if(isAuthenticated(true, true)){
            getCustomerOrder()
        } else {
            history.push('/login');
            return;
        }
    }, []);
    
    const getCustomerOrder = async () => {
        try {
            const orders: Order | undefined = await getOrder(orderId)
            if (orders) {
                setOrder(orders)
            } else {
                console.log("Sem pedidos no momento")
            }
            
        } catch (error) {
            console.log("Houve um erro ao buscar pedidos: ", error)
            if (error.response) {
                if (error.response.status !== 404) {
                    toast.error("Houve um erro ao buscar seus pedidos, tente novamente mais tarde!");
                }
            }
        }
    }
    
    const theme = createTheme({
        components: {
            // Name of the component
            MuiStepLabel: {
                styleOverrides: {
                    // Name of the slot
                    label: {
                        // Some CSS
                        fontSize: '12px',
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        fontSize: '150%',
                    },
                    text: {
                        // Some CSS
                        fontSize: '12px'
                    },
                },
            },
        },
    });
    
    return (
        <ThemeProvider theme={theme}>
            <div className={myAccountStyles.container}>
                <ToastContainer/>
                <Breadcrumbs list={[{name: 'Minhas Compras'}, {name: "Compra #"+orderId}]}/>
                <div className={styles.content}>
                    <Sidebar active="Minhas Compras"/>
                    {order && (
                        <main style={{fontSize: '100%'}}>
                            <Typography sx={{ width: '100%', mb: 4, fontSize: '2rem'}} variant={"h4"} className={styles.content}>
                                {'Resumo da compra #'+orderId} efetuada em {formatDateTime(order.dateCreated)}
                            </Typography>
                            <OrderStatusStep order={order}/>
                            <OrderItemResume order={order} />
                            <OrderPaymentResume paymentForm={order.paymentForm}/>
                        </main>
                    )}
                </div>
            </div>
        </ThemeProvider>
    );
}