import {Image} from "./Image";
import {Link} from "./Link";
import {Status} from "./StatusType";

export interface Banner {
    _id : string,
    name : string,
    title : string,
    description : string,
    image : {
        desktop : Image,
        mobile : Image
    },
    link : Link,
    type : BannerType,
    status : Status,
    dateCreated : string,
    lastUpdated : string
}

export enum BannerType {
    HOME_TOP = "HOME_TOP",
    HOME_MIDDLE = "HOME_MIDDLE",
    PRODUCT_MIDDLE = "PRODUCT_MIDDLE",
    NONE = "NONE"
}