import { removePunctuation } from "../services/RegisterService"

export const checkEmail = (email: string) => {
    var emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/
    return typeof email === 'string' && emailPattern.test(email)
}

export const buildDateWithoutTime = (year: number, month: number, day: number) => {
    return new Date(year, month, day)
}

export const formatDate = (str: string) => {
    const date = new Date(str)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    
    const _day = day < 10 ? `0${day}` : day
    const _month = month < 10 ? `0${month}` : month
    
    return `${_day}/${_month}/${year}`
}

export const formatHour = (str: string) => {
    const date = new Date(str)
    let hour = date.getHours()
    const minute = date.getMinutes()
    const seconds = date.getSeconds()

    const _hour = hour < 10 ? `0${hour}` : hour
    const _minute = minute < 10 ? `0${minute}` : minute
    const _seconds = seconds < 10 ? `0${seconds}` : seconds
    
    return `${_hour}:${_minute}:${_seconds}`
}

export const formatDateTime = (str: string) => {
    const date = new Date(str)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    let hour = date.getHours()
    const minute = date.getMinutes()
    const seconds = date.getSeconds()
    
    const _day = day < 10 ? `0${day}` : day
    const _month = month < 10 ? `0${month}` : month
    const _hour = hour < 10 ? `0${hour}` : hour
    const _minute = minute < 10 ? `0${minute}` : minute
    const _seconds = seconds < 10 ? `0${seconds}` : seconds
    
    return `${_day}/${_month}/${year} ${_hour}:${_minute}:${_seconds}`
}

export const cpfMask = (value: string) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const phoneMask = (phone: string) => {
    let temp = removePunctuation(phone);

    if(temp.length > 0) temp = '(' + temp;
    if(temp.length > 3) temp = temp.substr(0,3) + ') ' + temp.substr(3);
    if(temp.length > 10) temp = temp.substr(0,10) + '-' + temp.substr(10);

    return temp;
}

export const onlyLettersAndNumbers = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/g, "").replace(' ', '');
}