import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router";
import { CredtCardIco } from "../../../components/Icons";
import api from "../../../services/api";
import style from './style.module.scss';
import StorageUtils from "../../../utils/StorageUtils";
import {Link} from "react-router-dom";
import {isAuthenticated} from "../../../services/AuthService";
import {cardMask, ccvMask, dateMask, PaymentForm} from "../../../services/CardService";
import {Context, ContextProvider} from "../../../contexts/ContextProvider";
import {findCartByID, findPaymentOptions} from "../../../services/CartService";
import CartUtils from "../../../utils/CartUtils";
import {CartVO, PaymentCard, PaymentInstallment, PaymentOption, PaymentOptions} from "../../../modelos/Cart";
import styles from "../../buy-coins/style.module.scss";

type CardProps = {
    brand: string
    dateCreated: Date
    expirationMonth: string
    expirationYear: string
    id: string
    main: false
    maskedNumber: string
    ownerName: string
    type: string
}

type InstallmentOptions = {
    installment: number,
    value: number
}

type itemsCart = {
    items: [
        {
            code: string,
            pack: {
                name: string,
                shortDescription: string,
                type: string,
                costPrice: 50.0,
                fees: 2,
                feesDescription: string,
                totalPrice: 52.0,
                coins: 70,
                _id: string
            },
            price: number,
            quantity: number,
            subtotal: number,
            discount: number,
            fees: number,
            totalPrice: number,
            earnedCoins: number
        }
    ],
    subTotal: number,
    feesTotal: number,
    freightTotal: number,
    freightDiscount: number,
    itemDiscount: number,
    totalPrice: number,
    earnedCoins: number,
    orderCreated: boolean,
    _id: string

}

export function CreditMethod() {
    const [contentCart, setContentCart] = useState<CartVO>();
    const [paymentOptions, setPaymentOptions] = useState<PaymentOptions>();
    const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption>();
    const [choosenInstallment, setChoosenInstallment] = useState(1)
    
    // START: FORM ADD CADD
    const [addCard, setAddCard] = useState(false);
    const [numberCard, setNumberCard] = useState('')
    const [nameCard, setNameCard] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [saveCard, setSaveCard] = useState(false);
    
    const context = useContext(Context)
    const Router = useHistory();
    // END FORM ADD CADD
    
    const brands = [
        {
            id: '4',
            name: 'Visa',
            ico: ''
        },
        {
            id: '5',
            name: 'MasterCard',
            ico: ''
        },
        {
            id: '6',
            name: 'Discover Card',
            ico: ''
        },
        {
            id: '37',
            name: 'American Express',
            ico: ''
        },
        {
            id: '38',
            name: 'Diners Club',
            ico: ''
        },

    ]
    useEffect(() => {
        if(isAuthenticated(true, true)) {
            //getRegisteredCards();
            getCartByID();
            getPaymentOptions();
        }
    }, [])
    
    useEffect(() => {
        if(selectedPaymentOption) {
            selectedPaymentOption.choosenInstallment = choosenInstallment
            setSelectedPaymentOption(selectedPaymentOption)
        }
    }, [choosenInstallment])

    const handleSelectedPaymentOption = (paymentOption : PaymentOption) => {
        // setSelectedCard(card)
        setCvv('')
        setSelectedPaymentOption(paymentOption)
        if(paymentOption.savedCard) {
            handleAddCard(false)
        } else {
            handleAddCard(true)
        }
        context.setPaymentOption(paymentOption)
    }

    const handleAddCard = (isAddCard : boolean = false) => {
        setAddCard(isAddCard)
    }
    
    const getPaymentOptions = async () => {
        if(CartUtils.getLocalCartId()) {
            try {
                const paymentOptions = await findPaymentOptions(CartUtils.getLocalCartId())
                if(paymentOptions) {
                    setPaymentOptions(paymentOptions)
                } else {
                    console.log("Sem opções de pagamento disponível para este carrinho")
                }
            
            } catch (error) {
                console.log("Erro ao buscar opções de pagamento", error)
                Router.push("/carrinho")
            }
            
        } else {
            console.log("Sem ID do carrinho para efetuar busca das opções de pagamento")
            CartUtils.clearCartLocal()
            Router.push("/carrinho")
        }
    }

    const CurrentBrand = () => {
        let idx = numberCard
        let brand = ""
        for (let i = 0; i < brands.length; i++) {
            if (brands[i].id === idx.slice(0, 1) || idx.slice(0, 2)) {
                brand = brands[i].name
            }
        }
        return brand
    }

    const getCartByID = async () => {
        if(CartUtils.getLocalCartId()) {
            try {
                const cart = await findCartByID(CartUtils.getLocalCartId())
                if(cart) {
                    setContentCart(cart)
                } else {
                    console.log("Carrinho não encontrado")
                    CartUtils.clearCartLocal()
                    Router.push("/carrinho")
                }
            
            } catch (error) {
                console.log("Houve um erro ao buscar carrinho pelo ID", error)
                Router.push("/carrinho")
            }
        } else {
            console.log("Sem ID do carrinho para efetuar busca")
            CartUtils.clearCartLocal()
            Router.push("/carrinho")
        }
    }
    
    const handleChoosenInstallment = (installment : string) => {
        setChoosenInstallment(Number(installment))
    }

    function handleCheckout() {
        //TODO - Adicionar validação se todos os campos do form foram preenchidos e são válidos
        let paymentCard : PaymentCard | null = null
        if(addCard){
            paymentCard = {
                ownerName: nameCard,
                number: numberCard,
                cvv: cvv,
                brand: CurrentBrand(),
                expirationMonth: dueDate.slice(0, 2),
                expirationYear: dueDate.slice(3, 7),
                type: 'CREDIT',
                savedCard: false,
                saveCard: saveCard,
            }
            
        } else {
            if(selectedPaymentOption?.paymentCard) {
                paymentCard = selectedPaymentOption.paymentCard
                paymentCard.cvv = cvv
                paymentCard.saveCard = false
                paymentCard.savedCard = true
            }
        }
        
        if(paymentCard) {
            let paymentOption : PaymentOption
            if(selectedPaymentOption) {
                paymentOption = selectedPaymentOption
                paymentOption.paymentCard = paymentCard
                paymentOption.choosenInstallment = choosenInstallment
        
                setSelectedPaymentOption(paymentOption)
                context.setPaymentOption(paymentOption)
            }
        }
        
        Router.push('/confirmacao-pagamento')
    }

    return (
        <div className="container">
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.contentLeft}>
                        <div className={style.options}>
                            <div className={style.row}>
                                <strong className={style.title}>Formas de pagamento</strong>
                                {paymentOptions && (
                                    paymentOptions.paymentOptions.map(paymentOption => {
                                        return (
                                            <div key={paymentOption.code} className={style.col}>
                                                <label htmlFor={paymentOption.code} className={style.cardStyle} >
                                                    <input onClick={()=> handleSelectedPaymentOption(paymentOption) } type="radio" name="paymentOption" id={paymentOption.code} />
                                                    <div className={style.cardIconContainer}>
                                                        <CredtCardIco />
                                                        <div className={style.flagCard}>
                                                            {paymentOption.savedCard ?
                                                                (<span>{paymentOption?.paymentCard?.brand} - {paymentOption?.paymentCard?.maskedNumber}</span>)
                                                                :
                                                                (<span>Novo Cartão de Crédito</span>)
                                                            }
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                    })
                                )}
    
                                <div className={style.col}>
                                    
                                        <div className={style.addNewCard}>
                                            {/*<h1>Cartão de crédito</h1>*/}
                                            <div className={style.layoutContainer}>
                                                <form>
                                                    {selectedPaymentOption && (
                                                        <>
                                                            <label htmlFor="">Parcelas</label>
                                                            <select onChange={event => handleChoosenInstallment(event.target.value)}>
                                                                {selectedPaymentOption?.installmentOptions ? selectedPaymentOption.installmentOptions.map(installmentOption => {
                                                                    return (
                                                                        <option
                                                                            key={installmentOption.installment}
                                                                            value={installmentOption.installment}>
                                                                            {installmentOption.installment}x {installmentOption.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                        </option>
                                                                    )
                                                                }) : (
                                                                    <option
                                                                        key={0}
                                                                        value={choosenInstallment}>
                                                                        1x {contentCart?.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                    </option>
                                                                )}
                                                            </select>
    
                                                            {!addCard && (
                                                                <>
                                                                    <label htmlFor="card_cvv">
                                                                        <span className={style.noMarginLeft}>Código de Segurança (CVV)</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        onChange={event => setCvv(ccvMask(event.target.value))}
                                                                        value={cvv}
                                                                        id="card_cvv"
                                                                        placeholder="000"
                                                                        minLength={3}
                                                                        maxLength={4}
                                                                        required
                                                                    />
                                                                </>
    
                                                            )}
                                                        </>
                                                    )}
                                                    
                                                    {addCard && (
                                                        <>
                                                            <label htmlFor="">Número do cartão</label>
                                                            <input
                                                                type="text"
                                                                // onChange={event => setNumberCard(event.target.value)}
                                                                onChange={event => setNumberCard(cardMask(event.target.value))}
                                                                value={numberCard}
                                                                id="card_number"
                                                                placeholder="0000 0000 0000 0000"
                                                                minLength={17}
                                                                maxLength={19}
                                                                required
                                                            />
    
                                                            <label htmlFor="">
                                                                Nome completo
                                                                <small style={{ fontSize: '1.2rem', marginLeft: '5px'}}>(Como está no cartão)</small>
                                                            </label>
    
                                                            <input
                                                                type="text"
                                                                placeholder="Nome impresso no cartão"
                                                                onChange={event => setNameCard(event.target.value)}
                                                                value={nameCard}
                                                                id="card_name"
                                                                minLength={5}
                                                                style={{ flex: 1 }}
                                                                required
                                                            />
    
                                                            <div>
                                                                <label htmlFor="">
                                                                    <span className={style.noMarginLeft}>Validade</span>
                                                                    <input
                                                                        type="text"
                                                                        onChange={event => setDueDate(dateMask(event.target.value))}
                                                                        value={dueDate}
                                                                        id="card_validity"
                                                                        placeholder="00/0000"
                                                                        minLength={7}
                                                                        maxLength={7}
                                                                        required
                                                                    />
                                                                </label>
        
                                                                <label htmlFor="">
                                                                    <span className={style.noMarginLeft}>Código de Segurança (CVV)</span>
                                                                    <input
                                                                        type="text"
                                                                        onChange={event => setCvv(ccvMask(event.target.value))}
                                                                        value={cvv}
                                                                        id="card_cvv"
                                                                        placeholder="000"
                                                                        minLength={3}
                                                                        maxLength={4}
                                                                        required
                                                                    />
                                                                </label>
                                                            </div>
    
                                                            <label htmlFor="saveCard">
                                                                <input
                                                                    className={style.noMarginLeft}
                                                                    type="checkbox"
                                                                    name=""
                                                                    id="saveCard"
                                                                    onChange={event => setSaveCard(event.target.checked)}
                                                                />
                                                                Salvar cartão para compras futuras.
                                                            </label>
                                                        </>
                                                    )}
                                                </form>
            
                                            </div>
                                        </div>
    
                                </div>
                                
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className={style.contentRight}>

                        <div className={style.orderSummary}>

                            <strong className={style.title}>Resumo da seleção</strong>

                            <table>
                                {contentCart && contentCart.items.map((content, index) => {
                                    return (
                                        <tr key={`item-${index}`}>
                                            <th>{content.pack.name} {content.pack.shortDescription}</th>
                                            <td>{content.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>

                                    )
                                })}
                            </table>
                            <br />
                            <table style={{ border: 'none', marginBottom: '3rem' }}>
                                <tr>
                                    <th>Sub-total : ({contentCart?.items.length}{contentCart && contentCart.items.length > 1 ? 'produtos' : 'prosuto'}) </th>
                                    <td>{contentCart?.subTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                                {contentCart?.feesTotal &&
                                    (<tr>
                                        <th>Taxa de serviço</th>
                                        <td>{contentCart?.feesTotal.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</td>
                                    </tr>)
                                }
                                {contentCart?.itemDiscount &&
                                    (<tr>
                                        <th>Total de desconto:</th>
                                        <td>{contentCart?.itemDiscount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>)
                                }
                                <tr style={{ fontSize: '1.2rem' }}>
                                    <th>Total:</th>
                                    <td>{contentCart?.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                               
                            </table>
                            <button onClick={()=>handleCheckout()} className={style.action}>Avançar</button>
                            <Link to="/easycoins">Continuar comprando</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}