import { Repet } from "../repet"
import style from './style.module.scss'
interface Props {
    vote: number;
    content: string;
    name: string;
}

export const TestimonyCard = ({vote, content,  name}:Props) => {
    return (
        <div className={style.card}>
            <div className={style.star}>
                <Repet qtd={vote}>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.37169 0.553711L10.7577 5.38757L16.0935 6.16748L12.2326 9.928L13.1438 15.2406L8.37169 12.731L3.59961 15.2406L4.51078 9.928L0.649872 6.16748L5.98565 5.38757L8.37169 0.553711Z" fill="#FEBB22" />
                    </svg>
                </Repet>
            </div>
            <div className={style.txt} dangerouslySetInnerHTML={{ __html: content }} />
            <strong>{name}</strong>
        </div>

    )

}