import {isAuthenticated} from "./AuthService";
import StorageUtils from "../utils/StorageUtils";
import api from "./api";
import {Wallet} from "../modelos/Wallet";

export const getWallet = async () : Promise<Wallet | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            const userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            const response = await api.get(`/p/wallet`,
                { headers: { "Authorization": `Bearer ${userToken}` } }
            )
            
            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}