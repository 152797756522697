import React from 'react';
import {ContainerText, HeadTitle} from './styles'
import { useHistory } from "react-router-dom";
import styles from "../registers/styles.module.scss";
import { BreadCrumbs } from '../../components/BreadCrumbs';
const InfoPage = (props: any) => {
    let history = useHistory();
    const info = props.location.state
    if (!info) {
        history.push("/");
    }
    const crumbContent = [
        {
            name: 'Pagina inicial',
            route: '/'
        },
        {
            name: 'Saiba mais',
            route: '/saiba-mais'
        }
    ]
    return (
        <div className="container">
            <BreadCrumbs content={crumbContent} />
            <div className={styles.container}>
            {info && (
                <div style={{ marginTop: '50px', marginBottom: '50px' }}>
                    <HeadTitle>{info.title}</HeadTitle>
                    &nbsp;
                    <ContainerText dangerouslySetInnerHTML={{ __html: info.description }} />
                </div>
            )}
            </div>
        </div>
    )
}

export default InfoPage