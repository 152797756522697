class StorageUtils {
    
    public static KEYS = {
        USER_TOKEN: 'userToken',
        USER_CARD: 'userCard',
        CART_ID: 'cartId',
        CART_ITEMS: 'cartItems',
        CART: "cart",
        CHECKOUT: 'checkout'
    
    }
    
    public static setStorageItem = (index: string, value: string) => {
        localStorage.setItem(index, value)
    }
    
    public static getStorageItem = (index:string) => {
        return localStorage.getItem(index)
    }
    
    public static removeStorageItem = (index: string) => {
        localStorage.removeItem(index)
    }
}

export default StorageUtils