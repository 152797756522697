import axios from "axios";

export interface NewsletterRequest {
    email: string;
    origin: string;
    originDescription?: string;
    source: string;
    sourceDescription?: string;
    
}

/**
 * Email sending and validation to siding page api
 *
 * @param newsletter
 * @returns
 */
export const register = async (newsletter: NewsletterRequest) => {
    let req = await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/api/o/newsletter/`, 
        JSON.stringify(newsletter),
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    );
    
    return req.data;
}