import styled from 'styled-components';
import { Col } from 'reactstrap'

export const HeaderContainer = styled(Col)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: ${ props => props.isbigheader ? '188px' : '84px'};
    align-items: flex-start;
    padding-top: 36px;
    padding-left: 15px;
    padding-right: 15px;
    background: var(--color-header);
    
    @media(min-width: 700px){
        height: ${ props => props.isbigheader ? '277px' : '120px'};
        color: #FFF;
    }
`
export const HeaderBox = styled.div`
    display: flex;
    align-items: center;
`
export const MobileIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 21px;

    @media(min-width: 700px){
        display: none;
    }
`
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    cursor: pointer;

    @media(min-width: 700px){
        width: 204px;
        height: 44px;
        margin-left:160px;
    }
`;

export const ImgBox = styled.div`
    border: 1px solid #313641;
    margin-left: 13px;
    border-radius: 4px;
    padding: 5.64px 5.74px;

    @media(min-width: 700px){
        padding: 7.75px 7.76px;
    }
`;

export const Logo = styled.img`
    width: 21.09px;
    height: 20.72px;

    @media(min-width: 700px){
        width: 28.46px;
        height: 28.49px;
    }
`;

export const Search = styled.img`
    margin-right: 6px;
    cursor: pointer;
`;

export const SearchBar = styled.div`
    
    @media(min-width: 700px){
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 550px; 
        height: 40px;
        background: #262A34;
        border-radius: 8px; 
    }
`;

export const InputFromSearch = styled.input`
    display: none;

    @media(min-width: 700px){
        display: block;
        background: var(--color-primary-light);
        border:none; 
        color: var(--color-text-medium);
        width: 480px;
        font-size: 14px;
        line-height: 16px;
        height: 90%;
        &:focus{
            outline:none;
        }
    }
`
export const Line = styled.img`
    display: none;

    @media(min-width: 700px){
        display: block;
    }

`;