import { FormEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumbs } from "../../../components/my-account/breadcrumbs";
import { Sidebar } from "../../../components/my-account/sidebar";
import { signOut } from "../../../services/AuthService";
import { cpfValidationAlgorithm, removePunctuation } from "../../../services/RegisterService";
import { getCustomerByToken, RequestUpdateUser, updatePassword, updateUser } from "../../../services/UserService";
import { cpfMask, phoneMask } from "../../../utils/utilities";

import fatherStyles from '../styles.module.scss';
import styles from './styles.module.scss';

interface User{
  email: string,
  fullName: string,
  nickname: string,
  birthday: string,
  cpfCnpj: string,
  telephones?: {
    ddi: string,
    ddd: string,
    number: string,
    main: boolean,
    mobile: boolean,
    whatsapp: boolean
  }[]
}

type AvailableFields = 'name' | 'birthdate' | 'cpf' | 'phone';

export default function MyCadastre(){
  const [user, setUser] = useState<User>();
  const [userToken, setUserToken] = useState<string>();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const [changingFields, setChangingFields] = useState<AvailableFields[]>([]);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if(!token){
      history.push('/login');
      return;
    }
    setUserToken(token);
    getUserData(token);
  },[]);

  useEffect(() => {
    if(user){
      setName(user.fullName);
      setEmail(user.email);
      setBirthdate(user.birthday);
      setCpf(cpfMask(user.cpfCnpj ?? ''));

      if(user.telephones && user.telephones.length > 0){
        setPhone(`(${user.telephones[0].ddd}) ${user.telephones[0].number}`);
        setIsWhatsapp(user.telephones[0].whatsapp);
      }
      else{
        setPhone('');
        setIsWhatsapp(false);
      }
    }
  },[user]);

  async function getUserData(token: string){
    try{
      const data = await getCustomerByToken(token);
      setUser(data);
    }catch(err: any){
      signOut();
      history.push('/login');
    }
  }

  function handleToggleFieldInChanging(fieldName: AvailableFields, event: any){
    if(!user) return;
    let index = changingFields.findIndex(field => field === fieldName)
    if(index === -1){
      setChangingFields([...changingFields, fieldName]);
      event.target.previousSibling.focus();
    }else{
      setChangingFields([...changingFields.filter(field => field !== fieldName)]);
      switch(fieldName){
        case 'name': setName(user.fullName ?? ''); break;
        case 'birthdate': setBirthdate(user.birthday ?? ''); break;
        case 'cpf': setCpf(cpfMask(user.cpfCnpj ?? '')); break;
        case 'phone':
          if(user.telephones && user.telephones.length > 0){
            setPhone(`(${user.telephones[0].ddd}) ${user.telephones[0].number}`);
            setIsWhatsapp(user.telephones[0].whatsapp);
          }
          else{
            setPhone('');
            setIsWhatsapp(false);
          }
          break;
      }
    }
  }

  async function handleSubmit(event: FormEvent){
    event.preventDefault();

    if(!user || !userToken) return ;
    if(changingFields.length === 0){
      toast.error('Nenhum campo foi editado');
      return;
    }

    var data = {} as RequestUpdateUser;
    if(changingFields.find(field => field === 'name')){
      data['fullName'] = name;
    }
    if(changingFields.find(field => field === 'birthdate')){
      data['birthday'] = birthdate;
    }
    if(changingFields.find(field => field === 'cpf')){
      if(!cpfValidationAlgorithm(cpf)){
        toast.error("CPF inválido");
        return;
      }
      data['cpfCnpj'] = removePunctuation(cpf);
    }

    if(changingFields.find(field => field === 'phone')){
      let parsePhone = removePunctuation(phone);
      data['telephones'] = [{
        ddi: '+55',
        ddd: parsePhone.substr(0,2),
        number: `${parsePhone.substr(2, parsePhone.length - 6)}-${parsePhone.substr(-4)}`,
        whatsapp: isWhatsapp
      }];
    }

    const response = await updateUser(data, userToken);

    if(!response.result) toast.error(response.response);
    else{
      toast.success(response.response);
      
      await getUserData(userToken);
      setChangingFields([]);
    }
  }
  return (
    <div className={fatherStyles.container}>
      <ToastContainer/>
      <Breadcrumbs list={isChangingPassword ? [{
        name: 'Meu Cadastro',
        fn: () => setIsChangingPassword(false)
      },{
        name: 'Alterar Senha'
      }]:[{
        name: 'Meu Cadastro',
      }]}/>
      <div className={styles.content}>
        <Sidebar active="Meu cadastro"/>
        <main>
          <h3 className={styles.title}>
            {isChangingPassword ? 'Alterar Senha': 'Meu cadastro'}
          </h3>
          {isChangingPassword && userToken && user ? (
            <ChangePassword
              token={userToken}
              email={user.email}
              goBack={() => setIsChangingPassword(false)}
            />
          ):(
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label htmlFor="name">Nome completo</label>
                <div className={styles.inputGroup}>
                  <input
                    type="text"
                    placeholder="Nome completo..."
                    id="name"
                    value={name}
                    minLength={5}
                    onChange={e => setName(e.target.value)}
                    readOnly={!changingFields.find(field => field === 'name')}
                    required={!!changingFields.find(field => field === 'name')}
                  />
                  <button
                    type="button"
                    onClick={(e) => handleToggleFieldInChanging('name', e)}
                    className={changingFields.find(field => field === 'name') ? styles.noChange : ''}
                  >
                    {changingFields.find(field => field === 'name') ? 'x': 'Alterar'}
                  </button>
                </div>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  placeholder="E-mail..."
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className={styles.readOnly}
                  readOnly
                />
              </div>
              <div className={styles.formGroup}>
                <label>Data de nascimento</label>
                <div className={styles.inputGroup}>
                  <input
                    type="date"
                    placeholder="00/00/0000"
                    value={birthdate}
                    onChange={e => setBirthdate(e.target.value)} 
                    readOnly={!changingFields.find(field => field === 'birthdate')}
                    required={!!changingFields.find(field => field === 'birthdate')}
                  />
                  <button
                    type="button"
                    onClick={(e) => handleToggleFieldInChanging('birthdate', e)}
                    className={changingFields.find(field => field === 'birthdate') ? styles.noChange : ''}
                  >
                    {changingFields.find(field => field === 'birthdate') ? 'x': 'Alterar'}
                  </button>
                </div>
              </div>
              <div className={styles.formGroup}>
                <label>CPF</label>
                <div className={styles.inputGroup}>
                  <input
                    type="text"
                    placeholder="000********"
                    value={cpf}
                    maxLength={14}
                    onChange={e => setCpf(cpfMask(e.target.value))}
                    readOnly={!changingFields.find(field => field === 'cpf')}
                    required={!!changingFields.find(field => field === 'cpf')}
                  />
                  <button
                    type="button"
                    onClick={(e) => handleToggleFieldInChanging('cpf', e)}
                    className={changingFields.find(field => field === 'cpf') ? styles.noChange : ''}
                  >
                    {changingFields.find(field => field === 'cpf') ? 'x': 'Alterar'}
                  </button>
                </div>
              </div>
              <div className={styles.formGroup}>
                <label>Telefone</label>
                <div className={styles.inputGroup}>
                  <input
                    type="text"
                    placeholder="(00) 00000-0000"
                    value={phone}
                    minLength={14}
                    maxLength={15}
                    onChange={e => setPhone(phoneMask(e.target.value))}
                    readOnly={!changingFields.find(field => field === 'phone')}
                    required={!!changingFields.find(field => field === 'phone')}
                  />
                  <button
                    type="button"
                    onClick={(e) => handleToggleFieldInChanging('phone', e)}
                    className={changingFields.find(field => field === 'phone') ? styles.noChange : ''}
                  >
                    {changingFields.find(field => field === 'phone') ? 'x': 'Alterar'}
                  </button>
                </div>
                <label style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
                  <input
                    type="checkbox"
                    checked={isWhatsapp}
                    onClick={() => !!changingFields.find(field => field === 'phone') ? setIsWhatsapp(!isWhatsapp): ''}
                    style={{ width: 'fit-content', marginRight: '10px' }}
                  />
                  Este número tem whatsapp
                </label>
              </div>
              <button type="submit" style={{ marginBottom: '10px'}}>Salvar alterações</button>
              <div className={styles.formGroup}>                
                <div className={styles.inputGroup}>
                  <button
                    type="button"
                    onClick={() => setIsChangingPassword(true)}
                    style={{
                      width: '100%',
                      border: '1px solid #E1E8F3',
                      padding: '10px 27px',
                      position: 'initial',
                      marginBottom: '42px'
                    }}
                  >Alterar Senha</button>
                </div>
              </div>
            </form>
          )}
        </main>
      </div>
    </div>
  );
}

interface ChangePasswordProps{
  token: string;
  email: string;
  goBack: () => void;
}
function ChangePassword({ token, email, goBack }: ChangePasswordProps){
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if(newPassword !== confPassword){
      toast.error("As senhas não são compatíveis!");
      return;
    }

    const response = await updatePassword({
      email,
      password: currentPassword,
      pass: newPassword,
      checkPass: confPassword
    }, token);

    if(!response.result) toast.error(response.response);
    else toast.success(response.response);
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="current-password">
          Senha Atual
        </label>
        <input
          type="password"
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          id="current-password"
          placeholder="Senha atual..."
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="new-password">
          Nova Senha
        </label>
        <input
          type="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          id="new-password"
          placeholder="Senha nova..."
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="conf-password">
          Confirmar Senha
        </label>
        <input
          type="password"
          value={confPassword}
          onChange={e => setConfPassword(e.target.value)}
          id="conf-password"
          placeholder="Confirmar senha..."
          required
        />
      </div>
      <button type="submit" style={{ marginBottom: '16px' }}>Salvar alterações</button>
      <button type="button" onClick={goBack} className={styles.goBack}>Voltar</button>
    </form>
  );
}