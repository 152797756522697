import api from "./api";
import {Banner, BannerType} from "../modelos/Banner";

export const getBanners = async () => {
    try {
        let response = await api.get('/o/banner?type=',
            {
                headers: {
                    "Content-Type": 'application/json',
                }
            }
        )
        return response.data;
        
    } catch (error) {
        throw new Error(error)
    }
}

export const getBannersByType = async (type: BannerType) : Promise<Banner[] | undefined> => {
    try {
        let response = await api.get('/o/banner?type='+type,
            {
                headers: {
                    "Content-Type": 'application/json',
                }
            }
        )
        return response.data;
        
    } catch (error) {
        throw new Error(error)
    }
}