import {getDecodedToken, isAuthenticated} from "../services/AuthService";

class AuthUtils {
    
    public static getCustomerID = () => {
        if(isAuthenticated(true, true)) {
            const decodedToken = getDecodedToken()
            return decodedToken.id
        }
    }
}

export default AuthUtils