import React, { useState, useEffect } from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
    ArticleBox,
    ArticleContainer,
    ArticleTitle,
    Container,
    CustomizedButton,
    Description,
    Separator,
    Subtitle,
    CustomizedTitle
} from './styles'

import { Search } from '../../components/headers/common/styles'
import { MobileInputFromSearch, MobileSearchBar } from '../../components/common/bars/styles'
import line11 from '../../assets/line11.svg'
import line54 from '../../assets/lines/line54.svg'
import separator from '../../assets/lines/menu-vertical-separator.svg'
import forward from '../../assets/arrow-forward.svg'
import email from '../../assets/email.svg'
import wpp from '../../assets/whatsapp.svg'
import search from '../../assets/search.svg'
import { BreadCrumbs } from '../../components/BreadCrumbs'

const HelpCenter = () => {
    const [enableArticle, setEnableArticle] = useState(false)
    const [enableHelpers, setEnableHelpers] = useState(true)
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (isDesktopWindow()) {
            setEnableArticle(true)
            setEnableHelpers(true)
        }else{
            setEnableArticle(false)
            setEnableHelpers(true)
        }
    }, [width])

    const isDesktopWindow = () => {
        return width > 700
    }
    const crumbContent = [
        {
            name: 'Pagina inicial',
            route: '/'
        },
        {
            name: 'Central de ajuda',
            route: '/informa'
        }
    ]
    return (
        <div className="container">
             <BreadCrumbs content={crumbContent} />
                {enableHelpers &&
                    (
                        <div>
                            <CustomizedTitle>Central de ajuda</CustomizedTitle>
                            <Description>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh lacus, feugiat venenatis lectus lacinia, condimentum sodales justo.</Description>
                            <MobileSearchBar style={{ margin: '24px auto' }}>
                                <MobileInputFromSearch />
                                <img src={line11} alt={"linha de separação"} />
                                <Search src={search} alt={"lupa"} />
                            </MobileSearchBar>
                            <Subtitle>Assuntos mais procurados</Subtitle>
                            {
                                [1, 2, 3, 4].map((item, idx) => (
                                    <ArticleContainer key={`#${item}-article`} isFirstItem={idx === 0}>
                                        <ArticleBox>
                                            <ArticleTitle>
                                                Lorem ipsum dolor sit amet
                                            </ArticleTitle>
                                            <Description style={{ margin: 0 }}>consectetur adipiscing elit. In nibh...</Description>
                                        </ArticleBox>
                                        <img src={line54} alt={'separador de informações'} />
                                        <img style={{ marginRight: '21px' }} src={forward} alt={'seta para direita'} />
                                    </ArticleContainer>
                                ))
                            }
                            <CustomizedTitle style={{color:'#828BA1', fontSize:'20px'}}>Não encontrou o que procura?</CustomizedTitle>
                            <Subtitle style={{ marginTop: '11px' }}>Fale com a gente</Subtitle>
                            <CustomizedButton color={'success'}>
                                <img style={{ marginRight: '16px' }} src={email} alt={"email"} />
                                Enviar e-mail
                            </CustomizedButton>
                            <CustomizedButton color={'success'}>
                                <img style={{ marginRight: '16px' }} src={wpp} alt={"whatsapp"}/>
                                Chat via Whatsapp
                            </CustomizedButton>
                        </div>
                    )}
                <Separator src={separator} alt="separador de conteúdeos" />
                {
                    enableArticle && (
                        <div>
                            <CustomizedTitle>Lorem ipsum dolor sit amet</CustomizedTitle>
                            <Description>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh lacus, feugiat venenatis lectus lacinia, condimentum sodales justo. In pharetra pretium porta. Morbi consequat sed urna a dictum. Etiam fringilla, ligula ut commodo bibendum, tortor dolor maximus nulla, sed luctus nunc ante in metus. Quisque nisl felis, consequat et blandit at, venenatis a enim. Sed pretium posuere erat at egestas. Aenean justo nulla, accumsan vel diam at, tincidunt accumsan enim.</p>

                                <p>Nam rhoncus sapien non risus luctus rutrum. Ut laoreet ex non tempus rhoncus. Fusce pulvinar eget velit ac ornare. Phasellus lacus purus, mollis non tempus quis, euismod in eros. Duis fermentum ut est id congue. Mauris quis leo tristique, egestas lectus in, dignissim elit. Vivamus semper luctus varius. Aenean non massa aliquam, mattis elit id, bibendum elit. Morbi sagittis quam erat, ac luctus sem congue id. Aliquam varius nunc a quam commodo laoreet.</p>
                            </Description>
                        </div>
                    )
                }

           
        </div>)
}

export default HelpCenter