import styled from 'styled-components'
import { SubTitle, Title } from '../../components/common/styles/styles'

export const HeadTitle = styled(Title)`
    margin: 54px 42px 0px 42px;
    text-align: center;
    color: var(--color-text-medium);
    @media(min-width: 700px){
        margin: 24px 0px 0px 0px;
        color: var(--color-text-dark);
    }
`

export const Subtitle = styled(SubTitle)`
    margin: 16px 42px 0px 42px;
    display: inline-block;
    text-align: center;
    color: var(--color-text-medium);
    @media(min-width: 700px){
        margin: 11px 0px 0px 0px;
    }
`
export const ImageBox = styled.div`
    margin-top:64px;
    display: flex;
    justify-content: center;
    @media(min-width: 700px){
        margin-top: 36px;
    }
`

export const AuctionFavourites = styled.h1`
    margin: 24px 0px 5px 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-medium);
`;
interface ContainerProductProps{
    flexDirection: 'row' | 'column'
}
export const ContainerProduct = styled.div<ContainerProductProps>`
    display: flex;

    ${(props) => props.flexDirection === 'row' ? `
            flex-direction: ${props.flexDirection};
            > div{
                min-width: 340px;
            }
            overflow-x: auto;
            gap: 16px;

            @media(max-width: 450px){
                flex-direction: column;
                overflow-x: hidden;
            }
        `:`
            flex-direction: ${props.flexDirection};
        `
    }

    align-items: center;
    margin-top: 36px;
    margin-bottom: 36px;

    @media(min-width: 700px){
        margin: 0;
        align-items: flex-start;
    }
`