import React, { useState } from 'react'
import {
    AuctionBox,
    AuctionHeader,
    AuctionHeaderBox,
    AuctionText,
    StatusBox,
    StatusContainer,
    StatusText
} from './styles'
import line54 from '../../assets/lines/line54.svg'
import { BreadCrumbs } from '../../components/BreadCrumbs'
const crumbContent = [
    {
        name: 'Pagina inicial',
        route: '/'
    },
    {
        name: 'Leilão',
        route: '/leilao'
    },
    {
        name: 'status',
        route: '/leilao/status'
    }
]
const AuctionStatus = () => {
    const [currentStatus, setCurrentStatus] = useState('in_progress')
    return (
        <div className="container">
            <BreadCrumbs content={crumbContent} />
            <StatusContainer>
                {
                    [
                        { name: 'in_progress', label: 'Em andamento' },
                        { name: 'finished_off', label: 'Arrematados' },
                        { name: 'closed', label: 'Encerrados' },
                    ].map((status, idx) =>
                    (
                        <StatusBox key={`${idx}-${status}`}>
                            <StatusText onClick={() => setCurrentStatus(status.name)} color={currentStatus === status.name ? 'up' : 'down'}>{status.label}</StatusText>
                        </StatusBox>
                    ))
                }
            </StatusContainer>
            <AuctionHeader>
                <AuctionHeaderBox>
                    <span>Leilão</span>
                </AuctionHeaderBox>
                <AuctionHeaderBox>
                    <span>Data</span>
                </AuctionHeaderBox>
            </AuctionHeader>
            {[
                { description: 'Lorem ipsum dolor sit amet, consectetur.', date: '11/06/2021 às 09:30h Você fez: 2 lances' },
                { description: 'Lorem ipsum dolor sit amet, consectetur.', date: '11/06/2021 às 09:30h Você fez: 2 lances' }
            ].map((auction, idx) => (
                <AuctionBox key={`${idx}-leilao`} isFirstItem={idx === 0}>
                    <AuctionText>
                        {auction.description}
                    </AuctionText>
                    <img style={{ marginRight: '14px' }} src={line54} alt={'separador de informações'} />
                    <AuctionText>
                        {auction.date}
                    </AuctionText>
                </AuctionBox>
            ))}

        </div>
    )
}

export default AuctionStatus