import React, {useEffect, useState} from 'react'
import Information from "./Information";
import {InformationContainer} from './styles';
import {getInitialKnowMore} from "../../modelos/KnowMore";
import {getKnowMoreInfo} from "../../services/InfoServices";

const InformationGroup = () => {
    const [infoList, setInfoList] = useState([getInitialKnowMore()])
    
    useEffect(() => {
        getKnowMoreInfos()
    }, [])
    
    const getKnowMoreInfos = async () => {
        try {
            const results: any = await getKnowMoreInfo()
            setInfoList(results)
        } catch (e) {
            console.error('Não foi possível exibir os cards de informações.')
        }
    }
    
    return (
        <InformationContainer>
            {infoList.map((info, idx) => {
                    return (
                        <div key={`info#${idx}`}>
                            <Information info={info}/>
                        </div>
                    )
                }
            )}
        </InformationContainer>
    )
}

export default InformationGroup