import React, {FormEvent, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
// import { CartCtx } from "../../../hooks/cart.ts";
import { UserProps } from "../../../modelos/UserType";
import { isAuthenticated, signOut } from "../../../services/AuthService";
import { getCustomerByToken } from "../../../services/UserService";
import {
    BindIco,
    BoxIco,
    CartIco,
    CloseIco,
    CoinsIcon,
    CreditCardIco,
    ExitIco,
    FileIco,
    GearIco,
    GrayCoin,
    HomeIco,
    Logo,
    Search,
    UserIco
} from "../../Icons";
import style from './styles.module.scss'
import CartUtils from "../../../utils/CartUtils";
import {CartItemLocal} from "../../../modelos/Cart";
import {Context} from "../../../contexts/ContextProvider";
import {getWallet} from "../../../services/WalletService";


export const Header = () => {
    // const { product, setProduto } = CartCtx();
    const [hasCartItems, setHasCartItems] = useState(false);
    const [credit] = useState(42);
    const authenticated = isAuthenticated(true, false);
    const [openMenu, setOpenMenu] = useState(false);
    const [menuRight, setMenuRight] = useState(false);
    const [search, setSearch] = useState(false);
    const [user, setUser] = useState<UserProps>();
    const history = useHistory()
    const context = useContext(Context)
  
    useEffect(() => {
        /*const cartItems : CartItemLocal[] = CartUtils.getCartItemsLocal();
        if(cartItems?.length > 0) {
            setHasCartItems(true)
        } else {
            setHasCartItems(false)
        }*/
        
        if(CartUtils.hasCartItemsLocal()) {
            setHasCartItems(true)
            context.setHasCartItems(true)
        } else {
            setHasCartItems(false)
            context.setHasCartItems(false)
        }
        
        if(window.innerWidth > 850) {
            setSearch(true)
        }
        
        if(authenticated) {
            getUser();
            getCustomerWallet();
        }
        
    }, [])
    
    function handleSearch(txt: string) {
        setSearch(true)
    }

    const getUser = async () => {
        let token = localStorage.getItem('userToken');
        if (token) {
            try {
                const response = await getCustomerByToken(token);
                setUser(response)

            } catch (error) {
                console.log(error)
            }
        }
    }

    const getCustomerWallet = async () => {
        try {
            const wallet = await getWallet();
            if(wallet) {
                context.setWallet(wallet)
            }

        } catch (error) {
            console.log(error)
        }
        
    }

    const handleLogOut = () => {
        setMenuRight(false)
        setOpenMenu(false)
        signOut()
        history.push("/login")
    }

    return (
        <header className={style.container}>
            <div className="container">
                <div className={style.row}>
                    <div className={style.left}>
                        <div onClick={() => setOpenMenu(!openMenu)} className={style.openMenu}>
                            <div />
                            <div />
                            <div />
                        </div>
                        <Link to="/" className={style.logo}>
                            <Logo />
                        </Link>
                    </div>
                    <div className={style.search}>

                        <div className={style.hide}
                            style={{ display: search ? 'flex' : 'none' }}
                        >
                            <button onClick={() => setSearch(false)} className={style.close}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#828BA1" />
                                    <path d="M15 9L9 15" stroke="#181A20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 9L15 15" stroke="#181A20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </button>
                            <input
                                type="search"
                                placeholder="O que você está procurando?"
                                name=""
                                id=""
                            />
                            <svg className={style.searvhTwo} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#828BA2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M21 21L16.65 16.65" stroke="#828BA2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                        <button onClick={() => handleSearch('')}>
                            <Search />
                        </button>
                    </div>
                    <div className={style.options}>
                        {authenticated ? (
                            <div
                                className={style.Logged}>
                                <div className={style.amountCoins}>
                                    <GrayCoin />
                                    <div>
                                        <span>Você tem</span>
                                        <strong>{context.wallet.coins} Easycoins</strong>
                                    </div>
                                </div>
                                <Link to="/carrinho" className={style.cart}>
                                    <CartIco />
                                    {context.hasCartItems ? (
                                        <div className={style.qtdItem} />
                                    ) : ''}
                                </Link>
                                <div className={style.user} >
                                    <div onClick={() => setMenuRight(!menuRight)} style={{ cursor: 'pointer' }}>
                                        <UserIco />
                                        <span className={style.txtAcount}>Minha conta</span>
                                    </div>
                                    {menuRight ? (
                                        <div
                                            className={`menuHide ${style.userMenu}`}
                                            onMouseLeave={() => setTimeout(() => setMenuRight(false), 1000)}
                                        >
                                            <div className={style.user}>
                                                <small>Olá, {user?.nickname}</small>
                                            </div>
                                            <ul>
                                                <li>
                                                    <Link to="/minha-conta" onClick={() => setMenuRight(false)}>
                                                        Minha conta
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className={style.qtdLeiloes} to="" onClick={() => setMenuRight(false)}>
                                                        Meus Leiloes
                                                        <span>3</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/favoritos" onClick={() => setMenuRight(false)}>
                                                        Favoritos
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a href="javascript:" onClick={handleLogOut}>
                                                        Sair
                                                    </a>
                                                </li>
                                            </ul>

                                            <p className={style.qtdcoins}>Você tem: <span>{context.wallet.coins} Easycoins</span></p>
                                            <div className={style.containerButton}>
                                                <Link className={style.btn} to="/easycoins">Comprar Easycoins</Link>
                                            </div>
                                            <div onClick={() => setMenuRight(false)}>
                                                <UserIco />
                                            </div>
                                        </div>
                                    ) : ''}

                                </div>

                            </div>
                        ) : (
                            <div className={style.loggedOut}>
                                <Link to="/carrinho" className={style.cartNotLogged}>
                                    <CartIco />
                                    {context.hasCartItems ? (
                                        <div className={style.qtdItemNotLogged} />
                                    ) : ''}
                                </Link>
                                <Link to="/login">Entre</Link>
                                <Link to="/cadastro">Cadastre-se</Link>
                            </div>

                        )}
                    </div>

                </div>
            </div>

            <div className={style.hideMenu}
                style={{ left: openMenu ? '' : '-100%' }}
            >
                <div className={style.content}>
                    <div className={style.user}>
                        {authenticated ? (
                            <div className={style.Logged}>
                                <div className={style.containerIco}>
                                    <UserIco />
                                </div>
                                <div className={style.userName}>
                                    <small>Olá</small>
                                    <span>{user?.nickname}</span>
                                </div>
                            </div>
                        ) : (
                            <div className={style.loggedOut}>
                                <UserIco />
                                <Link to="/login">
                                    Entre
                                </Link>
                                <Link to="/cadastro">
                                    Cadastre-se
                                </Link>
                            </div>

                        )}


                    </div>
                    {authenticated &&
                        <ul className={style.list}>
                            <li>
                                <Link to="/" onClick={() => setOpenMenu(false)} >
                                    <HomeIco />
                                    Página inicial
                                </Link>
                            </li>
                            <li>
                                <Link to="/minha-conta/como-funciona" onClick={() => setOpenMenu(false)}>
                                    <FileIco />
                                    Como funciona
                                </Link>
                            </li>
                            <li>
                                <Link to="/minha-conta/meu-cadastro" onClick={() => setOpenMenu(false)}>
                                    <GearIco />
                                    Meu cadastro
                                </Link>
                            </li>
                            <li>
                                <Link to="/minha-conta/minhas-compras" onClick={() => setOpenMenu(false)}>
                                    <span className={style.icon}><CoinsIcon /> Minhas compras</span>
                                </Link>
                            </li>
                            {/*<li>
                                <Link to="/leilao/status" onClick={() => setOpenMenu(false)}>
                                    <BindIco />
                                    Meus arremates
                                </Link>
                            </li>*/}
                            <li>
                                <Link to="/minha-conta/pagamentos" onClick={() => setOpenMenu(false)}>
                                    <CreditCardIco />
                                    Formas de Pagamentos
                                </Link>
                            </li>
                            <li>
                                <Link to="/minha-conta/retirada" onClick={() => setOpenMenu(false)}>
                                    <BoxIco />
                                    Retirada
                                </Link>
                            </li>
                            <li>
                                <a href="javascript:" onClick={handleLogOut}>
                                    <ExitIco />
                                    Sair
                                </a>
                            </li>
                            
                            <li>
                                <p className={style.qtdcoins}>
                                    Você tem: <span>{context.wallet.coins} Easycoins</span>
                                </p>
                                <div className={style.containerButton}>
                                    <Link className={style.btn} to="/easycoins" onClick={() => setOpenMenu(false)}>
                                        Comprar Easycoins
                                    </Link>
                                </div>
                            </li>

                        </ul>
                    }

                    <footer>
                        <Link to="" onClick={() => setOpenMenu(false)}><span>?</span> Central de relacionamento</Link>
                    </footer>
                </div>
                <div onClick={() => setOpenMenu(false)} className={style.closeArea}>
                    <CloseIco />
                </div>
            </div>
        </header>
    )
}