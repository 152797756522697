import styled, { css } from 'styled-components'
import { Col } from 'reactstrap'
export const Text = styled.span`
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-medium);
`

export const Eye = styled(Col)`
${({ showeye }) => {
        if (!showeye) return css`display:none`
    }}
`
export const Icon = styled.img`
    margin-right: 22px;
`

export const CustomizedInput = styled.input`
    background: transparent;
    border:none; 
    color: var(--color-text-medium);
    width: 480px;
    font-size: 14px;
    line-height: 16px;
    height: 50px;

    &:focus{
        outline:none;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        color: var(--color-text-medium);
  }
`