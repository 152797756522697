import StorageUtils from "./StorageUtils";
import {CartItemLocal} from "../modelos/Cart";

class CartUtils {
    
    public static getCartItemsLocal = () : CartItemLocal[] => {
        let cartItem = StorageUtils.getStorageItem(StorageUtils.KEYS.CART_ITEMS)
        if(cartItem) {
            return JSON.parse(cartItem)
        } else {
            return []
        }
    }
    
    public static getLocalCartId = () : string => {
        let localCartId = StorageUtils.getStorageItem(StorageUtils.KEYS.CART_ID)
        if(localCartId) {
            return localCartId
        } else {
            return ""
        }
    }
    
    public static setCartItemsLocal = (cartItemLocal : CartItemLocal[]) => {
        if (cartItemLocal && cartItemLocal.length > 0) {
            StorageUtils.setStorageItem(StorageUtils.KEYS.CART_ITEMS, JSON.stringify(cartItemLocal))
        }
    }
    
    public static hasCartItemsLocal = () : boolean => {
        let cartItemsLocal = CartUtils.getCartItemsLocal()
        if(cartItemsLocal && cartItemsLocal.length > 0) {
            return true
        } else {
            return false
        }
    }
    
    public static clearCartLocal = ()  => {
        CartUtils.removeCartLocal()
        CartUtils.removeCartItemsLocal()
        CartUtils.removeCartIDLocal()
        CartUtils.removeCheckoutLocal()
    }
    
    public static removeCartLocal = ()  => {
        StorageUtils.removeStorageItem(StorageUtils.KEYS.CART)
    }
    
    public static removeCartItemsLocal = ()  => {
        StorageUtils.removeStorageItem(StorageUtils.KEYS.CART_ITEMS)
    }
    
    public static removeCartIDLocal = ()  => {
        StorageUtils.removeStorageItem(StorageUtils.KEYS.CART_ID)
    }
    
    public static removeCheckoutLocal = ()  => {
        StorageUtils.removeStorageItem(StorageUtils.KEYS.CHECKOUT)
    }
}

export default CartUtils