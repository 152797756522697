import { useEffect, useState, FormEvent } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "../../../components/common/buttons/styles";
import { ArrowLeft, WithoutCard,  TrashIco, EditIco } from "../../../components/Icons";
import {
  BrandAmexCardIco,
  BrandMasterCardIco,
  BrandVisaCardIco,
  BrandHipercardCardIco,
  BrandDinersCardIco,
  BrandEloCardIco,
  BrandAuraCardIco,
  BrandDiscoverCardIco,
  BrandJcbCardIco,
  BrandMaestroCardIco,
  BrandOtherCardIco,
 } from "../../../components/Icons/brands";

import { ModalConfirm } from "../../../components/modal-confirm";
import { Breadcrumbs, BreadcrumbsList } from "../../../components/my-account/breadcrumbs";
import { Sidebar } from "../../../components/my-account/sidebar";
import {
  Card,
  createCard,
  deleteCard,
  getCards,
  identifyCardBrand,
  cardMask,
  dateMask,
  ccvMask
} from "../../../services/CardService";

import fatherStyles from '../styles.module.scss';
import styles from './styles.module.scss';

type Sections = 'list' | 'create' | 'show' | 'edit';

export default function Payments(){
  const [sectionActive, setSectionActive] = useState<Sections>('list');
  const fixBreadItem = {
    name: 'Pagamentos',
    fn: () => handleToggleSection('list')
  };
  const [breadList, setBreadList] = useState<BreadcrumbsList[]>([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [currentCard, setCurrentCard] = useState<Card | null>(null);

  useEffect(() => {
    handleGetCards();
  },[]);
  async function handleGetCards(){
    const data = await getCards();
    const parseCards : Card[] =  data.result ? data.response as Card[] : [];
    setCards([...parseCards.map(card => {
      return {
        ...card,
        number: card.maskedNumber,
        validity: card.expirationMonth + '/' + card.expirationYear
      }
    })]);
  }
  function handleAddCard(card: Card){
    setCards([...cards,{
      ...card,
      number: card.maskedNumber,
      validity: card.expirationMonth + '/' + card.expirationYear
    }]);
    handleToggleSection('list');
  }
  function handleToggleSection(section: Sections){
    switch(section){
      case 'list':
        setBreadList([]);
        break;
      case 'create':
        setBreadList([{
          name: 'Adicionar cartão',
          fn: () => {}
        }]);
        break;
      case 'show':
        setBreadList([{
          name: 'Visualizar cartão',
          fn: () => {}
        }]);
        break;
      case 'edit':
        setBreadList([{
          name: 'Editar cartão',
          fn: () => {}
        }]);
        break;
    }
    setSectionActive(section);
  }
  function handleSelectCard(card: Card, redirect: Sections){
    setCurrentCard(card);
    handleToggleSection(redirect);
  }
  async function handleDeleteCard(){
    if(currentCard === null) return;

    const data = await deleteCard(currentCard.id);

    if(data.result){
      toast.success('Cartão excluído com sucesso!');
      setCards([...cards.filter(card => card.id !== currentCard.id)]);
      handleToggleSection('list');
    }
    else toast.success(data.response);
  }
  function handleEditCard(card: Card){
    if(currentCard === null) return;
    let index = cards.findIndex(card => card.id === currentCard.id);
    let temp = cards;
    let formattedCard = {
      ...card,
      number: card.maskedNumber,
      validity: card.expirationMonth + '/' + card.expirationYear
    };
    temp[index] = formattedCard;
    setCurrentCard({ ...formattedCard });
    setCards([...temp]);
    handleToggleSection('show');
  }
  return (
    <div className={fatherStyles.container}>
      <ToastContainer/>
      <Breadcrumbs list={[fixBreadItem, ...breadList]}/>
      <div className={styles.content}>
        <Sidebar active="Pagamentos"/>
        <main>
          {
            sectionActive === 'list' ? <ListCards
              handleCreate={() => handleToggleSection('create')}
              handleSelectCard={handleSelectCard}
              cards={cards}
            /> :
            sectionActive === 'create' ? <CreateCard handleAddCard={handleAddCard}/> :
            sectionActive === 'show' ? <ShowCard
              card={currentCard}
              handleDeleteCard={handleDeleteCard}
              handleEdit={() => handleToggleSection('edit')}
              goBack={() => handleToggleSection('list')}
            /> :
            sectionActive === 'edit' ? <EditCard
              currentCard={currentCard}
              handleEdit={handleEditCard}
              goBack={() => handleToggleSection('show')}
            /> : ''
          }
        </main>
      </div>
    </div>
  );
}

interface ListCardProps{
  handleCreate: () => void;
  handleSelectCard: (card: Card, redirection: Sections) => void;
  cards: Card[]
}
function ListCards({ handleCreate, handleSelectCard, cards } : ListCardProps){
  
  return (
    <>
      <h3 className={styles.title}>
        {cards.length === 0 ? (
          <>
            Você não tem nenhum<br/>
            cartão adicionado
          </>
        ):( <>Pagamentos</> )}</h3>
      <div className={styles.containerCards}>
        {cards.map(card => { return (
          <div
            style={{ cursor: 'pointer'}}
            className={styles.contentCard}
            onClick={() => handleSelectCard(card, 'show')}
          >
            <HandleCardBrand brand={card.brand}/>
            <span>{card.maskedNumber}</span>
            <button className={styles.buttonReset} style={{ fontSize: 0 }}>
              <ArrowLeft />
            </button>
          </div>
        );})}
        {cards.length === 0 && (
          <div style={{
            margin: 'auto',
            width: 'fit-content',
            maxWidth: '100%'
          }}>
            <WithoutCard/>
          </div>
        )}
      </div>
      <Button
        style={{ border: 'none', color: 'white', margin: '32px auto', width: '350px', maxWidth: '100%'}}
        onClick={handleCreate}
      >
        Adicionar cartão de crédito
      </Button>
    </>
  );
}
interface CreateCardProps{
  handleAddCard: (card: Card) => void;
}
function CreateCard({ handleAddCard } : CreateCardProps){
  const [brand, setBrand] = useState('OTHER');
  const [ownerName, setOwnerName] = useState('');
  const [number, setNumber] = useState('');
  const [validity, setValidity] = useState('');
  const [cvv, setCvv] = useState('');

  useEffect(() => {
    if(number.length >= 16) setBrand(identifyCardBrand(number) ?? 'OTHER');
    else setBrand('OTHER');
  },[number]);
  async function handleCreateCard(event: FormEvent){
    event.preventDefault();

    let invalidDate = false;
    if(!(validity.indexOf('/') === 2 && validity.length === 7)) invalidDate = true;

    let [expirationMonth, expirationYear]: string[] = validity.split('/');

    if(!(expirationMonth && expirationYear)) invalidDate = true;

    if(invalidDate){
      toast.error('Formato de data de validate inválido');
      return ;
    }

    let formattedBrand = brand.toUpperCase();

    const data = await createCard({
      brand: formattedBrand,
      ownerName,
      number: number.replaceAll(' ',''),
      expirationMonth,
      expirationYear,
      type: 'CREDIT',
      cvv
    });

    if(data.result){
      handleAddCard(data.response as Card);
      toast.success('Cartão cadastrado com sucesso');
    }
    else toast.error(data.response as string);
  }

  return(
    <>
      <h3 className={styles.title}>Adicionar Cartão</h3>
      <form className={styles.formCard} onSubmit={handleCreateCard}>
        <div className={styles.formGroup}>
          <label htmlFor="card_name">Nome no Cartão</label>
          <div className={styles.formControl}>
            <input
              type="text"
              id="card_name"
              placeholder="Nome impresso no Cartão"
              value={ownerName}
              minLength={5}
              onChange={e => setOwnerName(e.target.value)}
              style={{ flex: 1 }}
              required
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="card_number">Número do cartão</label>
          <div className={styles.formControl}>
            <HandleCardBrand brand={brand}/>
            <input
              type="text"
              id="card_number"
              placeholder="0000 0000 0000 0000"
              value={number}
              onChange={e => setNumber(cardMask(e.target.value))}
              minLength={17}
              maxLength={19}
              required
            />
          </div>
        </div>
        <fieldset className={styles.fieldGroup}>
          <div className={styles.formGroup}>
            <label htmlFor="card_validity">Data de venc.</label>
            <div className={styles.formControl}>
              <input
                type="text"
                id="card_validity"
                placeholder="00/0000"
                value={validity}
                minLength={7}
                maxLength={7}
                onChange={e => setValidity(dateMask(e.target.value))}
                required
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="card_cvv">CVV</label>
            <div className={styles.formControl}>
              <input
                type="text"
                id="card_cvv"
                placeholder="000"
                value={cvv}
                minLength={3}
                maxLength={4}
                onChange={e => setCvv(ccvMask(e.target.value))}
                required
              />
            </div>
          </div>
        </fieldset>
        <Button style={{ border: 'none', color: 'white', margin: '32px auto' }}>Salvar</Button>
      </form>
    </>
  );
}
interface ShowCardProps{
  card: Card | null;
  handleDeleteCard: () => Promise<void>;
  handleEdit: () => void;
  goBack: () => void;
}
function ShowCard({ card, handleDeleteCard, handleEdit, goBack } : ShowCardProps){
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  if(card === null) return <p>Carregando...</p>;
  return (
    <>
      <h3 className={styles.title}>{ card.brand }</h3>
      <div className={styles.showCard}>
        <div className={styles.contentCard}>
          <div className={styles.cardHeader}>
            <HandleCardBrand brand={card.brand}/>
            <span>{card.maskedNumber}</span>
          </div>
          <div className={styles.cardFooter}>
            <p>{card.ownerName}</p>
            <div>Data de Vencimento</div>
            <strong>{card.validity}</strong>
          </div>
        </div>
        <div className={styles.actions} style={{ marginBottom: '10px'}}>
          <button className={styles.buttonReset} style={{ fontWeight: 'bold' }} onClick={handleEdit}>
            <EditIco/>
            Editar
          </button>
          <button className={styles.buttonReset} onClick={() => setIsOpenConfirm(true)}>
            <TrashIco/> Remover
          </button>
        </div>
        <button className={styles.buttonBack} onClick={goBack}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: 'currentColor'}}><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg> Voltar
        </button>
        {isOpenConfirm ? (
          <ModalConfirm
            onClose={() => setIsOpenConfirm(false)}
            onConfirm={handleDeleteCard}
          >
            <h2 style={{ textAlign: 'center' }}>Confirmar Exclusão</h2>
            Tem certeza que deseja excluir o cartão {card.maskedNumber}?
          </ModalConfirm>
        ):''}
      </div>
    </>
  );
}
interface EditCardProps{
  currentCard: Card | null;
  handleEdit: (card: Card) => void;
  goBack: () => void;
}
function EditCard({ currentCard, handleEdit, goBack }: EditCardProps) {
  const [brand, setBrand] = useState(currentCard ? currentCard.brand : 'OTHER');
  const [ownerName, setOwnerName] = useState(currentCard ? currentCard.ownerName : '');
  const [number, setNumber] = useState(currentCard ? currentCard.maskedNumber : '');
  const [validity, setValidity] = useState(currentCard ? currentCard.validity ?? '' : '');
  const [cvv, setCvv] = useState(currentCard ? currentCard.cvv ?? '' : '');

  useEffect(() => {
    if(number.length >= 16) setBrand(identifyCardBrand(number) ?? 'OTHER');
    else setBrand('OTHER');
  },[number]);
  
  async function handleUpdateCard(event: FormEvent){
    event.preventDefault();

    if(!currentCard) return;
    
    let invalidDate = false;
    if(!(validity.indexOf('/') === 2 && validity.length === 7)) invalidDate = true;

    let [expirationMonth, expirationYear]: string[] = validity.split('/');

    if(!(expirationMonth && expirationYear)) invalidDate = true;

    if(invalidDate){
      toast.error('Formato de data de validate inválido');
      return ;
    }

    const dataDelete = await deleteCard(currentCard.id);

    if(!dataDelete.result){
      toast.error(dataDelete.response);
      return;
    }
    
    console.log(brand);
    const data = await createCard({
      brand: brand.toUpperCase(),
      ownerName,
      number: number.replaceAll(' ',''),
      expirationMonth,
      expirationYear,
      type: 'CREDIT',
      cvv
    });

    if(data.result){
      handleEdit(data.response as Card);
      toast.success('Cartão editado com sucesso');
    }
    else toast.error(data.response as string);
  }

  if(currentCard === null) return <p>Carregando...</p>;

  return(
    <>
      <h3 className={styles.title}>Editar Cartão</h3>
      <form className={styles.formCard} onSubmit={handleUpdateCard}>
        <div className={styles.formGroup}>
          <label htmlFor="card_name">Nome no Cartão</label>
          <div className={styles.formControl}>
            <input
              type="text"
              id="card_name"
              placeholder="Nome impresso no Cartão"
              value={ownerName}
              minLength={5}
              onChange={e => setOwnerName(e.target.value)}
              style={{ flex: 1 }}
              required
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="card_number">Número do cartão</label>
          <div className={styles.formControl}>
            <HandleCardBrand brand={brand}/>
            <input
              type="text"
              id="card_number"
              placeholder="0000 0000 0000 0000"
              value={number}
              onChange={e => setNumber(cardMask(e.target.value))}
              minLength={17}
              maxLength={19}
              required
            />
          </div>
        </div>
        <fieldset className={styles.fieldGroup}>
          <div className={styles.formGroup}>
            <label htmlFor="card_validity">Data de venc.</label>
            <div className={styles.formControl}>
              <input
                type="text"
                id="card_validity"
                placeholder="00/0000"
                value={validity}
                minLength={7}
                maxLength={7}
                onChange={e => setValidity(dateMask(e.target.value))}
                required
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="card_cvv">CVV</label>
            <div className={styles.formControl}>
              <input
                type="text"
                id="card_cvv"
                placeholder="000"
                value={cvv}
                minLength={3}
                maxLength={4}
                onChange={e => setCvv(ccvMask(e.target.value))}
                required
              />
            </div>
          </div>
        </fieldset>
        <Button type="submit" style={{
          border: 'none',
          color: 'white',
          margin: '32px auto 10px'
        }}>Salvar</Button>        
        <button className={styles.buttonBack} onClick={goBack}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: 'currentColor'}}><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg> Voltar
        </button>
      </form>
    </>
  );
}
function HandleCardBrand({ brand }:{ brand: string }){
  switch(brand?.toLowerCase()){
    case 'visa': return <BrandVisaCardIco/>;
    case 'master': return <BrandMasterCardIco/>;
    case 'hipercard': return <BrandHipercardCardIco/>;
    case 'amex': return <BrandAmexCardIco/>;
    case 'diners': return <BrandDinersCardIco/>;
    case 'elo': return <BrandEloCardIco/>;
    case 'aura': return <BrandAuraCardIco/>;
    case 'discover': return <BrandDiscoverCardIco/>;
    case 'jcb': return <BrandJcbCardIco/>;
    case 'maestro': return <BrandMaestroCardIco/>;
    case 'other': default: return <BrandOtherCardIco color={'#333'}/>;
  }
}