import React, {useEffect, useState} from 'react'
import {Banner, BannerType} from "../../modelos/Banner";
import style from './styles.module.scss';
import {useHistory} from "react-router-dom";
import {getBannersByType} from "../../services/BannerService";

interface BannersProps {
    type: BannerType
}

const Banners: React.FC<BannersProps> = ({type}) => {
    const history = useHistory();
    const [scrollX, setScrollX] = useState(0);
    const [banners, setBanners] = useState<Banner[]|undefined>()
    
    useEffect(() => {
        loadBanners()
    }, [])
    
    const loadBanners = async () => {
        try {
            const banners = await getBannersByType(type)
            setBanners(banners)
        } catch (e) {
            console.error('Não foi possível carregar os banners - HOME_MIDDLE.')
        }
    }
    
    function right() {
        let x = scrollX - window.innerWidth;
        
        if (banners) {
            let listW = banners.length * window.innerWidth;
            if ((window.innerWidth - listW > x)) {
                x = window.innerWidth - listW
            }
            
        }
        
        setScrollX(x)
    }
    
    function left() {
        let x = scrollX + window.innerWidth;
        if (x > 0) {
            x = 0
        }
        
        setScrollX(x)
    }
    
    const buildBannersHomeTop = () => {
        return (
            <div className={style.banner}>
                <div className={style.carousel}>
                    {banners && banners.length > 1 ? (
                        <>
                            <button onClick={left} className={style.left}></button>
                            <button onClick={right} className={style.rigth}></button>
                        </>
                    ) : ''}
                    <div className={style.slider}>
                        <div className={style.containerItems}
                             style={{
                                 marginLeft: scrollX,
                                 width: banners && banners.length * window.innerWidth,
                             }}
                        >
                            {banners ? (
                                banners.map(banner => {
                                    return (
                                        <div key={banner._id} className={style.item}
                                             style={{
                                                 backgroundImage: window.innerWidth < 600 ? `url(${banner.image.mobile.url})` : `url(${banner.image.desktop.url})`,
                                             }}
                                        />
                                    )
                                })
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    const buildBannersHomeMiddle = () => {
        return banners && banners.map(
            banner => {
                return (
                    <div onClick={()=> history.push(`${banner.link.url}`)} className={style.coinsSection} key={banner._id} >
                        <img src={window.innerWidth <= 600 ? banner.image.mobile.url : banner.image.desktop.url} alt={banner.title} />
                    </div>
                )
            }
        )
    }
    
    const buildBannersProductMiddle = () => {
        return buildBannersHomeMiddle()
    }
    
    return (
        <>
            {type == BannerType.HOME_MIDDLE ? (
                    buildBannersHomeMiddle()
                ) : type == BannerType.PRODUCT_MIDDLE ? (
                    buildBannersProductMiddle()
                ) : type == BannerType.HOME_TOP ? (
                        buildBannersHomeTop()
                ) : ""
            }
        </>
    )
}

export default Banners