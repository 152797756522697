import styled from 'styled-components';

export const MobileSearchBar = styled.div`
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 345px; 
        height: 40px;
        background: #262A34;
        border-radius: 8px; 
`;

export const MobileInputFromSearch = styled.input`
        display: block;
        background: var(--color-primary-light);
        border:none; 
        color: var(--color-text-medium);
        width: 270px;
        font-size: 14px;
        line-height: 16px;
        height: 90%;
        &:focus{
            outline:none;
        }
    
`