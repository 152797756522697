import { useState } from 'react'

const useStatus = () => {
    const initialStatus = { isFetching: false, success: false, error: false }
    const [status, setStatus] = useState(initialStatus)

    const setAllStatus = (allStatus: any) => {
        setStatus(allStatus)
    }

    return {
        status,
        setAllStatus
    }
}

export default useStatus
