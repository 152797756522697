import styled from 'styled-components'

export const CoinsSection = styled.img`
    width: 360px; 
    height: 430px;
    display: block;
    margin: 0 auto;
    cursor: pointer;

    @media(min-width: 700px){
        width: 100%;
        height: 315px;
    }
`
export const Title = styled.h1`
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;

    @media(min-width: 700px){
        font-size: 24px;
        line-height: 28px;
    }
`
export const SubTitle = styled.span`
    font-size: 14px;
    line-height: 16px;
`