import React, { useEffect, useState } from 'react'
import ProductCard from '../../components/product-card/ProductCard'
import { formatDate, formatHour } from '../../utils/utilities'

import { AuctionFavourites, ContainerProduct, HeadTitle, ImageBox, Subtitle } from './styles';
import card_img from '../../assets/favourite-card.svg'
import { getFavorites, Favorite, deleteFavorite } from '../../services/FavoriteService';
import { useHistory } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { BreadCrumbs } from '../../components/BreadCrumbs';

const Favourite = () => {
    const crumbContent = [
        {
            name: 'Pagina inicial',
            route: '/'
        },
        {
            name: 'Favoritos',
            route: '/favoritos'
        }
    ]
    
    const [products, setProducts] = useState<Favorite[]>([])
    const history = useHistory();
    
    useEffect(() => {
        (async () => {
            const data = await getFavorites();
            
            if(data.result){
                const response = data.response as Favorite[];
                setProducts([...response]);
            }
        })();
    },[]);
    
    async function handleDeleteFavorite(id: string){
        const data = await deleteFavorite(id);
        
        if(data.noAuth) history.push("/login");
        
        if(!data.result) toast.error(data.response);
        else{
            toast.success(data.response);
            setProducts((old) => {
                const oldFiltered = old.filter(product => product._id !== id)
                return oldFiltered;
            })
        }
    }

    return (
        <div className="container" style={{
            paddingBottom: 52,
            minHeight: 'calc(100vh - 150px)'
        }}>
            <ToastContainer/>
            <BreadCrumbs content={crumbContent} />
            {
                products.length > 0 ? (
                    <>
                        <AuctionFavourites>{products.length} leilões</AuctionFavourites>
                        <ContainerProduct flexDirection="row">
                            {
                                products.map((item, idx) => {

                                    return (
                                        <ProductCard
                                            key={item._id}
                                            _id={item._id}
                                            img={item.images[0].url}
                                            date={formatDate(item.startAuctionDate)}
                                            hour={formatHour(item.startAuctionDate)}
                                            cardTitle={item.name +" - "+ item.shortDescription}
                                            bid={item.currentPrice}
                                            handleFavorite={{
                                                isFavorite: true,
                                                addFavorite: (id: string) => {},
                                                deleteFavorite: handleDeleteFavorite
                                            }}
                                        />
                                    );
                                })
                            }

                        </ContainerProduct>
                    </>) : (
                    <ContainerProduct flexDirection="column">
                        <HeadTitle>Você ainda não tem nenhum leilão em seus favoritos</HeadTitle>
                        <Subtitle>Para adicionar um leilão aos seus favoritos, aperte no icone de coração do leilão desejado.</Subtitle>
                        <ImageBox><img src={card_img} alt={"plano de fundo"}/></ImageBox>
                    </ContainerProduct>)
            }

        </div>)
}

export default Favourite