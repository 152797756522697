import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardImg, CardImgOverlay, CardTitle, CardText } from 'reactstrap'

export const Container = styled(Card)`
    padding: 0;
    background: transparent;
    border-radius: 16px;
    border: none;
    margin-bottom:15px;
    box-shadow: 0px 4px 16px rgba(216, 224, 242, 0.8);
    width: 318px;
    height: 409px;

    min-width: inherit !important;
    max-width: 100%;

    @media(min-width: 700px){
        width: 360px;
        height: 365px;
       
    }    
    
`
export const Overlay = styled(CardImgOverlay)`
    width:100%;
    height:30px;
`
export const CardImgOverlayContent = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;

    @media(min-width: 700px){
        justify-content: space-between;
        align-items: center;
        height: 30px;
    }
`

export const DateBox = styled.div`
    display: flex;
    justify-content: space-around; 
    align-items: center;    
    border-radius: 5px;
    width: 228px;
    background: var(--color-primary-dark);
    color: var(--color-text-medium);
    font-size: 14px;
    line-height: 16px;
    padding:10px;
    align-self: flex-start;
`
export const AuctionDate = styled(CardText)`
    align-self: center;
    font-size: 14px;
`
export const AuctionBegin = styled.span`
    color: var(--color-text-medium);
`
export const CardImgCustomized = styled(CardImg)`
    background: transaparent;
    height: 230px;
    border-radius: 16px;

    @media(min-width: 700px){
        border-radius: 8px;
    }    
`
export const CardBodyCustomized = styled(CardBody)`
    background: transparent;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(min-width: 700px){
        border-radius: 16px;
    }    
`
export const CardTitleCustomized = styled(CardTitle)`
    color: var(--color-text-dark);
    font-size: 20px;
    line-height: 23px;
`
export const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media(min-width: 700px){
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }
`
export const BidInfo = styled.div`
    display: flex; 
    align-items: center;
    margin-bottom: 10px;
`
export const MoneyImg = styled.img`
    padding-right: 12px;
`
export const BidTitle = styled.span`
    font-size: 12px;
    line-height: 14px;
    color: var(--color-text-medium);
`
export const CurrentBid = styled(CardText)`
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-text-dark);
`

export const Heart = styled.img`
    align-self: flex-end;

    @media(min-width: 700px){
        cursor: pointer;
        align-self: center;
    }
`

export const ButtonWrapper = styled(Link)`
    width: 100%;

    @media(min-width: 700px){
        width: 144px;
        height: 41px;
    }
`
export const ButtonCustomized = styled(Button)`
    width: 100%;

    @media(min-width: 700px){
        width: 144px;
        height: 41px;
        margin-left: 20px;
    }
`