import styled from 'styled-components';
import { Card } from 'reactstrap';
import { Button } from '../../components/common/buttons/styles';
import { Link } from "react-router-dom";

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 320px;
    margin: 8rem auto 12rem;
    min-height: calc(100vh - 562px);
`

export const CustomizedCard = styled(Card)`
    width:450px;
    height: 200px;
`
export const CustomizedButton = styled(Button)`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
`
export const StyledLink = styled(Link)`
    color: var(--color-text-medium) !important;
    font-size: 14px;
    line-height: 16px;  
    margin-top: 10px;  
    text-align: center;
`