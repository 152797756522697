import api from './api'

export interface RequestUpdateUser{
    fullName?: string,
    birthday?: string,
    cpfCnpj?: string,
    telephones?: {
        ddi: string,
        ddd: string,
        number: string,
        main?: boolean,
        mobile?: boolean,
        whatsapp: boolean
    }[]
}
interface ResponseUpdateUser{
    result: boolean;
    response: string;
}
interface RequestUpdatePassword{
    email: string;
    password: string;
    pass: string;
    checkPass: string;
}

export async function getCustomerByToken(token: string) {
    const response = await api.get('/p/customer', { headers: { Authorization: `Bearer ${token}` } })
    return response.data;
}

export async function updateUser(
    request: RequestUpdateUser,
    userToken: string
) : Promise<ResponseUpdateUser>{
    try{
        const response = await api.put('/p/customer', request, {
            headers: { Authorization: `Bearer ${userToken}` }
        });

        let message = 'Alterado com sucesso';
        if(typeof response === 'string') message = response;

        return {
            result: true,
            response: message
        };
    }catch(err: any){
        let message = "Houve um erro ao enviar essa requisição";
        if(err.response && err.response.data && err.response.data.message){
            message = err.response.data.message;
        }
        if(err.response && err.response.data && err.response.data.validationErrors){
            let messages = err.response.data.validationErrors.map((err: { message: string }) => {
                return " "+err.message;
            })
            message+= ".\n\n" + messages.join("\n");
        }
        return {
            result: false,
            response: message
        } 
    }
}

export async function updatePassword(
    request: RequestUpdatePassword,
    userToken: string
) : Promise<ResponseUpdateUser>{
    try{
        const response = await api.put('/p/customer/update-password', {
            account: {
                "id": request.email,
                "password": request.password,
                "pass": request.pass,
                "checkPass": request.checkPass
            }
        }, {
            headers: { Authorization: `Bearer ${userToken}` }
        });

        let message = 'Alterado com sucesso';
        if(typeof response === 'string') message = response;

        return {
            result: true,
            response: message
        };
    }catch(err: any){
        let message = "Houve um erro ao enviar essa requisição";
        if(err.response && err.response.data && err.response.data.message){
            message = err.response.data.message;
        }
        if(err.response && err.response.data && err.response.data.validationErrors){
            let messages = err.response.data.validationErrors.map((err: { message: string }) => {
                return " "+err.message;
            })
            message+= ".\n\n" + messages.join("\n");
        }
        return {
            result: false,
            response: message
        } 
    }
}