import { ToastContainer } from "react-toastify";
import { Breadcrumbs } from "../../../components/my-account/breadcrumbs";
import { Sidebar } from "../../../components/my-account/sidebar";

import fatherStyles from '../styles.module.scss';
import styles from './styles.module.scss';

export default function Withdrawal(){
  return (
    <div className={fatherStyles.container}>
      <ToastContainer/>
      <Breadcrumbs/>
      <div className={styles.content}>
        <Sidebar active="Retirada"/>
        <main>
        <h3 className={styles.title}>Retirada</h3>
        </main>
      </div>
    </div>
  );
}