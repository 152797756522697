import { ReactNode } from 'react';

interface RepeatProps {
    qtd: number,
    children: ReactNode
}


export const Repet = ({ qtd, children }: RepeatProps) => {
    function handleRepeat(element: ReactNode, repeat: number) {
        const elements: ReactNode[] = [];
        for (let i = 0; i < repeat; i++) {
            elements.push(element);
        }
        return elements;
    }
    return (
        <>
            {handleRepeat(
                children
                ,
                qtd
            )}

        </>
    )
}