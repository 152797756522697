import {Switch, useLocation} from "react-router-dom";
import {Footer} from "./components/layouts/Footer";
import {Header} from "./components/layouts/Header";

import routes from './routes';
import {ContextProvider} from "./contexts/ContextProvider";

function Routes() {
    const location = useLocation()
    
    return (
        <ContextProvider>
            <Header />
            <Switch location={location}>
                {
                    routes.map((route, index) => {
                        return (
                            <route.routeType
                                key={`route-${index}`}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}/>
                        )
                    })
                }
            </Switch>
            <Footer/>
        </ContextProvider>
    )
}

export default Routes;