import React, { FormEvent, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import StyledInput from '../../components/common/inputs/styled-input/StyledInput';
import { Title } from '../../components/common/styles/styles';
import { FormContainer, CustomizedButton } from './styles';
import { ButtonLabel } from '../../components/common/buttons/styles';
import styles from "../registers/styles.module.scss";
import {
    CustomizedSubtitle,
    TitleBox
} from '../login/styles';
import useForm from '../../hooks/useForm';
import { SuccessIco, FailIco } from '../../components/Icons';
import { checkRecoveryPassword, changePassword } from '../../services/ForgotPasswordService';

import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from 'react-router';
import { ModalForgotPassword, stylesBtnFinish } from '../../components/modal-forgot-password';
import { Link } from 'react-router-dom';

const google_recaptcha = {
    dev: {
        token: '6LfHZw8dAAAAAF3QWrphGxTT4kbUs5nqdYIxuMvE',
        src: 'https://www.google.com/recaptcha/enterprise.js?render=6LfHZw8dAAAAAF3QWrphGxTT4kbUs5nqdYIxuMvE%22' 
    },
    prod: {
        token: '6LdJng4dAAAAAPNgyXB7WUPasndHwME-a-W0kIUA',
        src: 'https://www.google.com/recaptcha/enterprise.js?render=6LdJng4dAAAAAPNgyXB7WUPasndHwME-a-W0kIUA%22%3E%3C%2Fscript%3E'
    }
}

interface ModalContent{
    type: 'success' | 'error';
    children: React.ReactNode;
}

export default function ResetPassword(){
    const history = useHistory();
    const { values, setValue } = useForm({ password: '', confirmation: '' })
    const [reCaptcha, setReCaptcha] = useState<string | null>(null);
    
    const [authEmailToken, setAuthEmailToken] = useState<{ email: string, token: string }>();
    const [statusValidation, setStatusValidation] = useState('');

    const [isResponseOpen, setIsResponseOpen] = useState(false);
    const [responseContent, setResponseContent] = useState<ModalContent>({
        type: 'error',
        children: <p>...</p>
    });
    useEffect(() => {
        const data = handleUrlParams();
        if(!data.result){
            handleShowResponse({
                type: 'error',
                children: <>
                    <p>{data.response}</p>
                    <Link to="/esqueceu-senha"  style={{
                        color: '#828BA1',
                        fontSize: '16px',
                        textAlign: 'center'
                    }}>
                        Voltar
                    </Link>
                </>
            });
            setStatusValidation(data.response);
        }
    },[]);

    function handleUrlParams(){
        const url = window.location.href;
        if(url.includes('?email=') && url.includes('&token=')){
          const [urlWithoutParams, params] = url.split('?email=');
          if(!params) return {
            result: false,
            response: 'Link inválido. Não é possível realizar a troca de senha'
          };

          const [email, token] = params.split('&token=');
        
          if(!email || !token) return {
            result: false,
            response: 'Link inválido. Não é possível realizar a troca de senha'
          };

          handleVerifyEmailToken(
            decodeURIComponent(email),
            token
          );

          return {
            result: true,
            response: 'Link correto'
          };
        }else return {
            result: false,
            response: 'Link incompleto. Não é possível realizar a troca de senha'
        }
    }

    async function handleVerifyEmailToken(email: string, token: string){
        const data = await checkRecoveryPassword(email, token);

        if(data.result) setAuthEmailToken({
            email,
            token
        });
        else{
            handleShowResponse({
                type: 'error',
                children: <>
                    <p>{data.response}</p>
                    <Link to="/esqueceu-senha" style={{
                        color: '#828BA1',
                        fontSize: '16px',
                        textAlign: 'center'
                    }}>
                        Voltar
                    </Link>
                </>
            });
            setStatusValidation(data.response);
        }
    }

    async function handleChangePassword(event: FormEvent){
        event.preventDefault();

        if(!authEmailToken){
            handleShowResponse({
                type: 'error',
                children: <p>{statusValidation}</p>
            });
            return;
        }

        if(values.password !== values.confirmation){
            toast.error('As senhas não são iguais');
            return;
        }

        if(!reCaptcha){
            toast.error('Clique em não sou um Robô');
            return;
        }

        const data = await changePassword(
            authEmailToken.email,
            authEmailToken.token,
            reCaptcha,
            values.password,
            values.confirmation
        );

        if(data.result){
            handleShowResponse({
                type: 'success',
                children:(
                    <>
                        <p>
                            Senha alterada com sucesso.<br/>
                            Agora você já pode fazer o login e acessar sua conta.
                        </p>
                        <Link to="/login" className={stylesBtnFinish}>Ir para Login</Link>
                    </>
                )
            });
        }
        else toast.error(data.response);
    }

    function handleShowResponse(obj: ModalContent){
        setResponseContent(obj);
        setIsResponseOpen(true);
    }
    return (
        <div className={styles.container}>
            <FormContainer onSubmit={handleChangePassword}>
                <ToastContainer />
                <TitleBox>
                    <Title style={{ textAlign: 'center'}}>Redefinir senha</Title>
                    {!isResponseOpen ? (
                        <CustomizedSubtitle>Por favor, preencha uma senha para a sua conta.</CustomizedSubtitle>
                    ):''}
                </TitleBox>
                <br/>
                {isResponseOpen ? (<>
                    {
                        responseContent.type === 'success' ? <SuccessIco/> :
                        responseContent.type === 'error' ? <FailIco/> : ''
                    }
                    <div style={{
                        textAlign: 'center',
                        fontSize: '16px',
                        marginTop: '24px',
                        color: 'var(--color-text-medium)'
                    }}>
                        {responseContent.children}
                    </div>
                </>) : (<>
                    <StyledInput
                        showeye={true}
                        type={'password'}
                        name={'password'}
                        placeholder={"Nova Senha"}
                        onchange={setValue}
                        required
                    />

                    <StyledInput
                        showeye={true}
                        type={'password'}
                        name={'confirmation'}
                        placeholder={"Confirmação"}
                        onchange={setValue}
                        required
                    />
                    
                    <ReCAPTCHA
                        sitekey={google_recaptcha.dev.token}
                        onChange={setReCaptcha}
                    />

                    <CustomizedButton type="submit">
                        <ButtonLabel style={{ color: '#FFF' }}>Registrar senha</ButtonLabel>
                    </CustomizedButton>
                </>)}
            </FormContainer>
        </div>
    );
}