import axios from 'axios';
import { UserRegister } from '../modelos/UserRegister'
const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST

export const userRegister = async (user: UserRegister) => {
    const url: string = `${BACKEND_HOST}/api/o/customer`
    const response: any = await axios.post(url, user)
    return response
}

export function cpfValidationAlgorithm(strCPF: string){
    strCPF = removePunctuation(strCPF);
    
    let invalids = [
        '11111111111','22222222222','33333333333','44444444444','55555555555',
        '66666666666','77777777777','88888888888','99999999999'
    ];

    if(invalids.indexOf(strCPF)!=-1)
        return false;
    var soma: number;
    var resto: number;
    soma = 0;
    if (strCPF == "00000000000")
        return false;
    for (let i=1; i<=9; i++)
        soma = soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if ((resto == 10) || (resto == 11))
        resto = 0;
    if (resto != parseInt(strCPF.substring(9, 10)))
        return false;
    soma = 0;
    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto == 10) || (resto == 11))
        resto = 0;
    if (resto != parseInt(strCPF.substring(10, 11)))
        return false;
    return true;
}

export function removePunctuation(temp: string){
    while(temp.indexOf('.') !== -1 || temp.indexOf('-') !== -1 || 
          temp.indexOf('/') !== -1 || temp.indexOf(' ') !== -1 ||
          temp.indexOf('(') !== -1 || temp.indexOf(')') !== -1){
        temp = temp.replace('.','');
        temp = temp.replace('-','');
        temp = temp.replace('/','');
        temp = temp.replace(' ','');
        temp = temp.replace('(','');
        temp = temp.replace(')','');
    }
    return temp;
}