import { useState } from 'react'

const useForm = (initialValues: any) => {
    const [values, setValues] = useState(initialValues)
    const setValue = (e: any) => {
        const value = e.target.value
        console.log('value: ', value)
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const setAllValues = (allValues: any) => {
        setValues(allValues)
    }

    return {
        values,
        setValue,
        setAllValues,
    }
}

export default useForm