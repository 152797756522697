import React, {useEffect, useState} from 'react'
import {Box, Paper, Step, StepLabel, Stepper} from "@mui/material";
import {Order, OrderStatus, OrderStatusType, OrderStatusTypeLabel} from "../../../modelos/Order";
import {isAuthenticated} from "../../../services/AuthService";
import {formatDate, formatDateTime} from "../../../utils/utilities";

interface OrderStatusStepProps {
    order: Order
}

interface OrderStatusStep {
    step0?: OrderStatus,
    step1?: OrderStatus,
    step2?: OrderStatus
}

const OrderStatusStep: React.FC<OrderStatusStepProps> = ({order}) => {
    const [orderStatusStep, setOrderStatusStep] = useState<OrderStatusStep>({});
    const [activeStep, setActiveStep] = useState<number>(0);
    
    useEffect(() => {
        handleSteps()
    }, []);
    
    const handleSteps = () => {
        let activeStep = 0
        let orderStatusStep : OrderStatusStep = {
            step0: order.orderStatusList.find(orderStatus => orderStatus.status === OrderStatusType.PROCESSED)
        };
        
        order.orderStatusList.forEach((orderStatus, index) => {
        
        });
    
        let step1 = order.orderStatusList.find(orderStatus => orderStatus.status === OrderStatusType.AWAITING_PAYMENT)
        if(step1) {
            orderStatusStep.step1 = step1
        }
        step1 = order.orderStatusList.find(orderStatus => orderStatus.status === OrderStatusType.APPROVED)
        if(step1) {
            orderStatusStep.step1 = step1
        }
        step1 = order.orderStatusList.find(orderStatus => orderStatus.status === OrderStatusType.PAYMENT_DECLINED)
        if(step1) {
            orderStatusStep.step1 = step1
        }
        if(orderStatusStep.step1) {
            activeStep = 1
        }
        
        orderStatusStep.step2 = order.orderStatusList.find(orderStatus => orderStatus.status === OrderStatusType.CANCELLED)
        if(orderStatusStep.step2 || order.earnedCoinsCharged) {
            activeStep = 2
        }
        
        setOrderStatusStep(orderStatusStep)
        setActiveStep(activeStep)
    
        return activeStep
    }
    
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 3 }} elevation={0}>
                <Stepper sx={{ width: '100%', mb: 3, fontSize: '2rem'}} activeStep={activeStep} alternativeLabel>
                    <Step sx={{ width: '100%', mb: 3, fontSize: '2rem'}} key={"created"}>
                        <StepLabel sx={{ width: '100%', fontSize: '2rem'}}>
                            <p style={{fontSize: '1.5rem'}}>
                                Pedido Efetuado
                                <br />
                                {orderStatusStep.step0?.dateCreated && (formatDateTime(orderStatusStep.step0.dateCreated))}
                            </p>
                        </StepLabel>
                    </Step>
                    <Step key={"paid"}>
                        {activeStep === 0 ? (
                            <StepLabel sx={{ width: '100%', fontSize: '2rem'}}>
                                <p style={{fontSize: '1.5rem'}}>Aguardando Confirmação do Pagamento</p>
                            </StepLabel>
                        ) : (
                            <StepLabel sx={{ width: '100%', fontSize: '2rem'}}>
                                <p style={{fontSize: '1.5rem'}}>
                                    {orderStatusStep.step1?.status ? OrderStatusTypeLabel[orderStatusStep.step1.status] : "---"}
                                    <br />
                                    {orderStatusStep.step1?.dateCreated && (formatDateTime(orderStatusStep.step1.dateCreated))}
                                </p>
                            </StepLabel>
                        )}
                    </Step>
                    <Step key={"coinsLoaded"}>
                        <StepLabel sx={{ width: '100%', fontSize: '2rem'}}>
                            {activeStep === 2 && orderStatusStep.step2?.dateCreated && (
                                <p style={{fontSize: '1.5rem'}}>
                                    Pedido Cancelado <br />
                                    {formatDateTime(orderStatusStep.step2.dateCreated)}
                                </p>
                            )}
                            {activeStep === 2 && order.earnedCoinsChargedDate && (
                                <p style={{fontSize: '1.5rem'}}>
                                    Créditos Carregados <br />
                                    {formatDateTime(order.earnedCoinsChargedDate)}
                                </p>
                            )}
                            {activeStep < 2 && (
                                <p style={{fontSize: '1.5rem'}}>
                                    Créditos Pendentes
                                </p>
                            )}
                        </StepLabel>
                    </Step>
                </Stepper>
            </Paper>
        </Box>
    )
}

export default OrderStatusStep