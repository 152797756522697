import React, {useState, useEffect, useContext} from 'react';
import coinsImg from '../../assets/coins.svg'
import styles from './style.module.scss';
import {ToastContainer, toast} from 'react-toastify';
import InformationGroup from "../../components/information/InformationGroup";
import {getEasyCoinsPacks} from "../../services/easyCoinsServices";
import {getInitialEasyCoins} from "../../modelos/EasyCoins";
import {Link, useHistory} from 'react-router-dom';
import {CloseIco} from '../../components/Icons';
import api from '../../services/api';
import {EasyCoins} from '../../modelos/EasyCoins';
import {BreadCrumbs} from '../../components/BreadCrumbs';
import StorageUtils from "../../utils/StorageUtils";
import {CartItemLocal} from "../../modelos/Cart";
import CartUtils from "../../utils/CartUtils";
import {Context} from "../../contexts/ContextProvider";

type CardProps = {
    brand: string
    dateCreated: Date
    expirationMonth: string
    expirationYear: string
    id: string
    main: false
    maskedNumber: string
    ownerName: string
    type: string
}

const BuyCoins = () => {
    const [cartItemsLocal, setCartItemsLocal] = useState<CartItemLocal[]>([]);
    const [easyCoinsPackage, setEasyCoinsPackage] = useState([getInitialEasyCoins()]);
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState<EasyCoins>();
    const Router = useHistory();
    const [cards, setCards] = useState<CardProps[]>()
    const context = useContext(Context)
    
    useEffect(() => {
        /*const cartItems : CartItemLocal[] = CartUtils.getCartItemsLocal();
        if(cartItems?.length > 0) {
            setCartItemsLocal(cartItems)
        }*/
        
        if(CartUtils.hasCartItemsLocal()) {
            setCartItemsLocal(CartUtils.getCartItemsLocal)
            context.setHasCartItems(true)
        } else {
            context.setHasCartItems(false)
        }
        
        loadEasyCoinsPacks();
        getRegisteredCards()
    }, [])
    
    const loadEasyCoinsPacks = async () => {
        try {
            const results: any = await getEasyCoinsPacks()
            setEasyCoinsPackage(results)
        } catch (e) {
            //Todo - Verificar o que fazer quando não encontrar packs. Toast? Mensagem padrão na tela igual tela de carrinho?
            //console.error('Não foi possível exibir os packs de EasyCoins.')
        }
    }
    const crumbContent = [
        {
            name: 'Pagina inicial',
            route: '/'
        },
        {
            name: 'Easycoins',
            route: '/easycoins'
        }
    ]
    
    
    function getInfoPackOfCoins(object: EasyCoins) {
        setModal(true)
        setModalContent(object)
    }
    
    function handleTheCart(packageId: string, goToCart?: boolean) {
        let cartItemsLocal : CartItemLocal[] = []
        let cartItemToAdd = {
            pack: {
                _id: packageId
            },
            quantity: 1
        }
        
        if (packageId) {
            let cartItemsLocalString = StorageUtils.getStorageItem(StorageUtils.KEYS.CART_ITEMS)
            
            if(cartItemsLocalString) {
                cartItemsLocal = JSON.parse(cartItemsLocalString)
                
                let itemLocalIndex = cartItemsLocal.map(e => { return e.pack._id }).indexOf(packageId)
                
                if(itemLocalIndex != -1) {
                    cartItemsLocal[itemLocalIndex].quantity++;
                    CartUtils.setCartItemsLocal([...cartItemsLocal])
                } else {
                    cartItemsLocal.push(cartItemToAdd)
                    CartUtils.setCartItemsLocal([...cartItemsLocal])
                }
                
            } else {
                cartItemsLocal.push(cartItemToAdd)
                CartUtils.setCartItemsLocal([...cartItemsLocal])
            }
            context.setHasCartItems(true)
        
            setModal(false)
            
            /*let idx = product.map(e => {
                return e.pack._id
            }).indexOf(packageId)
            
            if (idx != -1) {
                product[idx].quantity++;
                setProduto([...product])
            } else {
                setProduto([...product,
                    {
                        pack: {
                            _id: packageId
                        },
                        quantity: 1
                    }
                ])
            }
            
            setModal(false)*/
        }
        
        if (goToCart) {
            if (cartItemsLocal.length >= 1) {
                Router.push('/carrinho')
            } else {
                alert('fake: compra realizada com sucesso !')
            }
        } else {
            notify()
        }
    }
    
    const notify = () => {
        toast.success("produto adicionado com sucesso");
    }
    
    const getRegisteredCards = async () => {
        let access_token = localStorage.getItem('userToken');
        if (access_token) {
            try {
                const response: any = await api.get('/p/customer/card', {headers: {"Authorization": `Bearer ${access_token}`}})
                setCards(response.data)
                
            } catch (error) {
                //Cliente sem cartão cadastrado
            }
        }
    }
    
    return (
        <>
            <div className="container">
                <ToastContainer hideProgressBar={true}/>
                <div className={styles.topPage}>
                    <BreadCrumbs content={crumbContent}/>
                    
                    <h1>Pacotes de easycoins</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh lacus, feugiat venenatis lectus
                        lacinia.</p>
                </div>
                <div className={styles.row}>
                    {easyCoinsPackage.map(coinAdv => {
                        return (
                            <div className={styles.col} key={coinAdv._id}>
                                <div className={styles.content}>
                                    <div className={styles.cardContent}>
                                        <img src={coinsImg} alt=""/>
                                        <strong>{coinAdv.name}</strong>
                                        <span>{coinAdv.shortDescription}</span>
                                    </div>
                                    <button onClick={() => getInfoPackOfCoins(coinAdv)}>
                                        {coinAdv.costPrice.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                
                <InformationGroup/>
                {modal &&
                <div className={styles.modalCallToAction}>
                    <div className={styles.content}>
                        <button onClick={() => setModal(false)} className={styles.close}>
                            <CloseIco/>
                        </button>
                        <div className={styles.containerTable}>
                            <div className={styles.coinsInfo}>
                                <div className={styles.img}>
                                    <img src={coinsImg} alt=""/>
                                </div>
                                <strong>{modalContent?.coins} easycoins</strong>
                                <span>{modalContent?.shortDescription}</span>
                            </div>

                            <table>
                                <tr>
                                    <th>Valor unitário:</th>
                                    <td>{modalContent?.costPrice.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</td>
                                </tr>
                                <tr>
                                    <th>Taxa de serviço:</th>
                                    <td>{modalContent?.fees.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</td>
                                </tr>
                                <tr>
                                    <th>Total:</th>
                                    <td>{modalContent?.costPrice.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</td>
                                </tr>
                                <span>Pagar com</span><br/>
                                <select className={styles.selectCard}>
                                    {cards && cards.map(content => {
                                        return (
                                            <option key={content.id}
                                                    value=""> {content.brand} - {content.maskedNumber}</option>
                                        )
                                    })}
                                </select>
                            </table>

                        </div>
                        <span className={styles.lgpd}>Ao clicar em “Comprar agora” você concorda com os termos de serviço do EasyBid 4U.</span>
                        <div className={styles.groupOfButtons}>
                            <button
                                onClick={() => modalContent && handleTheCart(modalContent._id)}
                            >
                                Adicionar ao carrinho
                            </button>
                            <button

                                onClick={() => modalContent && handleTheCart(modalContent._id, true)}
                            >
                                {cartItemsLocal.length >= 1 ? 'Ver carrinho' : 'Comprar agora'}
                            </button>

                        </div>

                    </div>

                </div>
                
                }
            
            </div>
        
        </>
    )
}

export default BuyCoins