import React, { useState, useEffect } from 'react';
import { InputBox, Icon } from '../styles'
import { CustomizedInput, Eye } from './styles'
import eye from '../../../../assets/eye.svg'
import { EyeIco } from '../../../Icons';

interface StyledInputProps {
    placeholder: string,
    onchange?: any,
    showeye?: boolean,
    type?: string,
    icon?: any
    name?: string,
    value?: string,
    required?: boolean,
    reverse?: boolean,
    size?: string,
}
const StyledInput: React.FC<StyledInputProps> = ({ placeholder, value, showeye, type, icon, onchange, name, required, reverse, size }) => {
    const [isPasswordVisble, setIsPasswordVisible] = useState(false)
    const [privateType, setPrivateType] = useState('')

    useEffect(() => {
        const enablePasswordVisibility = () => {
            if (type === 'password') {
                const str: string = isPasswordVisble ? 'text' : 'password'
                setPrivateType(str)
            }

        }
        enablePasswordVisibility()
    }, [isPasswordVisble, type])


    return (
        <InputBox isrowreverse={reverse ? true : false} size={size}>
            {icon && <Icon width={24} src={icon} alt={'ícone do input'} />}
            <CustomizedInput
                placeholder={placeholder}
                type={privateType || type}
                onChange={e => onchange(e)}
                name={name}
                value={value}
                required={required}
            />
            <Eye onClick={() => setIsPasswordVisible(!isPasswordVisble)} showeye={showeye} sm='1' lg='1' style={{ cursor: 'pointer' }}>
                <EyeIco open={!isPasswordVisble}/>
                {/* <img src={eye} alt={'visualizar senha.'} /> */}
            </Eye>
        </InputBox>)
}

export default StyledInput