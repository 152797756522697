import React, { useState, FormEvent } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import StyledInput from '../../components/common/inputs/styled-input/StyledInput';
import { FormContainer, CustomizedButton, StyledLink } from './styles';
import { ButtonLabel } from '../../components/common/buttons/styles';
import { Title } from '../../components/common/styles/styles';
import { startRecoveryPassword } from '../../services/ForgotPasswordService';
import user from '../../assets/user.svg';
import { SuccessIco } from '../../components/Icons';

import ReCAPTCHA from "react-google-recaptcha";

import {
    CustomizedSubtitle,
    TitleBox
} from '../login/styles';
import styles from "../registers/styles.module.scss";

const google_recaptcha = {
    dev: {
        token: '6LfHZw8dAAAAAF3QWrphGxTT4kbUs5nqdYIxuMvE',
        src: 'https://www.google.com/recaptcha/enterprise.js?render=6LfHZw8dAAAAAF3QWrphGxTT4kbUs5nqdYIxuMvE%22' 
    },
    prod: {
        token: '6LdJng4dAAAAAPNgyXB7WUPasndHwME-a-W0kIUA',
        src: 'https://www.google.com/recaptcha/enterprise.js?render=6LdJng4dAAAAAPNgyXB7WUPasndHwME-a-W0kIUA%22%3E%3C%2Fscript%3E'
    }
}

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [reCaptcha, setReCaptcha] = useState<string | null>(null);
    const [isSended, setIsSended] = useState(false);

    async function handleSendForgotPassword(event: FormEvent){
        event.preventDefault();

        if(email.trim().length === 0) return;
        if(!reCaptcha){
            toast.error('Clique em não sou um Robô');
            return;
        }

        const data = await startRecoveryPassword(email, reCaptcha);

        if(data.result) setIsSended(true);
        else toast.error(data.response);
    }

    return (
        <div className={styles.container}>
            <FormContainer onSubmit={handleSendForgotPassword}>
                <ToastContainer />
                <TitleBox>
                    <Title>Esqueceu a senha?</Title>
                    { !isSended ? (
                        <CustomizedSubtitle>Informe o email abaixo para recuperar sua conta</CustomizedSubtitle>
                    ):''}
                </TitleBox>
                <br/>
                { isSended ? (<>
                    <SuccessIco/>
                    <strong style={{ fontSize: '22px'}}>Tudo certo</strong>
                    <CustomizedSubtitle style={{ textAlign: 'center'}}>As instruções para cadastrar uma nova senha foram enviadas para o email informado.</CustomizedSubtitle>
                </>):(<>
                    <StyledInput
                        icon={user}
                        type="email"
                        name={'email'}
                        placeholder={"Email"}
                        value={email}
                        onchange={(e: any) => setEmail(e.target.value)}
                        required
                    />
                    <ReCAPTCHA
                        sitekey={google_recaptcha.dev.token}
                        onChange={setReCaptcha}
                    />
                    <CustomizedButton type="submit">
                        <ButtonLabel style={{ color: '#FFF' }}>Iniciar recuperação de senha</ButtonLabel>
                    </CustomizedButton>
                    <StyledLink to="/login">Voltar ao Login</StyledLink>
                </>)}
            </FormContainer>
        </div>
    );
}

export default ForgetPassword