import {createContext, ReactNode, useEffect, useState} from "react";
import {PaymentForm} from "../services/CardService";
import CartUtils from "../utils/CartUtils";
import {PaymentOption} from "../modelos/Cart";
import {Wallet} from "../modelos/Wallet";
import {Status} from "../modelos/StatusType";

type ContextProviderType = {
    paymentOption: PaymentOption|null
    setPaymentOption: (paymentOption: PaymentOption) => void,
    paymentForm: PaymentForm|null
    setPaymentForm: (paymentForm: PaymentForm) => void,
    easyCoins: number,
    setEasyCoins: (easyCoins: number) => void,
    wallet: Wallet,
    setWallet: (wallet: Wallet) => void,
    hasCartItems: boolean,
    setHasCartItems: (hasCartItems: boolean) => void,
    clearAfterCheckout: () => void,
}

type ContextProviderProps = {
    children: ReactNode;
}

export const Context = createContext({} as ContextProviderType)

export function ContextProvider(props: ContextProviderProps) {
    const [paymentForm, setPaymentForm] = useState<PaymentForm|null>(null);
    const [paymentOption, setPaymentOption] = useState<PaymentOption|null>(null);
    const [hasCartItems, setHasCartItems] = useState<boolean>(false);
    const [easyCoins, setEasyCoins] = useState<number>(0);
    const [wallet, setWallet] = useState<Wallet>({
        coins: 0,
        deposit: 0,
        withdraw: 0,
        status: Status.ACTIVE,
        deleted: false
    });
    
    const clearAfterCheckout = () => {
        setPaymentForm(null)
        setPaymentOption(null)
        setHasCartItems(false)
    }
    
    useEffect(() => {
        /*let cart = localStorage.getItem('CART_COINS')
        if (cart) setProduto(JSON.parse(cart));*/
        if(CartUtils.hasCartItemsLocal()) {
            setHasCartItems(true)
        } else {
            setHasCartItems(false)
        }
    }, [hasCartItems])
    
    /*useEffect(() => {
        if (!paymentForm) return;
        localStorage.setItem('CART_COINS', JSON.stringify(product))
    }, [paymentForm])*/
    
    return (
        <Context.Provider value={{ paymentOption, setPaymentOption, paymentForm, setPaymentForm, easyCoins, setEasyCoins,
            wallet, setWallet, hasCartItems, setHasCartItems, clearAfterCheckout }}>
            {props.children}
        </Context.Provider>
    )
}