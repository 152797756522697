import {Order, OrderListResponse} from "../modelos/Order";
import {isAuthenticated} from "./AuthService";
import StorageUtils from "../utils/StorageUtils";
import api from "./api";

export const getOrders = async (max: number, offset: number) : Promise<OrderListResponse | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            const userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            const response = await api.get(`/p/order?max=${max}&offset=${offset}`,
                { headers: { "Authorization": `Bearer ${userToken}` } }
            )
            
            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}
export const getOrder = async (orderId: string) : Promise<Order | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            const userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            const response = await api.get(`/p/order/`+orderId,
                { headers: { "Authorization": `Bearer ${userToken}` } }
            )
            
            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}