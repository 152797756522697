import styled from 'styled-components'
import { Button, Card, CardText, CardTitle, Input, InputGroup } from 'reactstrap';


export const Conatiner = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 180px;
    background: var(--color-success);  
    color: var(--color-text-off-white);  
    border-radius: 8px;
    padding: 24px 27px;

    width: calc(100%);
    margin-top: 10px;
    overflow: hidden;
    
    @media(min-width: 700px){
      width: 100%;
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
    }
    
`

export const Title = styled(CardTitle)`
    text-align: center;
    font-size: 19px;
    line-height: 23px;

    @media(min-width: 700px){
        font-size: 20px;
    }
`

export const Text = styled(CardText)`
    text-align: center;
    font-size: 14px;
    color: var(--color-text-lighter);
`

export const InputGroupCustomized = styled(InputGroup)`
    width: 100%;
    height: 41px;
    border-radius: 8px;
    border: 1px solid var(--color-text-off-white);

    @media(min-width: 700px){
        align-self: center;
        width: 446px;
     }
`

export const InputCustomized = styled(Input)`
    background:var(--color-primary);
    font-size: 16px;
    border: none ;
    border-radius: 8px;
    color: var(--color-text-off-white);
    ::placeholder {
        color: var(--color-text-off-white);
    }
    &:focus{
        background:var(--color-primary);
        color: var(--color-text-off-white);
    }

    
`

export const ButtonCustomized = styled(Button)`
    width: 52px;
    height: 100%;
    font-size: 16px;
    color: var(--color-text-lighter);

    @media(min-width: 700px){
        width: 108px;
     }
`

