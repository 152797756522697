import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export interface BreadcrumbsList{
  name: string,
  fn?: () => void,
  href?: string
}
interface BreadcrumbsProps{
  list?: BreadcrumbsList[];
}
export function Breadcrumbs({ list = [] } : BreadcrumbsProps){
    let random = Math.floor(Math.random() * 10);
  return(
    <ul className={styles.breadcrumbs}>
      <li>Pagina Inicial</li>
      <li key={'breadcrumb-'+(random++)} className={list.length === 0 ? styles.active :''}>
        <Link to="/minha-conta">Minha Conta</Link>
      </li>
      {list.map((item, idx) => { return item.fn ? (
        <li
            key={'breadcrumb-'+(random++)+'-'+idx}
            className={idx === list.length - 1 ? styles.active : ''}
          onClick={item.fn}
        >{item.name}</li>
      ):(
        <li
            key={'breadcrumb-'+(random++)}
          className={idx === list.length - 1 ? styles.active : ''}
        ><Link to={item.href ?? '#'}>{item.name}</Link></li>
      );})}
    </ul>
  );
}