import { BrowserRouter as Router } from "react-router-dom";
import Routes from './RFCRoutes';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
// import history from './services/history'
function App() {

  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
