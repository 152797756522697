import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {toast, ToastContainer} from "react-toastify";
import {Breadcrumbs} from "../../../components/my-account/breadcrumbs";
import {Sidebar} from "../../../components/my-account/sidebar";
import {isAuthenticated, signOut} from "../../../services/AuthService";

import myAccountStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import {Table} from "reactstrap";
import {Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {Order, OrderListResponse, OrderStatusType, OrderStatusTypeLabel} from "../../../modelos/Order";
import {formatDate, formatDateTime} from "../../../utils/utilities";
import {getOrders} from "../../../services/OrderService";

export default function MyOrders() {
    const [orderListResponse, setOrderListResponse] = useState<OrderListResponse>();
    const [orders, setOrders] = useState<Order[]>();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    useEffect(() => {
        getCustomerOrders()
    }, [page,rowsPerPage]);
    
    const getCustomerOrders = async () => {
        if(isAuthenticated(true, true)){
            try {
                const orderListResponse: OrderListResponse | undefined = await getOrders(rowsPerPage, page)
                if (orderListResponse) {
                    const orders: Order[] | undefined = orderListResponse?.result
                    setOrderListResponse(orderListResponse)
                    setOrders(orders)
                } else {
                    console.log("Sem pedidos no momento")
                }
                
            } catch (error) {
                console.log("Houve um erro ao buscar pedidos: ", error)
                if (error.response) {
                    if (error.response.status !== 404) {
                        toast.error("Houve um erro ao buscar seus pedidos, tente novamente mais tarde!");
                    }
                }
            }
        } else {
            history.push('/login');
            return;
        }
    }
    
    const openOrder = (orderId: number) => {
        history.push("/minha-conta/minhas-compras/"+orderId)
    }
    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return (
        <div className={myAccountStyles.container}>
            <ToastContainer/>
            <Breadcrumbs list={[{name: 'Minhas Compras'}]}/>
            <div className={styles.content}>
                <Sidebar active="Minhas Compras"/>
                <main>
                    <h3 className={styles.title}>
                        {'Minhas Compras'}
                    </h3>
                    <TableContainer component={Paper} sx={{ width: '100%', mb: 3 }} elevation={2}>
                        <Table aria-label="simple table" aria-labelledby="tableTitle" size={'medium'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={styles.tableHeader} component="th"
                                               align="center">Número</TableCell>
                                    <TableCell className={styles.tableHeader} component="th"
                                               align="center">Data</TableCell>
                                    <TableCell className={styles.tableHeader} component="th"
                                               align="center">Status</TableCell>
                                    <TableCell className={styles.tableHeader} component="th"
                                               align="center">EasyCoins</TableCell>
                                    <TableCell className={styles.tableHeader} component="th"
                                               align="center">Valor</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders ? orders.map((order, index) => (
                                    <TableRow
                                        hover
                                        key={index}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}, cursor: 'pointer' }}
                                        onClick={() => openOrder(order.orderId)}
                                    >
                                        <TableCell className={styles.tableBody} align="center" component="th"
                                                   scope="row">
                                            {order.orderId}
                                        </TableCell>
                                        <TableCell className={styles.tableBody}
                                                   align="center">{formatDate(order.dateCreated)}</TableCell>
                                        <TableCell className={styles.tableBody}
                                                   align="center">{OrderStatusTypeLabel[order.currentOrderStatus.status]}</TableCell>
                                        <TableCell className={styles.tableBody}
                                                   align="center">{order.earnedCoins}</TableCell>
                                        <TableCell className={styles.tableBody}
                                                   align="center">{order.totalPrice.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow
                                        key={9999}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell className={styles.tableBody} colSpan={5} align="center"
                                                   component="td" scope="row">
                                            Sem pedidos para exibir no momento!
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {orders && (
                            <TablePagination
                                className={styles.tablePagination}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                labelRowsPerPage={"Pedidos por página"}
                                labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) {
                                        return `Exibindo ${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`;
                                    }
                                }
                                SelectProps={{className:'styles.tablePagination'}}
                                component="div"
                                count={orderListResponse?.count?? 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </TableContainer>
                </main>
            </div>
        </div>
    );
}