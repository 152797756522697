import styled from 'styled-components'

export const SectionTitle = styled.h1`
    font-size: 24px;
    line-height: 28px;
    align-self: flex-start;
    color: var(--color-text-medium);
    margin: 16px 0;
    padding-left: 25px;

    @media(min-width: 700px){
        display: none;
    }
`
export const SectionTitleFixe = styled.h1`
    font-size: 24px;
    line-height: 28px;
    align-self: flex-start;
    color: var(--color-text-medium);
    margin: 16px 0;
   
`
export const SectionTitleCentered = styled.h1`
    font-size: 24px;
    line-height: 28px;
    align-self: flex-start;
    color: var(--color-text-medium);
    margin: 16px 0;
      text-align: center;
    
    margin-left: 25px;

    @media(min-width: 700px){
        margin-left: auto;
        text-align: center;
    }
  
  //textAlign: isMobile ? "left" : "center", marginLeft: isMobile ? "25px" : ""
   
`
export const ContainerSection = styled.h1`
    margin-bottom: 16px;
    margin-left: 10px;
  
    @media(min-width: 700px){
      margin-left: 25px;
      left: 25px;
    }
`

export const TestimonyBox = styled.div`
    top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
    @media(min-width: 700px){
        margin-top: 20px;
        display: block;
    }
`
export const InfoBox = styled.div`    
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 345px;
    height: 180px;
    padding: 24px 27px;
    background: var(--color-primary-light);
`
export const TitleInfoBox = styled.h2`
    text-align: center;
    font-size: 19px;
    line-height: 23px;
    color: var(--color-text-light);
`
export const ContentInfoBox = styled.span`
    text-align: center;
    font-size: 14px;
    color: var(--color-text-light);
`
export const Banner = styled.img`
    width: 100%; 
    max-width: 318px;
    height: auto;
    max-height: 180px; 
    border-radius:  8px;

    @media(min-width: 700px){
        width: calc(100% - 30px);
        max-width: 1500px;
        height: auto;
        max-height: 350px;
    }
`
export const Logo = styled.div`
    background: var(--color-primary-light);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 45px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 23px;
    color: var(--color-text-medium);
    margin-left: 13px;

    @media(min-width: 700px){
        width: 170px;
        height: 66px;
    }
`
export const BannerBox = styled.div`
    position: absolute;
    border-radius:  8px;
    width: 100%;
    height: auto;
    max-height: 180px;
    left: 0px;
    top: 97px;
    @media(min-width: 700px){
        width: calc(100% - 30px);
        max-width: 1500px;
        height: auto;
        max-height: 350px;
        margin-left: auto;
        margin-right: auto;
        left: 15px;
        right: 15px !important;
        top: 120px;
    }
`
export const CategoryBox = styled.div`
    margin-top: 105px;    
    margin-bottom: 16px;

    @media(min-width: 700px){
        margin-top: 190px;
        margin-bottom: 32px;
    }
`
export const HelperSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: 700px){
        margin: 32px auto;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }
`