import axios, {AxiosResponse} from 'axios';

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST

export const getEasyCoinsPacks = async () => {
    try {
        const url: string = `${BACKEND_HOST}/api/o/pack`
        const response: AxiosResponse = await axios.get(url)
        return response.data
    } catch (err) {
        throw new Error(err)
    }
}

export const getExampleEasyCoinsPacks = () => {
    return [
        { bidQuantity: 50, bonus: 300, price: 'R$ 50'},
        { bidQuantity: 100, bonus: 300, price: 'R$ 100'},
        { bidQuantity: 300, bonus: 300, price: 'R$ 300'},
        { bidQuantity: 600, bonus: 300, price: 'R$ 600'},
        { bidQuantity: 1000, bonus: 600, price: 'R$ 1000'},
        { bidQuantity: 2000, bonus: 1500, price: 'R$ 2000'}
    ]
    
}