import styles from './styles.module.scss';

export enum AlertModalType {
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR",
    NONE = "NONE",
}

interface AlertModalProps {
    type: AlertModalType;
    onClose: () => void;
    message?: string;
}

export function AlertModal({type, onClose, message}: AlertModalProps) {
    return (
        <div className={styles.overlay}
             onClick={event => {
                 let e = event.target;
                 // @ts-ignore
                 if (e.classList.contains(styles.overlay)) onClose();
             }}
        >
            <div className={styles.container}>
                <div className={styles.content}>
                    
                    <button type="button" onClick={onClose} className={styles.closeModal}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 3L3 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3 3L21 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    
                    {type === AlertModalType.SUCCESS && (
                        <>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="37" stroke="#00C1A5" strokeWidth="5"/>
                                <path d="M53.3333 33L35.9167 50.4167L28 42.5" stroke="#00C1A5" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <h3>{message}</h3>
                        </>
                    )}
                    
                    {type === AlertModalType.WARNING && (
                        <>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="37" stroke="#FEBB22" strokeWidth="5"/>
                                <path d="M43.7166 47.6764H36.4171L35.2674 20H44.8663L43.7166 47.6764ZM35 56.2992C35 54.927 35.4724 53.8051 36.4171 52.9335C37.3797 52.0434 38.574 51.5984 40 51.5984C41.426 51.5984 42.6114 52.0434 43.5561 52.9335C44.5187 53.8051 45 54.927 45 56.2992C45 57.6714 44.5187 58.8026 43.5561 59.6927C42.6114 60.5642 41.426 61 40 61C38.574 61 37.3797 60.5642 36.4171 59.6927C35.4724 58.8026 35 57.6714 35 56.2992Z" fill="#FEBB22"/>
                            </svg>
                            {/*<div className={styles.content} dangerouslySetInnerHTML={{__html: message}} />*/}
                            <h3>{message}</h3>
                        </>
                    )}
                    
                    {type === AlertModalType.ERROR && (
                        <>
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="40" cy="40" r="37" stroke="#FD6151" strokeWidth="5"/>
                                <path d="M52.75 27.25L27.25 52.75" stroke="#FD6151" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M27.25 27.25L52.75 52.75" stroke="#FD6151" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <h3>{message}</h3>
                        </>
                    )}
                    
                </div>
            </div>
        </div>
    );
}