import styled from 'styled-components'
import { Title } from '../../components/common/styles/styles'

export const ContainerText = styled.div`
    font-size: 1.5rem;
    color: var(--color-primary-light);
    margin: 8px;
    @media(min-width: 700px){
       margin: 0;
    }
`
export const HeadTitle = styled(Title)`
    margin: 10px 0px 0px 8px;
    @media(min-width: 700px){
        margin: 0;
     }
`
export const AdjustMargin = styled(Title)`
    padding-top: 300px;
`