

export function NewDate(date: Date) {
    let value = new Date(date);
    let day = value.getDate().toString().padStart(2, '0');
    let month = (value.getMonth() + 1).toString().padStart(2, '0');
    let year = value.getFullYear();
    let hour = value.getHours().toString().padStart(2, '0');
    let minutes = value.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} - Início ${hour}:${minutes}h`
}