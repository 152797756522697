import {CartVO} from "./Cart";
import {Refund} from "./Refund";
import {Audit} from "./Audit";
import {Notepad} from "./Notepad";
import {PaymentFormVO} from "./Payment";
import {ListResponse} from "./ListResponse";

export interface OrderListResponse extends ListResponse {
    result: Array<Order>
}

export interface Order extends CartVO {
    orderId: number;
    cartId: string;
    cartDate: string;
    refunds?: Refund[];
    observations?: string;
    paymentForm: PaymentFormVO;
    changePaymentAvailable: boolean;
    tokenToChangePayment?: string;
    currentOrderStatus: OrderStatus;
    orderStatusList: OrderStatus[];
    orderEmailSent?: string;
    orderSentToProduction?: string;
    auditList?: Audit[];
    notepadList?: Notepad[];
    paymentNotificationData?: Map<string, object>;
    resended: boolean;
    tenant?: string;
    earnedCoinsCharged: boolean;
    earnedCoinsChargedDate?: string;
}

export interface OrderStatus {
    status: OrderStatusType;
    dateCreated: string
}

export enum OrderStatusType {
    FINALIZED = "FINALIZED",
    CANCELLED = "CANCELLED",
    DELIVERED = "DELIVERED",
    PAYMENT_DECLINED = "PAYMENT_DECLINED",
    RETURNED = "RETURNED",
    WAITING_WITHDRAW = "WAITING_WITHDRAW",
    SENT = "SENT",
    PREPARED = "PREPARED",
    APPROVED = "APPROVED",
    PROCESSED = "PROCESSED",
    REFUNDED = "REFUNDED",
    PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
    AWAITING_PAYMENT = "AWAITING_PAYMENT",
    BILL_GENERATED = "BILL_GENERATED",
    NONE = "NONE",
}
export enum OrderStatusTypeLabel {
    FINALIZED = "Finalizado",
    CANCELLED = "Cancelado",
    DELIVERED = "Entregue",
    PAYMENT_DECLINED = "Pagamento negado",
    RETURNED = "Pedido devolvido",
    WAITING_WITHDRAW = "Aguardando retirada",
    SENT = "Enviado",
    PREPARED = "Envio preparado",
    APPROVED = "Aprovado",
    PROCESSED = "Processado",
    REFUNDED = "Reembolso Total",
    PARTIALLY_REFUNDED = "Reembolso Parcial",
    AWAITING_PAYMENT = "Aguardando pagamento",
    BILL_GENERATED = "Boleto Gerado",
    NONE = "NONE",
}