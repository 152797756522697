import axios from 'axios';

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
const BASE_URL = `${BACKEND_HOST}/api/o/customer-login/`;

export interface ResponseResult{
  result: boolean;
  response: any;
}

export async function startRecoveryPassword(email: string, captcha: string){
  try{
    const { data } = await axios.put(`${BASE_URL}init-recover-account`,{
      id: email,
      captcha
    });

    return {
      result: true,
      response: data
    };
  }catch(err: any){
    let message = "Houve um erro ao realizar a requisição";
    if(err && err.response && err.response.data && err.response.data.message){
      message = err.response.data.message;
    }

    return {
      result: false,
      response: message
    };
  }
}
export async function checkRecoveryPassword(email: string, token: string){
  try{
    const { data } = await axios.put(`${BASE_URL}can-recover-account`,{
      id: email,
      recoverToken: token
    });

    return {
      result: true,
      response: data
    };
  }catch(err: any){
    let message = "Houve um erro ao realizar a requisição";
    if(err && err.response && err.response.data && err.response.data.message){
      message = err.response.data.message;
      message = message.replace('Nao permitido', 'Token inválido ou expirado');
    }

    return {
      result: false,
      response: message
    };
  }
}
export async function changePassword(
  email: string,
  token: string,
  captcha: string,
  pass: string,
  checkPass: string
){
  try{
    const { data } = await axios.put(`${BASE_URL}recover-account`,{
      id: email,
      recoverToken: token,
      captcha,
      pass,
      checkPass
    });

    return {
      result: true,
      response: data
    };
  }catch(err: any){
    let message = "Houve um erro ao realizar a requisição";
    if(err && err.response && err.response.data && err.response.data.message){
      message = err.response.data.message;
    }

    return {
      result: false,
      response: message
    };
  }
}