import styled from 'styled-components';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom'

export const Container = styled(Col)`
    display: flex;
    flex-direction:column;
    background: var(--color-background);
    padding: 0;
    width: 100%;
    max-width: 345px;
    max-height: 448px;
    margin-top: 50px;
    
    @media(min-width: 700px){
        flex-direction:${props => props.reverse ? 'row-reverse' : 'row'};
        align-items: center;
        max-width: 740px;
        max-height: 230px;
        padding: 0;
    }
`
export const Image = styled.img`
    border-radius: 4px;
    align-self: center;
    width: 100%;
    height: 310px;
    
    @media(min-width: 700px){
        width: 234px;
        height: 212px;
    }
`

export const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 10px;
    
    @media(min-width: 700px){
        border-top: none;
        padding-left: 40px;
        padding-right: 54px;
        height: 180px;
    }
`
export const BoxTitle = styled.h1`
    font-size: 20px;
    color: var(--color-primary-light);
`
export const TextContent = styled.span`
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-medium);
`
export const InformationLink = styled(Link)`
    cursor: pointer;
    margin-top: 15px;
  font-size: 1.5rem;
    color: var(--color-text-base);
  text-decoration: none;
`
export const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-bottom: 50px;

    @media(min-width: 700px){
        margin: 10px auto 50px;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
    }
`