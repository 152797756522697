import React from "react";
import { Link } from "react-router-dom";
import { EmailIco, FacebookIco, InstaIco, TwitterIco, WhatsAppIco, YoutubeIco } from "../../Icons";
import styles from './styles.module.scss'

export const Footer = () => {
    return (
        <footer className={styles.container}>
            <div className="container">
                <div className={styles.gridContainer}>.

                    <div className={`${styles.logo} ${styles.item1}`}>
                        <svg width="140" height="28" viewBox="0 0 140 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.5478 1.4C24.3824 1.4 27.4935 4.41 27.4935 8.12V19.8625C27.4935 23.5725 24.3824 26.5825 20.5478 26.5825H8.39277C4.55814 26.6 1.44703 23.59 1.44703 19.88V8.12C1.44703 4.41 4.55814 1.4 8.39277 1.4H20.5478ZM20.5478 0H8.39277C3.76227 0 0 3.64 0 8.12V19.8625C0 24.36 3.76227 28 8.39277 28H20.5297C25.1602 28 28.9225 24.36 28.9225 19.88V8.12C28.9406 3.64 25.1783 0 20.5478 0Z" fill="#D8E0F2" />
                            <path d="M13.8915 16.5371L13.8191 16.6246L13.1137 17.3071L13.9096 18.0771L13.0956 18.8646L12.2997 19.6346L15.7726 22.9946C17.0388 22.8196 18.2326 22.3996 19.2816 21.7871L19.2455 21.7521L13.8915 16.5371Z" fill="#D8E0F2" />
                            <path d="M6.7106 11.095L7.50647 11.865L8.30233 11.095L9.11628 10.3075L10.6899 8.78504L11.5039 7.99754L12.2997 7.22754L11.4858 6.47504L12.2997 5.68754L12.969 5.04004C9.00776 5.67004 5.87856 8.69754 5.24548 12.5125L5.91473 11.865L6.7106 11.095Z" fill="#D8E0F2" />
                            <path d="M21.1085 9.57211L21.9044 10.3421L21.0904 11.1296L23.8398 13.7896C23.7312 9.57211 20.6382 6.05461 16.5323 5.16211L20.2946 8.80211L21.1085 9.57211Z" fill="#D8E0F2" />
                            <path d="M10.6899 21.1916L9.89404 20.4216L9.08008 19.6341L5.31781 15.9941C6.24029 19.9841 9.87595 22.9591 14.2351 23.0641L11.4858 20.4041L10.6899 21.1916Z" fill="#D8E0F2" />
                            <path d="M19.4987 12.6694L18.6847 13.4569L17.8889 12.6869L17.1111 13.4394L22.4832 18.6369L22.5193 18.6719C23.1524 17.6569 23.5865 16.5019 23.7674 15.2769L20.2945 11.8994L19.4987 12.6694Z" fill="#D8E0F2" />
                            <path d="M16.2972 14.2273L15.4832 13.4398L11.4858 9.57227L9.91217 11.0948L13.8915 14.9798L14.7235 15.7498L14.7055 15.7673L20.0775 20.9648L21.6693 19.4073L16.2972 14.2098V14.2273Z" fill="#D8E0F2" />
                            <path d="M9.89405 18.8469L10.6899 19.6169L12.2997 18.0769L11.5039 17.3069L13.0052 15.8369L13.0956 15.7669L9.09818 11.8994L7.50645 13.4394L6.71059 12.6694L5.10077 14.2094L5.91472 14.9794L9.89405 18.8469Z" fill="#D8E0F2" />
                            <path d="M12.2997 8.78457L16.2972 12.6521L17.8889 11.1121L18.6848 11.8821L19.4806 11.1296L20.2765 10.3421L19.4806 9.57207L15.4832 5.70457L14.6873 4.93457L13.0956 6.47457L13.8915 7.24457L12.2997 8.78457Z" fill="#D8E0F2" />
                            <path d="M13.8915 16.5371L13.8191 16.6246L13.1137 17.3071L13.9096 18.0771L13.0956 18.8646L12.2997 19.6346L15.7726 22.9946C17.0388 22.8196 18.2326 22.3996 19.2816 21.7871L19.2455 21.7521L13.8915 16.5371ZM15.863 22.7321L12.6434 19.6171L13.2584 19.0221L14.0724 18.2346L14.2351 18.0771L14.0724 17.9196L13.4393 17.3071L13.8915 16.8696L18.9018 21.7171C17.9612 22.2246 16.9302 22.5746 15.863 22.7321Z" fill="#D8E0F2" />
                            <path d="M6.7106 11.095L7.50647 11.865L8.30233 11.095L9.11628 10.3075L10.6899 8.78504L11.5039 7.99754L12.2997 7.22754L11.4858 6.47504L12.2997 5.68754L12.969 5.04004C9.00776 5.67004 5.87856 8.69754 5.24548 12.5125L5.91473 11.865L6.7106 11.095ZM5.62533 11.8125C6.02326 10.2725 6.8553 8.87254 8.03101 7.73504C9.18864 6.59754 10.6357 5.81004 12.2274 5.42504L12.1189 5.53004L11.3049 6.31754L11.1421 6.47504L11.3049 6.63254L11.938 7.24504L11.323 7.84004L10.509 8.62754L8.93541 10.15L8.13954 10.9375L7.52455 11.5325L6.87339 10.92L6.7106 10.7625L6.54781 10.92L5.73386 11.7075L5.62533 11.8125Z" fill="#D8E0F2" />
                            <path d="M21.1085 9.57211L21.9044 10.3421L21.0904 11.1296L23.8398 13.7896C23.7493 9.57211 20.6382 6.05461 16.5323 5.16211L20.2946 8.80211L21.1085 9.57211ZM21.5064 8.36461C22.7002 9.76461 23.4057 11.4271 23.5685 13.2121L21.416 11.1296L22.0672 10.4996L22.23 10.3421L22.0672 10.1846L21.2713 9.41461L20.4573 8.62711L17.3282 5.59961C18.9561 6.10711 20.4212 7.06961 21.5064 8.36461Z" fill="#D8E0F2" />
                            <path d="M10.6899 21.1916L9.89404 20.4216L9.08008 19.6341L5.31781 15.9941C6.24029 19.9841 9.87595 22.9591 14.2351 23.0641L11.4858 20.4041L10.6899 21.1916ZM8.64598 20.8066C7.32556 19.7391 6.33073 18.3216 5.78809 16.7641L8.91729 19.7916L9.73125 20.5791L9.89404 20.7366L10.5271 21.3491L10.6899 21.5066L10.8527 21.3491L11.5039 20.7191L13.6563 22.8016C11.8114 22.6441 10.0749 21.9616 8.64598 20.8066Z" fill="#D8E0F2" />
                            <path d="M19.4806 12.6694L18.6667 13.4569L17.8708 12.6869L17.093 13.4394L22.4651 18.6369L22.5013 18.6719C23.1344 17.6569 23.5685 16.5019 23.7494 15.2769L20.2765 11.8994L19.4806 12.6694ZM22.447 18.2869L17.4367 13.4394L17.8889 13.0019L18.522 13.6144L18.6847 13.7719L18.8475 13.6144L19.6615 12.8269L20.2946 12.2319L23.5142 15.3469C23.3333 16.3794 22.9897 17.3769 22.447 18.2869Z" fill="#D8E0F2" />
                            <path d="M16.2972 14.2273L15.4832 13.4398L11.4858 9.57227L9.91217 11.0948L13.9096 14.9623L14.7235 15.7498L14.7055 15.7673L20.0775 20.9648L21.6874 19.4073L16.2972 14.2273ZM20.0775 20.6148L15.031 15.7498L15.0491 15.7323L14.8863 15.5748L14.0724 14.7873L10.2558 11.0948L11.5039 9.88727L15.3204 13.5798L16.1344 14.3673L16.2791 14.5248L16.2972 14.5073L21.3437 19.3898L20.0775 20.6148Z" fill="#D8E0F2" />
                            <path d="M9.89405 18.8469L10.6899 19.6169L12.2997 18.0594L11.5039 17.2894L13.0052 15.8369L13.0956 15.7494L9.09818 11.8994L7.50645 13.4394L6.71059 12.6694L5.10077 14.2269L5.89663 14.9969L9.89405 18.8469ZM6.6925 12.9669L7.32557 13.5794L7.48837 13.7369L7.65116 13.5794L9.09818 12.2144L12.7519 15.7494L11.323 17.1319L11.1602 17.2894L11.323 17.4469L11.9561 18.0594L10.6899 19.2844L10.0568 18.6719L9.89405 18.5144L6.22222 14.9619L6.05942 14.8044L5.42635 14.1919L6.6925 12.9669Z" fill="#D8E0F2" />
                            <path d="M12.2997 8.80207L16.2972 12.6696L17.8889 11.1296L18.6848 11.8996L19.4806 11.1296L20.2946 10.3421L19.4987 9.57207L15.4832 5.70457L14.6873 4.93457L13.0775 6.49207L13.8734 7.26207L12.2997 8.80207ZM13.4212 6.47457L14.6873 5.24957L15.3204 5.86207L15.4832 6.01957L19.1369 9.55457L19.2997 9.71207L19.9328 10.3246L19.2817 10.9546L19.1189 11.1121L18.6667 11.5496L18.0336 10.9371L17.8708 10.7796L17.7261 10.9371L16.2972 12.3196L12.6253 8.78457L14.0543 7.40207L14.2171 7.24457L14.0543 7.08707L13.4212 6.47457Z" fill="#D8E0F2" />
                            <path d="M97.6925 10.3604C98.5017 10.3604 99.1576 9.72575 99.1576 8.94289C99.1576 8.16003 98.5017 7.52539 97.6925 7.52539C96.8834 7.52539 96.2274 8.16003 96.2274 8.94289C96.2274 9.72575 96.8834 10.3604 97.6925 10.3604Z" fill="#D8E0F2" />
                            <path d="M39.9742 10.2204V12.6879H45.1654V15.2779H39.9742V17.8854H45.7985V20.5629H36.8992V7.52539H45.7985V10.2204H39.9742Z" fill="#D8E0F2" />
                            <path d="M58.6589 11.3931V20.5631H55.8372V19.5131C55.5116 19.9156 55.0594 20.2306 54.4987 20.4756C53.9199 20.7206 53.3049 20.8256 52.6356 20.8256C51.7493 20.8256 50.9715 20.6331 50.2842 20.2306C49.5969 19.8281 49.0723 19.2681 48.7106 18.5506C48.3488 17.8331 48.1498 16.9931 48.1498 16.0481C48.1498 15.0506 48.3488 14.1931 48.7467 13.4406C49.1447 12.6881 49.7054 12.1106 50.4289 11.6906C51.1524 11.2706 51.9664 11.0781 52.8889 11.0781C54.2455 11.0781 55.2945 11.4806 56 12.2856L56.1989 11.3931H58.6589ZM55.0594 17.6406C55.4935 17.2206 55.6925 16.6431 55.6925 15.9431C55.6925 15.2606 55.4754 14.7006 55.0594 14.2806C54.6253 13.8606 54.0827 13.6506 53.3953 13.6506C52.708 13.6506 52.1653 13.8606 51.7493 14.2981C51.3333 14.7181 51.1343 15.2781 51.1343 15.9781C51.1343 16.6606 51.3333 17.2206 51.7493 17.6581C52.1653 18.0956 52.708 18.3056 53.3772 18.3056C54.0827 18.2706 54.6434 18.0606 55.0594 17.6406Z" fill="#D8E0F2" />
                            <path d="M62.0594 20.0206C61.2997 19.4781 60.8656 18.7256 60.7933 17.7631H63.6873C63.7054 18.0781 63.832 18.3231 64.0853 18.4806C64.3385 18.6556 64.646 18.7431 65.0258 18.7431C65.3695 18.7431 65.6227 18.6906 65.8217 18.5681C66.0207 18.4456 66.1111 18.2706 66.1111 18.0256C66.1111 17.8156 66.0387 17.6406 65.876 17.5356C65.7132 17.4306 65.5142 17.3606 65.2429 17.3081L63.7235 16.9406C61.9147 16.5206 61.0103 15.6106 61.0103 14.1756C61.0103 13.2481 61.3721 12.5131 62.0956 11.9356C62.8191 11.3581 63.7778 11.0781 64.9535 11.0781C66.1111 11.0781 67.0517 11.3581 67.7752 11.9181C68.4987 12.4781 68.8785 13.2131 68.8966 14.1056H66.0388C66.0207 13.8256 65.9121 13.6156 65.6951 13.4581C65.478 13.3006 65.2067 13.2131 64.8811 13.2131C64.5917 13.2131 64.3566 13.2656 64.1938 13.3881C64.031 13.5106 63.9406 13.6681 63.9406 13.8956C63.9406 14.1056 64.0129 14.2631 64.1576 14.3506C64.3023 14.4381 64.5375 14.5081 64.863 14.5956L66.6357 14.9981C67.4858 15.1906 68.1189 15.5231 68.5168 16.0131C68.9147 16.4856 69.1137 17.0631 69.1137 17.7281C69.1137 18.6731 68.7338 19.4256 67.9922 20.0031C67.2506 20.5631 66.2377 20.8606 64.9716 20.8606C63.7959 20.8431 62.8191 20.5631 62.0594 20.0206Z" fill="#D8E0F2" />
                            <path d="M81.323 11.3936L76.9277 22.0511C76.6925 22.6111 76.4212 23.0836 76.1499 23.5036C75.8605 23.9061 75.4445 24.2736 74.8838 24.5886C74.323 24.9036 73.6176 25.0611 72.7675 25.0611C72.1887 25.0611 71.5375 24.9911 70.8321 24.8336L70.8682 22.5236C71.3928 22.6111 71.845 22.6636 72.2429 22.6636C72.7132 22.6636 73.0931 22.5936 73.3825 22.4711C73.6538 22.3486 73.8708 22.1911 73.9975 21.9986C74.1241 21.8061 74.2507 21.5611 74.3592 21.2636L74.5401 20.8611L70.4884 11.3936H73.6538L75.8786 17.3961L78.23 11.3936H81.323Z" fill="#D8E0F2" />
                            <path d="M93.3876 15.2079C93.8217 15.7154 94.0207 16.3454 94.0207 17.1329C94.0207 18.2529 93.5865 19.0929 92.7364 19.6879C91.8863 20.2829 90.7106 20.5629 89.2274 20.5629H83.5297V7.52539H89.2455C90.6925 7.52539 91.8139 7.85789 92.646 8.50539C93.4599 9.17039 93.8759 10.0279 93.8759 11.1304C93.8759 11.9004 93.677 12.5129 93.279 13.0204C92.8811 13.5104 92.3385 13.8779 91.6692 14.0879C92.3927 14.3329 92.9535 14.7004 93.3876 15.2079ZM86.5685 10.0454V13.0204H89.1189C89.6796 13.0204 90.1137 12.8629 90.4031 12.5654C90.6925 12.2679 90.8372 11.9354 90.8372 11.5504C90.8372 11.1654 90.6925 10.8329 90.4031 10.5179C90.1137 10.2029 89.6796 10.0454 89.1189 10.0454H86.5685ZM90.5297 17.6754C90.8372 17.4129 91 17.0804 91 16.6429C91 16.2229 90.8372 15.8729 90.5116 15.5929C90.186 15.3129 89.77 15.1554 89.2816 15.1554H86.5685V18.0604H89.2816C89.7881 18.0779 90.2222 17.9379 90.5297 17.6754Z" fill="#D8E0F2" />
                            <path d="M99.1576 11.3936H96.2274V20.5636H99.1576V11.3936Z" fill="#D8E0F2" />
                            <path d="M112.018 20.5629H109.576L109.413 19.4604C109.088 19.8979 108.654 20.2304 108.075 20.4754C107.514 20.7204 106.881 20.8254 106.194 20.8254C105.289 20.8254 104.475 20.6154 103.77 20.2129C103.065 19.8104 102.504 19.2329 102.106 18.4979C101.708 17.7629 101.509 16.9229 101.509 15.9429C101.509 14.9804 101.708 14.1229 102.106 13.3879C102.504 12.6354 103.047 12.0579 103.77 11.6554C104.475 11.2529 105.289 11.0429 106.194 11.0429C106.827 11.0429 107.388 11.1304 107.876 11.2879C108.364 11.4454 108.78 11.7079 109.106 12.0229V7.52539H112.018V20.5629ZM108.437 17.6404C108.871 17.2204 109.07 16.6429 109.07 15.9429C109.07 15.2604 108.853 14.7004 108.437 14.2804C108.003 13.8604 107.46 13.6504 106.773 13.6504C106.085 13.6504 105.543 13.8604 105.127 14.2979C104.711 14.7179 104.512 15.2779 104.512 15.9779C104.512 16.6604 104.711 17.2204 105.127 17.6579C105.543 18.0954 106.085 18.3054 106.755 18.3054C107.442 18.2704 108.003 18.0604 108.437 17.6404Z" fill="#D8E0F2" />
                            <path d="M126.705 15.5754V17.9729H124.987V20.5629H122.075V17.9729H115.961V15.8204L121.351 7.52539H124.969V15.5754H126.705ZM122.075 15.5754V11.0604L119.145 15.5754H122.075Z" fill="#D8E0F2" />
                            <path d="M128.134 7.52539H131.209V15.5754C131.227 16.4504 131.481 17.0979 131.987 17.5179C132.494 17.9379 133.163 18.1654 133.995 18.1654C134.899 18.1654 135.605 17.9379 136.093 17.4829C136.581 17.0279 136.835 16.3979 136.835 15.5754V7.52539H139.91V15.7154C139.91 17.3429 139.367 18.6029 138.3 19.4954C137.233 20.3879 135.786 20.8429 133.995 20.8429C132.186 20.8429 130.757 20.3879 129.708 19.4604C128.659 18.5504 128.134 17.2904 128.134 15.7154V7.52539Z" fill="#D8E0F2" />
                        </svg>

                    </div>
                    <ul className={styles.item2}>
                        <li>
                            <Link to="/">
                                Pagina inicial
                            </Link>
                        </li>
                        <li>
                            <Link to="/minha-conta">
                                Minha conta
                            </Link>
                        </li>
                        <li>
                            <Link to="/leilao/status">
                                Meus leilões
                            </Link>
                        </li>
                        <li>
                            <Link to="/favoritos">
                                Favoritos
                            </Link>
                        </li>
                    </ul>


                    <ul className={styles.item3}>
                        <li>
                            <Link to="/easycoins">
                                Comprar
                            </Link>
                        </li>
                        <li>
                            <Link to="/leilao/status">
                                Meus arremates
                            </Link>
                        </li>
                        <li>
                            <Link to="/minha-conta/pagamentos">
                                Pagamentos
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                Retirada
                            </Link>
                        </li>
                    </ul>


                    <ul className={styles.item4}>
                        <li>
                            <Link to="/informa">
                                Como funciona
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                Termos de serviço
                            </Link>
                        </li>
                        <li>
                            <Link to="/informa">
                                Central de ajuda
                            </Link>
                        </li>
                    </ul>

                    <div className={`${styles.social} ${styles.item5}`}>
                        <span>Acompanhe:</span>
                        <div>
                            <InstaIco />

                            <TwitterIco />

                            <FacebookIco />

                            <YoutubeIco />
                        </div>
                    </div>

                    <div className={`${styles.attendance} ${styles.item6}`}>
                        <span>Atendimento:</span>
                        <button>
                            <WhatsAppIco color="#E1E6F4" size={20} />
                            Via Whatsapp
                        </button>
                        <button>
                            <EmailIco color="#E1E6F4" size={20} />
                            Via  e-mail
                        </button>
                        <span>
                            segunda a domingo: 8h às 20h
                            (exceto feriados)
                        </span>
                    </div>
                    <div className={styles.item7}>
                        <span> Copyright © 2021 site.com.br LTDA</span> <br />
                        <span> CNPJ n.º 00.000.000/0000-00 / Av. Lorem ipsum dolor sit amet, nº 000, Austin, Nova Iguaçu/RJ - CEP 00000-000 - empresa do grupo 4U.</span>
                    </div>
                </div>
            </div>



        </footer>
    )
}