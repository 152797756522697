import api from "./api";
import {CartRequest, CartVO, CheckoutRequest, PaymentOptions} from "../modelos/Cart";
import StorageUtils from "../utils/StorageUtils";
import {getToken, isAuthenticated} from "./AuthService";
import CartUtils from "../utils/CartUtils";
import history from "./history";

/**
 * Email sending and validation to siding page api
 *
 * @param cartRequest
 * @returns
 */
export const createCart = async (cartRequest: CartRequest) : Promise<CartVO | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            let userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            let response = await api.post('/p/cart', cartRequest,
                {
                    headers: {
                        "Authorization": `Bearer ${userToken}`,
                        "Content-Type": 'application/json',
                    }
                }
            )
            return response.data;
        }
        
    } catch (error) {
        throw new Error(error)
    }
}

/**
 * Email sending and validation to siding page api
 *
 * @param cartId
 * @returns
 */
export const findCartByID = async (cartId : string) : Promise<CartVO | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            const userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            const response = await api.get(`/p/cart/${cartId}`,
                { headers: { "Authorization": `Bearer ${userToken}` } }
            )

            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}

/**
 * Email sending and validation to siding page api
 *
 * @param cartId
 * @param cartRequest
 * @returns
 */
export const updateCartByID = async (cartId : string, cartRequest: CartRequest) : Promise<CartVO | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            const userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            const response = await api.put(`/p/cart/${cartId}`, cartRequest,
                {
                    headers: {
                        "Authorization": `Bearer ${userToken}`,
                        "Content-Type": 'application/json',
                    }
                }
            )

            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}

export const findPaymentOptions = async (cartId : string) : Promise<PaymentOptions | undefined> => {
    try {
        if(isAuthenticated(true, true)) {
            const userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
            const response = await api.get(`/p/cart/${cartId}/payment-options`,
                { headers: { "Authorization": `Bearer ${userToken}` } }
            )
            
            return response.data
        }
    } catch (error) {
        throw new Error(error)
    }
}

/**
 * Checkout
 *
 * @param checkoutRequest
 * @returns
 */
export const doCheckout = async (checkoutRequest: CheckoutRequest) => {
    //TODO - Retornar OrderVO
    try {
        if(isAuthenticated(true, true)) {
            let userToken = getToken()
            let response = await api.post('/p/checkout', checkoutRequest,
                {
                    headers: {
                        "Authorization": `Bearer ${userToken}`,
                        "Content-Type": 'application/json',
                    }
                }
            )
            return response.data;
        }
        
    } catch (error) {
        throw new Error(error)
    }
}