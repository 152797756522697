import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_HOST + "/api/p/customer/card";

export interface PaymentForm {
    _id?: string,
    brand?: string,
    ownerName?: string,
    number?: string,
    maskedNumber?: string,
    expirationMonth?: string,
    expirationYear?: string,
    type?: string,
    cvv?: string,
    savedCard?: boolean,
    saveCard?: boolean,
    installments?: number,
}

export interface Card { 
  id: string;
  brand: string;
  number?: string;
  cvv?: string;
  code: string;
  maskedNumber: string;
  dateCreated: string;
  deleted: boolean;
  validity?: string;
  expirationMonth: string;
  expirationYear: string;
  main: boolean;
  ownerName: string;
  saveCard: false;
  type: string;

}

export interface ResponseResult{
  response: string,
  result: boolean,
  noAuth?: boolean,
}
export type ResponseResultGetCard = Omit<ResponseResult, 'response'> & {
  response: Card | string;
}
export type ResponseResultGetCards = Omit<ResponseResult, 'response'> & {
  response: Card[];
}

export type RequestCreateCard = {
  brand: string;
  ownerName: string;
  number: string;
  expirationMonth: string;
  expirationYear: string;
  type: string;
  cvv: string;
}
// DEVE ESTAR  AUTENTICADO
export const createCard = async (request: RequestCreateCard) : Promise<ResponseResultGetCard> => {
  try{
    const userToken = localStorage.getItem('userToken');
    if(!userToken) return {
      result: false,
      response: 'Faça o login',
      noAuth: true
    };

    let response: ResponseResult = {
      response: 'Houve um erro ao realizar a requisição',
      result: false
    };

    const { data } = await axios.post(`${baseURL}`,request,{
      headers: { Authorization: `Bearer ${userToken}` }
    });
    
    response = {
      result: true,
      response: data
    };

    return response;
  }
  catch(err: any) {
    let message = 'Houve um erro ao realizar a requisição';
    
    if(err &&
       err.response &&
       err.response.data &&
       err.response.data.message
    ) message= err.response.data.message;

    if(
      err &&
      err.response &&
      err.response.data &&
      err.response.data.validationErrors
    ){
      let arrMessages = err.response.data.validationErrors.map(
        (val : { message: string }) => {return val.message}
      );
      let messages = arrMessages.join('. ');
      messages = messages.replaceAll('paymentCard.brand', 'bandeira');
      messages = messages.replaceAll('paymentCard.ownerName', 'nome no cartão');
      messages = messages.replaceAll('paymentCard.number', 'número do cartão');
      messages = messages.replaceAll('paymentCard.expirationMonth', 'data de venc.');
      messages = messages.replaceAll('paymentCard.expirationYear', 'data de venc.');
      messages = messages.replaceAll('paymentCard.type', 'tipo');
      messages = messages.replaceAll('paymentCard.cvv', 'cvv');

      message = messages;
    }
    

    return {
      response: message,
      result: false
    };
  }

}

export const getCards = async () : Promise<ResponseResultGetCards | ResponseResult> => {
  try{
    const userToken = localStorage.getItem('userToken');
    if(!userToken) return {
      result: false,
      response: 'Faça o login',
      noAuth: true
    };

    const { data } = await axios.get(`${baseURL}`,{
      headers: { Authorization: `Bearer ${userToken}` }
    });

    return {
      result: true,
      response: data
    };
  }
  catch(err: any){
    let message = 'Houve um erro ao realizar a requisição';

    if(err &&
      err.response &&
      err.response.data &&
      err.response.data.message
    ) message= err.response.data.message;

    return {
      response: message,
      result: false
    };
  }
}

export const deleteCard = async (card_id: string) : Promise<ResponseResultGetCard> => {
  try{
    const userToken = localStorage.getItem('userToken');
    if(!userToken) return {
      result: false,
      response: 'Faça o login',
      noAuth: true
    };

    let response: ResponseResult = {
      response: 'Houve um erro ao realizar a requisição',
      result: false
    };
    
    const { data } = await axios.delete(`${baseURL}/${card_id}`,{
      headers: { Authorization: `Bearer ${userToken}` }
    });
    
    response = {
      result: true,
      response: data
    };

    return response;
  }
  catch(err: any) {
    let message = 'Houve um erro ao realizar a requisição';

    if(err &&
      err.response &&
      err.response.data &&
      err.response.data.message
    ) message= err.response.data.message;

    return {
      response: message,
      result: false
    };
  }
}

export const identifyCardBrand = (nr: string) => {
  nr = removePunctuation(nr);
  const cards = {
    master: /^(5[1-5]\d{4}|677189)\d{10}$/,
    diners: /^3(0[0-5]|[68]\d)\d{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
    amex: /^3[47]\d{13}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}|\d{14}$/,
    aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
    maestro: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
    visa: /^4\d{12}(\d{3})?$/,
  } as any;

  for (var card in cards) if (nr.match(cards[card])) return card;
  return null;
}

export const removePunctuation = (temp: string) => {
  while(temp.indexOf('.') !== -1 || temp.indexOf('-') !== -1 || 
        temp.indexOf('/') !== -1 || temp.indexOf(' ') !== -1 ||
        temp.indexOf('(') !== -1 || temp.indexOf(')') !== -1){
      temp = temp.replace('.','');
      temp = temp.replace('-','');
      temp = temp.replace('/','');
      temp = temp.replace(' ','');
      temp = temp.replace('(','');
      temp = temp.replace(')','');
  }
  return temp;
}

export function cardMask(v: string){
  v=v.replace(/\D/g,"");
  v=v.replace(/^(\d{4})(\d)/g,"$1 $2");
  v=v.replace(/^(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3");
  v=v.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3 $4");
  return v;
}
export function dateMask(v: string){
  v=v.replaceAll('/','');
  v=v.replace(/[^0-9]/g,'');
  if(v.length > 2) v = v.substr(0,2) + '/' + v.substr(2);
  return v;
}
export function ccvMask(v: string){
  return v.replace(/[^0-9]/g,'');
}