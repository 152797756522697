import styled, { css } from 'styled-components'
import { Button, Col } from 'reactstrap'
import { Title } from '../../components/common/styles/styles'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
`
export const CustomizedTitle = styled(Title)`
    margin: 24px 0px 0px 15px;
    @media(min-width: 700px){
        margin: 0px;
    }
`
export const Separator = styled.img`
    display: none;
    @media(min-width: 700px){
        display:flex;
        margin: 0px 36px 0px 35px;
    }
`
export const Description = styled.div`
    margin: 11px 16px 0px 15px;
    font-size: 14px;
    line-height: 16px;
    color: #828BA2;
    @media(min-width: 700px){
        margin: 11px 16px 0px 0px;
    }
`
export const Subtitle = styled.h2`
    margin: 24px 0px 0px 15px;
    font-size: 14px;
    line-height: 16px;
    color: #828BA2;
    @media(min-width: 700px){
        margin: 0px;
    }
`

export const ArticleContainer = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    border-bottom: 1px solid var(--color-select);
    padding: 10px 0;

    ${({ isFirstItem }) => {
        if (isFirstItem) {
            return css`
            border-top: 1px solid var(--color-select);
            margin:  16px 15px 0px 15px;
            @media(min-width: 700px){
                margin: 16px 15px 0px 0px;
            }
            `
        } else {
            return css`
            margin:  0px 15px 0px 15px;
            @media(min-width: 700px){
                margin: 0px 15px 0px 0px;
            }
            `
        }
    }}
`
export const ArticleBox = styled.div`
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    width: 250px;
`

export const ArticleTitle = styled.h3`
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text-medium);
`
export const CustomizedButton = styled(Button)`
    width: 90%;
    height: 53px;
    margin: 24px 15px 0px 15px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 33px;    
    @media(min-width: 700px){
        margin: 24px 15px 0px 0px;
    }
`