import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss'
type Props = {
    name: string,
    route: string,
}
type breadsType = {
    content: Props[]
}

export function BreadCrumbs({ content }: breadsType) {

    return (

        <nav className={style.containerBread}>
            <ul>
                {content.map((bread, index) => {
                    return (
                        <li key={"li-"+index}>
                            <Link key={"link-"+index} to={bread.route}>
                                {bread.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>

    )
}