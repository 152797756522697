import { useEffect, useState } from "react";

type props ={
    url: string
}

export const BoxVideo = ({url}:props) =>{
    const [src, setSrc] = useState('')
    useEffect(()=>{
        handleYoutubeEmbed(url)
    },[])
    function handleYoutubeEmbed(elem: string) {
        let url: string = elem;
        if (url.indexOf('/embed/') === -1) {
            url = url.replace('watch?v=', '');
            url = url.replace('youtube.com/', 'youtube.com/embed/');
            url = url.replace('youtu.be/', 'youtube.com/embed/');

            let time = url.indexOf('&t=');
            if (time !== -1) url = url.substr(0, time);
        }
        setSrc(url)
    }
    return(
        <iframe
        src= {src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    >

    </iframe>
    )
}