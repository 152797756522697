import {FileType} from "./FileType";

export interface Image {
    title: string;
    type: FileType;
    url: string;
    key: string;
    order: number;
}

export const getInitialImage = (): Image => {
    const initialImage: Image = {
        title: '',
        type: FileType.IMAGE,
        url: '',
        key: '',
        order: 0
    }

    return initialImage
}