import styled from 'styled-components'
import { Input, Col } from 'reactstrap';

export const DefaultInput = styled(Input)`
    height: 40px;
    margin-bottom: 16px;
    background: var(--color-primary-light);
    color: var(--color-text-light);
    font-size: 20px;
    border: none;
    &:focus{
        outline: none;
        background: var(--color-primary-dark);
        color: var(--color-text-light);
    }
`
export const DefaultCheckBox = styled(Input)`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: var(--color-primary-light);
    color: var(--color-text-light);
    border: none;
    
    &:focus{
        outline: none;
        background: var(--color-primary-dark);
        color: var(--color-text-light);
    }
`
export const InputBox = styled(Col)`
    display: flex;
    flex-direction: ${props => props.isrowreverse ? 'row-reverse' : 'row'};
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
    padding-left: 27px;
    height: 40px;
    border-radius: 8px;
    background: var(--color-select);
    color: var(--color-text-light);
    margin-bottom: 25px;
    width: ${props => props.size ? props.size : '100%'};
`
export const Icon = styled.img`
    margin-right: 22px;
`