import React, {useContext, useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import style from './style.module.scss'
import {Context} from "../../../contexts/ContextProvider";
import CartUtils from "../../../utils/CartUtils";
import {doCheckout, findCartByID} from "../../../services/CartService";
import {CartVO, CheckoutRequest, PaymentOption} from "../../../modelos/Cart";
import StorageUtils from "../../../utils/StorageUtils";
import AuthUtils from "../../../utils/AuthUtils";

export function PaymentConfirmation() {
    const [contentCart, setContentCart] = useState<CartVO>();
    const [modal, setModal] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [paymentOption, setPaymentOption] = useState<PaymentOption>({});
    const Router = useHistory();
    const context = useContext(Context)

    useEffect(() => {
        if(context.paymentOption) {
            setPaymentOption(context.paymentOption)
            getCartByID()
        } else {
            Router.push("/formas-de-pagamento")
        }
    }, [paymentOption])

    const getCartByID = async () => {
        if(CartUtils.getLocalCartId()) {
            try {
                const cart = await findCartByID(CartUtils.getLocalCartId())
                if(cart) {
                    setContentCart(cart)
                } else {
                    console.log("Carrinho não encontrado")
                    CartUtils.clearCartLocal()
                    Router.push("/carrinho")
                }
                
            } catch (error) {
                console.log("Houve um erro ao buscar carrinho pelo ID", error)
                Router.push("/carrinho")
            }
        } else {
            console.log("Sem ID do carrinho para efetuar busca")
            CartUtils.clearCartLocal()
            Router.push("/carrinho")
        }
    }

    const handleCheckout = async () => {
        if(contentCart && paymentOption) {
            //TODO - Adicionar loading de página
            try {
                let checkoutRequest : CheckoutRequest = {
                    cartId: CartUtils.getLocalCartId(),
                    customerId: AuthUtils.getCustomerID(),
                    fingerPrint: contentCart.fingerPrint,
                    payment: paymentOption
                }
    
                if(contentCart.coupon) {
                    checkoutRequest.couponCode = contentCart.coupon.code
                }
    
                const order = await doCheckout(checkoutRequest)
                
                if(order) {
                    console.log("Pedido criado - order: ", order)
                    setOrderId(order.orderId)
                    CartUtils.clearCartLocal()
                    context.clearAfterCheckout()
                    //TODO - Abrir modal informando sucesso da compra e os detalhes do pedido
                    setModal(true)
                    /*context.setHasCartItems(false)
                    context.setPaymentOption({})
                    context.setPaymentForm({})*/
                }
                
            } catch (e) {
                console.log("Houve um erro ao criar pedido: ", e)
                //TODO - Abrir modal informando que deu erro e solicitar nova tentativa
            }
        } else {
            console.log("Impossível criar fazer checkout sem contentCart ou paymentOption")
            Router.push("/carrinho")
        }
    }
    
    return (
            <div className="container">
                <div className={style.main}>
                    <div className={style.orderSummary}>

                        <h1 className={style.title}>Confirmar compra</h1>
                        <table>
                            <tr>
                                <th>Forma de pagamento: </th>
                                <td>
                                    <span>{paymentOption.paymentCard?.number ? paymentOption.paymentCard.number : paymentOption.paymentCard?.maskedNumber}</span>
                                    <span> em {paymentOption.choosenInstallment === 1 ? '1 vez' : paymentOption.choosenInstallment + ' vezes'}</span>
                                </td>
                            </tr>
                        </table>
                        <table>
                            {contentCart && contentCart.items.map((content, index) => {
                                return (
                                    <tr key={`item-${index}`}>
                                        <th>{content.pack.name} {content.pack.shortDescription}</th>
                                        <td>{content.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                )
                            })}
                        </table>
                        <br />

                        <table style={{ border: 'none', marginBottom: '3rem' }}>
                            <tr>
                                <th>Sub-total : ({contentCart?.items.length}{contentCart && contentCart.items.length > 1 ? 'produtos' : 'prosuto'}) </th>
                                <td>{contentCart?.subTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                            {contentCart?.feesTotal &&
                            (<tr>
                                <th>Taxa de serviço</th>
                                <td>{contentCart?.feesTotal.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</td>
                            </tr>)
                            }
                            {contentCart?.itemDiscount ?
                                (<tr>
                                    <th>Total de desconto:</th>
                                    <td>{contentCart?.itemDiscount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>) : ''
                            }
                            <tr style={{ fontSize: '1.2rem' }}>
                                <th>Total:</th>
                                <td>{contentCart?.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>

                        </table>

                        <button onClick={handleCheckout} className={style.action}>Efetuar pagamento</button>
                        <Link to="/easycoins">Continuar comprando</Link>


                    </div>

                </div>
                {modal &&
                <div className={style.modal}>
                    <div className={style.modalContent}>
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="40" cy="40" r="37" stroke="#00C1A5" strokeWidth="5" />
                            <path d="M53.3333 33L35.9167 50.4167L28 42.5" stroke="#00C1A5" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <strong>
                            Parabéns. <br />
                            Compra #{orderId} realizada com sucesso.
                        </strong>
                        <span>
                            Um email foi enviado para você com todas
                            as informações da sua compra.
                            <br />
                            Ou se preferir, pode consulta-las aqui.
                        </span>
                        <Link to="/">
                        Voltar para os leilões
                        </Link >
                    </div>
                </div>
                }

            </div>

    )
}