import styled, { css } from 'styled-components'
import { Col } from 'reactstrap';

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 24px;
    margin-left: 15px;
`
export const StatusBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 23px;
`
export const StatusText = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 12px;
    color: var(--color-text-medium);
    ${({ color }) => {
        if (color === 'up') {
            return css`
            border-bottom: 4px solid #00C1A5;
            `
        }
    }}
`
export const AuctionBox = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    border-bottom: 1px solid var(--color-select);
    padding: 10px 0;

    ${({ isFirstItem }) => {
        if (isFirstItem) {
            return css`
            border-top: 1px solid var(--color-select);
            margin:  16px 15px 0px 15px;
            `
        } else {
            return css`
            margin:  0px 15px 0px 15px;
            `
        }
    }}
`
export const AuctionText = styled.span`
    display: inline-block;
    width: 182px;
    height: 30px;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text-medium);
`
export const AuctionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin:  32px 15px 0px 15px;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-text-medium);
`
export const AuctionHeaderBox = styled.div`
    width: 50%;
`