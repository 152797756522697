import Home from './pages/home/Home'
// import BidProduct from './pages/bid-product/BidProduct'
import PrivatePage from './pages/PrivatePage'
import Login from './pages/login/Login'
import BuyCoins from './pages/buy-coins/BuyCoins'
import Register from './pages/registers'
import PrivateRoute from './hoc/private-route/PrivateRoute'
import Route from './hoc/route/Route'
import AuctionStatus from './pages/auction-status/AuctionStatus'
import Favourite from './pages/favourite/Favourite'
import HelpCenter from './pages/help/HelpCenter'
import ForgetPassword from './pages/forget-password/ForgetPassword'
import ResetPassword from './pages/forget-password/ResetPassword'
import InfoPage from './pages/info-page/InfoPage'
import Siding from './pages/siding'
import MyAccount from './pages/minha-conta';
import Payments from './pages/minha-conta/pagamentos';
import { Cart } from './pages/checkout/cart';
import { Payment } from './pages/checkout/payment-options/old';
import { CreditMethod } from './pages/checkout/payment-options'
import { PaymentConfirmation } from './pages/checkout/paymentConfirmation'
import MyCadastre from './pages/minha-conta/meu-cadastro'
import HowItWorks from './pages/minha-conta/como-funciona'
import MyFinishes from './pages/minha-conta/meu-arremates'
import Withdrawal from './pages/minha-conta/retirada'
import MyOrders from "./pages/minha-conta/minhas-compras";
import MyOrder from "./pages/minha-conta/meu-pedido";
import BidProduct from "./pages/bid-product/BidProduct";
import PublicRoute from "./hoc/public-route/PublicRoute";

const getRouteType = (isPrivate: boolean) => {
    return isPrivate ? PrivateRoute : PublicRoute
}

const routes = [
    {
        path: '/login',
        exact: true,
        routeType: getRouteType(false),
        component: Login
    },
    {
        path: '/',
        exact: true,
        routeType: getRouteType(false),
        component: Home,
    },

    {
        path: '/easycoins',
        exact: true,
        routeType: getRouteType(false),
        component: BuyCoins
    },
    {
        path: '/cadastro',
        exact: true,
        routeType: getRouteType(false),
        component: Register
    },
    {
        path: '/private_page',
        exact: true,
        routeType: getRouteType(true),
        component: PrivatePage
    },
    {
        path: '/leilao/status',
        exact: true,
        routeType: getRouteType(true),
        component: AuctionStatus
    },
    {
        path: '/leilao/:id',
        exact: true,
        routeType: getRouteType(false),
        component: BidProduct
    },
    {
        path: '/favoritos',
        exact: true,
        routeType: getRouteType(true),
        component: Favourite
    },
    {
        path: '/informa',
        exact: true,
        routeType: getRouteType(true),
        component: HelpCenter
    },
    {
        path: '/esqueceu-senha',
        exact: true,
        routeType: getRouteType(false),
        component: ForgetPassword
    },
    {
        path: '/trocar-senha',
        exact: true,
        routeType: getRouteType(false),
        component: ResetPassword
    },
    {
        path: '/saiba-mais',
        exact: true,
        routeType: getRouteType(false),
        component: InfoPage
    },
    {
        path: '/minha-conta',
        exact: true,
        routeType: getRouteType(true),
        component: MyAccount,
    },
    {
        path: '/minha-conta/pagamentos',
        exact: true,
        routeType: getRouteType(true),
        component: Payments,
    },
    {
        path: '/minha-conta/meu-cadastro',
        exact: true,
        routeType: getRouteType(true),
        component: MyCadastre,
    },
    {
        path: '/minha-conta/como-funciona',
        exact: true,
        routeType: getRouteType(true),
        component: HowItWorks,
    },
    {
        path: '/minha-conta/meus-arremates',
        exact: true,
        routeType: getRouteType(true),
        component: MyFinishes,
    },
    {
        path: '/minha-conta/minhas-compras',
        exact: true,
        routeType: getRouteType(true),
        component: MyOrders,
    },
    {
        path: '/minha-conta/minhas-compras/:orderId',
        exact: true,
        routeType: getRouteType(true),
        component: MyOrder,
    },
    {
        path: '/minha-conta/retirada',
        exact: true,
        routeType: getRouteType(true),
        component: Withdrawal,
    },
    /*{
        path: '/',
        exact: true,
        routeType: getRouteType(false),
        component: Siding
    },*/
    
    {
        path: '/carrinho',
        exact: true,
        routeType: getRouteType(false),
        component: Cart
    },
    {
        path: '/pagamento',
        exact: true,
        routeType: getRouteType(false),
        component: Payment
    },
    {
        path: '/formas-de-pagamento',
        exact: true,
        routeType: getRouteType(false),
        component: CreditMethod
    },
    {
        path: '/confirmacao-pagamento',
        exact: true,
        routeType: getRouteType(false),
        component: PaymentConfirmation
    },
    
]

export default routes