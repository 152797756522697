import React from 'react'
import {
    Box,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import {Table} from "reactstrap";
import {Order} from "../../../modelos/Order";
import styles from '../styles.module.scss';

interface OrderProps {
    order: Order
}

const OrderItemResume: React.FC<OrderProps> = ({order}) => (
    <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 3 }} elevation={3}>
            <Toolbar sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}} >
                <Typography
                    sx={{ flex: '1 1 100%', textAlign: "center" }}
                    variant="h4"
                    id="tableTitle"
                    component="div"
                >
                    {'Resumo dos items'}
                </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table aria-label="simple table " aria-labelledby="tableTitle" size={'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.orderItemResumeTableHeader} component="th" align="center">
                                Item
                            </TableCell>
                            <TableCell className={styles.orderItemResumeTableHeader} component="th" align="center">
                                Valor
                            </TableCell>
                            <TableCell className={styles.orderItemResumeTableHeader} component="th" align="center">
                                Quantidade
                            </TableCell>
                            {/*<TableCell className={styles.orderItemResumeTableHeader} component="th" align="center">
                                Easycoins
                            </TableCell>*/}
                            <TableCell className={styles.orderItemResumeTableHeader} component="th" align="center">
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {order && (
                        <TableBody>
                            {order.items?.map((item) => (
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}} >
                                    <TableCell className={styles.orderItemResumeTableBody} align="left">
                                        <span className={styles.first}>
                                            {item.pack.name} {item.pack.shortDescription}
                                        </span>
                                    </TableCell>
                                    <TableCell className={styles.orderItemResumeTableBody} align="center">
                                        {item.pack.costPrice.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </TableCell>
                                    <TableCell className={styles.orderItemResumeTableBody} align="center">
                                        {item.quantity}
                                    </TableCell>
                                    {/*<TableCell className={styles.orderItemResumeTableBody} align="center">
                                        {item.earnedCoins}
                                    </TableCell>*/}
                                    <TableCell className={styles.orderItemResumeTableBody} align="right">
                                        <span className={styles.last}>
                                            {item.subtotal.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell colSpan={3} className={styles.orderItemResumeTableBody} align="right">
                                    <strong>Total de Easycoins</strong>
                                </TableCell>
                                <TableCell colSpan={1} className={styles.orderItemResumeTableBody} align="right">
                                    <strong className={styles.last}>
                                        {order.earnedCoins}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={3} className={styles.orderItemResumeTableBody} align="right">
                                    <strong>Subtotal</strong>
                                </TableCell>
                                <TableCell colSpan={1} className={styles.orderItemResumeTableBody} align="right">
                                    <strong className={styles.last}>
                                        {order.subTotal.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={3} className={styles.orderItemResumeTableBody} align="right">
                                    <strong>Taxas</strong>
                                </TableCell>
                                <TableCell colSpan={1} className={styles.orderItemResumeTableBody} align="right">
                                    <strong className={styles.last}>
                                        {order.feesTotal.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}} >
                                <TableCell colSpan={3} className={styles.orderItemResumeTableBody} align="right">
                                    <strong>Descontos</strong>
                                </TableCell>
                                <TableCell colSpan={1} className={styles.orderItemResumeTableBody} align="right">
                                    <strong className={styles.last}>
                                        {(order.freightDiscount + order.itemDiscount).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}} >
                                <TableCell colSpan={3} className={styles.orderItemResumeTableBody} align="right">
                                    <strong>Total</strong>
                                </TableCell>
                                <TableCell colSpan={1} className={styles.orderItemResumeTableBody} align="right">
                                    <strong className={styles.last}>
                                        {order.totalPrice.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </strong>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Paper>
    </Box>
)

export default OrderItemResume