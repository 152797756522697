import React from 'react'
import {
    Box,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import {Table} from "reactstrap";
import styles from '../styles.module.scss';
import {PaymentFormVO} from "../../../modelos/Payment";

interface OrderPaymentProps {
    paymentForm: PaymentFormVO
}

const OrderPaymentResume: React.FC<OrderPaymentProps> = ({paymentForm}) => (
    <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 5 }} elevation={3}>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 }
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%', textAlign: "center" }}
                    variant="h4"
                    id="tableTitle"
                    component="div"
                >
                    {'Resumo do pagamento'}
                </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table aria-label="simple table " aria-labelledby="tableTitle" size={'medium'}>
                    <TableBody>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                <span className={styles.first}>
                                    <strong>Forma de pagamento</strong>
                                </span>
                            </TableCell>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                {paymentForm.type}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                <span className={styles.first}>
                                    <strong>Portador do Cartão</strong>
                                </span>
                            </TableCell>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                {paymentForm.cardHolderName}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                <span className={styles.first}>
                                    <strong>Número do cartão</strong>
                                </span>
                            </TableCell>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                {paymentForm.cardNumberMask}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                <span className={styles.first}>
                                    <strong>Parcelas</strong>
                                </span>
                            </TableCell>
                            <TableCell colSpan={2} className={styles.orderItemResumeTableBody} align="left">
                                {paymentForm.installment}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </Box>
)

export default OrderPaymentResume