import api from "./api";


export async function GetProductByID(id:string){
   try{
        const response = await api.get(`/o/product/${id}`, { headers: { "Content-Type": "application/json" } })
        return response.data
   }catch(error){
      return error
   }
}