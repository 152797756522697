import styled from 'styled-components'
import { Form } from 'reactstrap';
import { Link } from "react-router-dom";
import { Button } from '../../components/common/buttons/styles';
import { SubTitle } from '../../components/common/styles/styles';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 15rem);
    margin: 0 50px;

    @media(min-width: 700px){
        width: 400px;
        margin: 0 auto;
    }
`
export const CustomizedForm = styled(Form)`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;;
    align-items: center;
    margin-top:63px;
`
export const DeaultLink = styled(Link)`
    color: var(--color-text-medium);
    font-size: 14px;
    line-height: 16px;
    align-self: flex-end;
    margin-bottom: 54px;

    &:hover{
        color: var(--color-text-light);
    }
`
export const CustomizedButton = styled(Button)`
    width:100%;
    margin-bottom: 32px;
`

export const Text = styled.span`
    color: var(--color-text-medium);
    font-size: 14px;
    line-height: 16px;
`
export const TitleBox = styled.div`
    display: flex;
    flex-direction: column;    
    width: 100%;
    margin-top:60px;
    @media(min-width: 700px){
        align-items:center;
    }
`
export const CustomizedSubtitle = styled(SubTitle)`
    color: var(--color-text-medium);
    margin-top:16px;
`