import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

interface ModalRegisterProps{
  type: 'success' | 'error';
  onClose: () => void;
  content?: string;
}
export function ModalRegister({ type, onClose, content }: ModalRegisterProps){
  return(
    <div className={styles.overlay} onClick={event => {
      let e = event.target;
      // @ts-ignore
      if(e.classList.contains(styles.overlay)) onClose();
    }}>
      <div className={styles.container}>
        <div className={styles.content}>
          <button type="button" onClick={onClose} className={styles.closeModal}>  
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 3L3 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 3L21 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          {type === 'success' ? (<>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="37" stroke="#00C1A5" strokeWidth="5"/>
              <path d="M53.3333 33L35.9167 50.4167L28 42.5" stroke="#00C1A5" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <h3>Sua conta foi criada com sucesso</h3>
            <Link to="/" className={styles.btnFinish}>Começar</Link>
          </>):(<>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="37" stroke="#FD6151" strokeWidth="5"/>
              <path d="M52.75 27.25L27.25 52.75" stroke="#FD6151" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M27.25 27.25L52.75 52.75" stroke="#FD6151" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            { content ? (
              <div className={styles.content} dangerouslySetInnerHTML={{__html: content}} />
            ):(
              <h3>Ops! Não foi possível realizar o cadastro.</h3>
            )}
          </>)}
        </div>
      </div>
    </div>
  );
}