import React, {useEffect, useState} from 'react'
import NewsLetterCard from '../../components/news-letter-card/NewsLetterCard'
import style from './style.module.scss'

import {getKnowMoreInfo} from '../../services/InfoServices'

import {KnowMore} from '../../modelos/KnowMore'
import {ArrowRight, Calendar, CoinIco, Heart, HeartProduct, UserIcon, VideoIcon} from '../../components/Icons'
import {useHistory, useParams} from 'react-router-dom';
import {GetProductByID} from '../../services/getProductByID'
import {Media, ProductType} from '../../modelos/productType'
import {NewDate} from '../../hooks/formatDate'
import {BoxVideo} from '../../components/BoxVideo'
import {BreadCrumbs} from '../../components/BreadCrumbs'
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {formatDateTime} from "../../utils/utilities";
import {InformationLink} from "../../components/information/styles";
import {addFavorite, deleteFavorite, findFavorites} from "../../services/FavoriteService";
import {isAuthenticated} from "../../services/AuthService";
import {toast, ToastContainer} from 'react-toastify';
import Banners from "../../components/banners/Banners";
import {BannerType} from "../../modelos/Banner";

interface ProductParams {
    id: string
}

interface LastBid {
    nickname: string;
    dateCreated: string;
    value: number;
}

const BidProduct = () => {
    const {id} = useParams<ProductParams>()
    const btnTextInicial = 'Confirmar'
    const [currentBid, setCurrentBid] = useState('0')
    const [isBidOpen, setIsBidOpen] = useState(false)
    const [isFirstBid, setIsFirstBid] = useState(true)
    const [btnText, setBtnText] = useState(btnTextInicial)
    const [productImage, setProductImage] = useState(0)
    const [modalImage, setModalImage] = useState(false)
    const [product, setProduct] = useState<ProductType>()
    const [currentImg, setcurrentImg] = useState(0);
    // midias separadas
    const [images, setImages] = useState<Media[]>([]);
    const [videos, setVideos] = useState<Media[]>([]);
    const [infoList, setInfoList] = useState<KnowMore[]>()
    const [lastBids, setLastBids] = useState<LastBid[]>()
    const [isFavorited, setIsFavorited] = useState(false)
    const history = useHistory();
    
    useEffect(() => {
        requiringProducts(id)
    }, [id]);
    
    useEffect(() => {
        handlemedia()
    }, [product])
    
    useEffect(() => {
        getLastBids();
    }, []);
    
    useEffect(() => {
        getKnowMoreInfos();
    }, []);
    
    const {width} = useWindowDimensions();
    const isDesktopWindow = () => {
        return width > 700
    }
    
    const getKnowMoreInfos = async () => {
        try {
            const results: any = await getKnowMoreInfo()
            setInfoList(results)
        } catch (e) {
            console.error('Não foi possível exibir os cards de informações.')
        }
    }
    
    const getLastBids = async () => {
        const lastBids : LastBid[] = [
            {
                nickname: "Amarildo",
                value: product?.currentPrice ? product.currentPrice : 370,
                dateCreated: "2021-09-26T18:52:12.019",
            },{
                nickname: "João",
                value: product?.currentPrice ? (product.currentPrice - 10) : 360,
                dateCreated: "2021-09-25T13:39:45.019",
            },{
                nickname: "Tião",
                value: product?.currentPrice ? (product.currentPrice - 20) : 350,
                dateCreated: "2021-09-25T13:37:09.019",
            }
        ];
        setLastBids(lastBids)
    }
    
    const addBid = (num: number) => {
        const numberCurrentBid = parseFloat(currentBid)
        let userBid: number = numberCurrentBid + num
        const strUserBid: string = userBid.toFixed(2)
        setCurrentBid(strUserBid)
    }
    
    const toggle = () => {
        setIsBidOpen(!isBidOpen)
        setIsFirstBid(!isFirstBid)
    }
    
    const sendBid = () => {
        setBtnText('Fazer novo lance')
    }
    
    const onChange = (value: any) => {
        setProductImage(value);
    }
    
    // START: GET PRODUCT BY ID
    async function requiringProducts(id: string) {
        try {
            const product = await GetProductByID(id)
            if(isAuthenticated()) {
                const favorites = await findFavorites()
                
                if(favorites) {
                    const isFavorite = favorites.find((favorite: any) => (
                        favorite._id === product._id
                    ))
                    setIsFavorited(!!isFavorite)
                }
            }
            setProduct(product)
        } catch (error) {
            console.log(error)
        }
    }
    
    // END: GET PRODUCT BY ID
    function handlemedia() {
        if (product && product.images) {
            let img: Media[] = [];
            let video: Media[] = [];
            product.images.forEach(content => {
                if (content.type === 'IMAGE') {
                    img.push(content)
                } else {
                    video.push(content)
                }
            })
            setImages(img);
            setVideos(video);
        }
    }
    
    // START: carrosel
    function next() {
        if (product) {
            if (currentImg < product.images.length - 1) {
                setcurrentImg(currentImg + 1)
            }
        }
    }
    
    function prev() {
        if (currentImg > 0) {
            setcurrentImg(currentImg - 1)
        }
    }
    // END: carrosel
    
    // START: favorite
    async function handleFavorite() {
        try {
            if(isAuthenticated(true, false) && product) {
                if(!isFavorited) {
                    const result = await addFavorite(product._id)
                    setIsFavorited(true)
                    toast.success("Produto adicionado aos favoritos")
                } else {
                    const result = await deleteFavorite(product._id)
                    setIsFavorited(false)
                    toast.success("Produto removido dos favoritos")
                }
            } else {
                toast.warn("Você precisa fazer o login para favoritar")
            }
        } catch (error) {
            toast.error("Erro ao tentar atualizar seus favoritos")
            console.log(error)
        }
    }
    
    // END: favorit
    
    const crumbContent = [
        {
            name: 'Pagina inicial',
            route: '/'
        },
        {
            name: 'leilao',
            route: '/'
        }
    ]
    return (
        <div className="container">
            <ToastContainer/>
            <BreadCrumbs content={crumbContent}/>
            <div className={style.generalContainer}>
                <div className={style.containerproduct}>
                    <div className={style.listImages}>
                        {product && product.images.map((pImage, index) => {
                            return (
                                index < 3 ? (
                                    pImage.type == "VIDEO" ? (
                                        <button key={index} style={{backgroundColor: '#1A1B20'}}
                                                onClick={() => setcurrentImg(index)}>
                                            <VideoIcon/>
                                        </button>
                                    ) : (
                                        <button
                                            key={index}
                                            onClick={() => setcurrentImg(index)}
                                            style={{backgroundImage: `url(${pImage.url})`}}
                                        />
                                    )
                                ) : ""
                            )
                        })}
                        
                        {product && product.images && product.images.length > 3 ? (
                            <button
                                style={{backgroundImage: `url(${product.images[0].url})`}}
                                onClick={() => setModalImage(true)}
                            >
                                <div className={style.qtdImages}>
                                    + {product.images.length - 3}
                                </div>
                            </button>
                        
                        ) : ''}
                    
                    </div>
                    
                    {window.innerWidth < 600 ? (
                        <div className={style.imgMobile}>
                            {product && product.images.map((content, index) => {
                                return (
                                    content.type === "VIDEO" ? (
                                        <div className={style.mediaVideo} key={index}>
                                            <BoxVideo url={content.url}/>
                                        
                                        </div>
                                    ) : (
                                        <div key={index} className={style.img}
                                             style={{
                                                 backgroundImage: `url(${content.url})`
                                             }}
                                        >
                                            <div className={style.qtdIndicate}> {index + 1} de {images.length}</div>
                                            <div className={style.top}>
                                                <div className={style.data}>
                                                    <span>{product && product.startAuctionDate && NewDate(product.startAuctionDate)}</span>
                                                </div>
                                                <div className={style.icon}>
                                                    {/*<ShareIcon />
                                                <span></span>*/}
                                                    <svg onClick={() => handleFavorite()} width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <HeartProduct color={ isFavorited ? "#FD6151" : undefined}/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            })}
                        </div>
                    ) : (
                        <div className={style.imageSelect} onClick={() => setModalImage(true)}>
                            {product && product.images[currentImg].type === "VIDEO" ? (
                                <BoxVideo url={product.images[currentImg].url}/>
                            ) : (
                                <div className={style.img}
                                     style={{
                                         backgroundImage: `url(${product?.images[currentImg].url})`
                                     }}
                                />
                            )}
                            
                            <div className={style.qtdIndicate}> {currentImg} de {images.length}</div>
                            <div className={style.top}>
                                <div className={style.data}>
                                    <span>11/06/2021 - Início: 09:30h</span>
                                </div>
                                <div className={style.icon}>
                                    {/*<ShareIcon />
                                                <span></span>*/}
                                    <svg onClick={() => handleFavorite()} width="24" height="24"
                                         viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <HeartProduct color={ isFavorited ? "#FD6151" : undefined}/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    <div className={style.productInfo}>
                        <div className={style.contentInfo}>
                            <div className={style.top}>
                                <div className={style.data}>
                                    <Calendar/>
                                    <span>{product && product.startBidDate && NewDate(product.startBidDate)}</span>
                                </div>
                                <div>
                                    {/*<Share/>*/}
                                    <button onClick={() => handleFavorite()}>
                                        <Heart color={ isFavorited ? "#FD6151" : undefined}/>
                                    </button>
                                </div>
                            </div>
                            <strong className={style.name}>
                                {product?.name} - {product?.shortDescription}
                            </strong>
                            <br/>
                            <a style={{fontSize: "1.4rem", color: "#00C1A5", textAlign: "center", marginTop: "10px"}} href="#knowMore">
                                <strong> Saiba mais informações</strong>
                            </a>
                            <div className={style.containerAuction}>
                                <div className={style.auction}>
                                    <CoinIco/>
                                    <span>lance atual</span>
                                    <strong>
                                        {lastBids ? (
                                            lastBids[0].value.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })
                                        ) : (
                                            "R$ 550,00"
                                        )}
                                    </strong>
                                </div>
                                <div className={style.auctionUser}>
                                    <span>Último lance por:</span>
                                    <strong>
                                        {lastBids ? (
                                            lastBids[0].nickname
                                        ) : (
                                            "NomedeUsuario"
                                        )}
                                    </strong>
                                </div>
                            </div>
                            {product?.feesDescription && (
                                <p>{product.feesDescription}</p>
                            )}
                            <div className={style.containerButton}>
                                <button>Participar</button>
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
                
                <div id="knowMore" className={style.containerDescription}>
                    <h3>Informações</h3>
                    {product && (<p dangerouslySetInnerHTML={{__html: product.description}}/>)}
                </div>
                
                <div className={style.containerBiddingHistory}>
                    <div className={style.containerForm}>
                        <h2>Histórico de lances <span>3 de 3</span></h2>
                        
                        <table>
                            <tbody>
                                {lastBids?.map((lastBid, index) => (
                                    <tr key={index}>
                                        <td>
                                            <UserIcon />
                                            <span>{lastBid.nickname}</span>
                                        </td>
                                        <td>
                                            {formatDateTime(lastBid.dateCreated)} - {lastBid.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
    
                    {isDesktopWindow() && (
                        <div className={style.objectionBreak}>
                            {infoList && infoList.map((content, index) => {
                                return (
                                    <div key={index} className={style.content}>
                                        <div className={style.img}>
                                            <img src={content.imageUrl} alt=""/>
                                        </div>
                                        <div>
                                            <strong>{content.title}</strong>
                                            <div className={style.txt}
                                                 dangerouslySetInnerHTML={{__html: `${content.shortDescription}`}}/>
                                            <InformationLink className={style.informationLink} to={{
                                                pathname: "/saiba-mais",
                                                state: content
                                            }}>
                                                Saiba mais <ArrowRight color={"#00C1A5"}/>
                                            </InformationLink>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    
                </div>
    
                {isDesktopWindow() && (
                    <Banners type={BannerType.PRODUCT_MIDDLE} />
                )}
                
                <NewsLetterCard
                    title={'Assinar newsletter'}
                    text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
                    btText={isDesktopWindow() ? 'Enviar' : 'Ok'}
                />
                
                {modalImage && window.innerWidth > 600 ? (
                    <>
                        <div className={style.containerModalImages}>
                            
                            <div className={style.carouselcontainer}>
                                <button className={style.close}>
                                    <svg
                                        onClick={() => setModalImage(false)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                        width={15}
                                    >
                                        <path
                                            fill="#fff"
                                            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                        >
                                        </path>
                                    
                                    </svg>
                                </button>
                                <div className={style.qtdImgModal}>
                                    {currentImg + 1} /{product && product.images.length}
                                </div>
                                <button onClick={prev} className={style.left}>
                                    <svg
                                        data-icon="chevron-left"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                        width="20"
                                    >
                                        <g className="fa-group">
                                            <path
                                                fill="#e2dfdf"
                                                d="M285.59 410.4a23.93 23.93 0 0 1 0 33.84l-22.7 22.65a24 24 0 0 1-33.94 0l-154.31-154L131.42 256z"
                                                className="fa-secondary"
                                            >
                                            </path>
                                            <path
                                                fill="#fff"
                                                d="M262.85 45.06l22.7 22.65a23.93 23.93 0 0 1 0 33.84L74.58 312.9l-40-40a23.94 23.94 0 0 1 0-33.84l194.33-194a24 24 0 0 1 33.94 0z"
                                                className="fa-primary">
                                            </path>
                                        </g>
                                    </svg>
                                </button>
                                <button onClick={next} className={style.rigth}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 320 512"
                                         className="svg-inline--fa fa-chevron-right fa-w-10 fa-3x"
                                         width="20"
                                    >
                                        <g className="fa-group">
                                            <path
                                                fill="#e2dfdf"
                                                d="M188.74 256l56.78 56.89L91.21 466.9a24 24 0 0 1-33.94 0l-22.7-22.65a23.93 23.93 0 0 1 0-33.84z"
                                                className="fa-secondary">
                                            </path>
                                            <path
                                                fill="#fff"
                                                d="M91.25 45.06l194.33 194a23.93 23.93 0 0 1 0 33.84l-40 40-211-211.34a23.92 23.92 0 0 1 0-33.84l22.7-22.65a24 24 0 0 1 33.97-.01z"
                                                className="fa-primary"
                                            >
                                            </path>
                                        </g>
                                    </svg>
                                </button>
                                <div className={style.carousel}>
                                    <div
                                        className={style.item}
                                    >
                                        {product && product.images[currentImg].type === "VIDEO" ? (
                                            <BoxVideo url={product.images[currentImg].url}/>
                                        ) : (
                                            <img src={product && product.images[currentImg].url} alt=""/>
                                        
                                        )}
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </>
                ) : ''}
            
            </div>
        </div>
    )
}

export default BidProduct