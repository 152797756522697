import React from 'react'
import { BoxTitle, Container, ContentBox, Image, InformationLink, TextContent } from './styles'
import arrow_right from '../../assets/arrow-right.svg'
import { Info } from '../../modelos/Info'


interface InformationProps {
    reverse?: boolean;
    info?: Info;
}

const Information: React.FC<InformationProps> = ({ info, reverse }) =>
(
    <Container reverse={reverse}>
        <Image src={info?.imageUrl} alt="imagem ilustrativa" />
        <ContentBox>
            <BoxTitle>{info?.title}</BoxTitle>
            <TextContent>{info?.shortDescription}</TextContent>
            <InformationLink to={{
                pathname: "/saiba-mais",
                state: info
            }}>
                <strong>Saiba mais </strong> <img width='16' src={arrow_right} alt="seta para direita" />
            </InformationLink>
        </ContentBox>
    </Container>
    
)

export default Information