import React, { FormEvent, useEffect, useState } from 'react';
import { Col, InputGroupAddon } from 'reactstrap';
import { NewsletterRequest, register } from '../../services/NewsletterService';
import style from './style.module.scss'

import {
    ButtonCustomized,
    Conatiner,
    InputCustomized,
    InputGroupCustomized,
    Title,
    Text
} from './styles'

interface NewsLetterCardProps {
    title: string,
    text: string,
    btText?: string,
}

interface BoxResponse {
    message?: string,
    type?: 'error' | 'success',
    show: boolean,
}
const NewsLetterCard: React.FC<NewsLetterCardProps> = ({ title, text, btText }) => {
    const [email, setEmail] = useState('');
    const [boxResponse, setBoxResponse] = useState<BoxResponse>({ show: false });

    useEffect(() => {
        if (boxResponse.show) {
            setTimeout(() => {
                setBoxResponse({ show: false });
            }, 5000);
        }
    }, [boxResponse]);

    async function handleRegisterNewsletter(event: FormEvent) {
        event.preventDefault();

        let newsletter: NewsletterRequest = {
            email: email,
            origin: "SITE",
            source: "DEFAULT"
        };

        let req = await register(newsletter);

        if (req.httpStatusCode != 201) setBoxResponse({
            message: "E-mail já existente!",
            type: 'error',
            show: true
        });
        else {
            setBoxResponse({
                message: "E-mail cadastrado com sucesso!",
                type: 'success',
                show: true
            });

            setEmail('');
        }
    }

    return (
        <>
              <Col sm="6" lg="12" className="newsletter">
                <form onSubmit={handleRegisterNewsletter}>
                    <Conatiner body>
                        <Title tag="h2">{title}</Title>
                        <Text>{text}</Text>
                        <InputGroupCustomized>
                            <InputCustomized
                                placeholder={'seu@email.com'}
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                                required
                            />
                            <InputGroupAddon addonType="prepend">
                                <ButtonCustomized className="button" color="warning">{btText}</ButtonCustomized>
                            </InputGroupAddon>
                        </InputGroupCustomized>
                        {boxResponse.show ? (
                            <Text
                                style={{
                                    color: boxResponse.type === 'success' ?
                                        "var(--color-text-lighter)" :
                                        "var(--color-text-off-white)"
                                }}
                            >{boxResponse.message}</Text>
                        ) : ''}

                    </Conatiner>
                </form>
            </Col>
            
        </>
    );
}

export default NewsLetterCard