import { ReactNode } from 'react';
import styles from './styles.module.scss';
import { Button } from "../common/buttons/styles";

interface ModalConfirmProps{
  children: ReactNode;
  onClose: () => void;
  onConfirm: () =>  void;
}
export function ModalConfirm({ children, onClose, onConfirm }: ModalConfirmProps){
  return(
    <div className={styles.overlay} onClick={event => {
      let e = event.target;
      // @ts-ignore
      if(e.classList.contains(styles.overlay)) onClose();
    }}>
      <div className={styles.container}>
        <button type="button" onClick={onClose} className={styles.closeModal}>  
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 3L3 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 3L21 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <div className={styles.content}>
          {children}
          <div className={styles.buttonGroup}>
            <Button 
              type="button" onClick={onClose}
              style={{ background: '#828BA1' }}        
            >Não</Button>
            <Button type="button" onClick={onConfirm}>Sim</Button>
          </div>
        </div>
      </div>
    </div>
  );
}