import axios from "axios";
import {CartVO} from "../modelos/Cart";
import {getToken, isAuthenticated} from "./AuthService";
import StorageUtils from "../utils/StorageUtils";
import api from "./api";

const baseURL = process.env.REACT_APP_BACKEND_HOST;

export interface Favorite {
  name: string;
  shortDescription: string;
  description: string;
  category: {
    name: string;
    _id: string;
  },
  startBidDate: string;
  startAuctionDate: string;
  informations: string;
  images: {
    title: string;
    type: string;
    url: string;
    key: string;
    order: number;
  }[],
  costPrice: number;
  startPrice: number;
  addValuePerBid: number;
  addValuePerBidType: string;
  currentPrice: number;
  minimumSalePrice: number;
  wantedSalePrice: number;
  fees: number;
  feesDescription: string;
  coinsCostPerBid: number;
  receivedCoins: number;
  minimumCoins: number;
  wantedCoins: number;
  useMinimumSalePrice: boolean,
  useWantedSalePrice: boolean,
  useFees: boolean,
  useMinimumCoins: boolean,
  useWantedCoins: boolean,
  active: boolean,
  status: string;
  auctionTerms: string;
  dateCreated: string;
  lastUpdated: string;
  valid: boolean,
  _id: string
}

export interface ResponseResult{
  response: string,
  result: boolean,
  noAuth?: boolean,
}
export type ResponseResultGetFavorites = Omit<ResponseResult, 'response'> & {
  response: Favorite[];
}

// DEVE ESTAR  AUTENTICADO
export const addFavorite = async (productId: string) : Promise<ResponseResult> => {
  try{
    const userToken = localStorage.getItem('userToken');
    if(!userToken) return {
      result: false,
      response: 'Faça o login',
      noAuth: true
    };

    let response: ResponseResult = {
      response: 'Houve um erro ao realizar a requisição',
      result: false
    };
    
    const { data } = await axios.post(`${baseURL}/api/p/favorite`,{ productId },{
      headers: { Authorization: `Bearer ${userToken}` }
    });
    
    if(data.httpStatusCode === 201) response = {
      response: 'Adicionado aos favoritos',
      result: true
    };

    return response;
  }
  catch(err: any) {
    let message = 'Houve um erro ao realizar a requisição';
    if(err && err.response && err.response.data && err.response.data.message) message = err.response.data.message
    return {
      response: message,
      result: false
    };
  }

}

export const deleteFavorite = async (productId: string): Promise<ResponseResult> => {
  try{
    const userToken = localStorage.getItem('userToken');
    if(!userToken) return {
      result: false,
      response: 'Faça o login',
      noAuth: true
    };

    let response: ResponseResult = {
      response: 'Houve um erro ao realizar a requisição',
      result: false
    };
    
    await axios.delete(`${baseURL}/api/p/favorite`,{
      headers: { Authorization: `Bearer ${userToken}` },
      data: { productId }
    });
    
    response = {
      response: 'Removido dos favoritos',
      result: true
    };

    return response;
  }
  catch(err: any) {
    let message = 'Houve um erro ao realizar a requisição';
    if(err && err.response && err.response.data && err.response.data.message) message = err.response.data.message
    return {
      response: message,
      result: false
    };
  }

}
export const getFavorites = async () : Promise<ResponseResultGetFavorites | ResponseResult> => {
  try{
    const userToken = localStorage.getItem('userToken');
    if(!userToken) return {
      result: false,
      response: 'Faça o login',
      noAuth: true
    };

    const { data } = await axios.get(`${baseURL}/api/p/favorite`,{
      headers: { Authorization: `Bearer ${userToken}` }
    });

    return {
      result: true,
      response: data
    };
  }
  catch(err: any){
    let message = 'Houve um erro ao realizar a requisição';
    if(err && err.response && err.response.data && err.response.data.message) message = err.response.data.message
    return {
      response: message,
      result: false
    };
  }
}

export const findFavorites = async () : Promise<Favorite[] | undefined> => {
    try {
        if(isAuthenticated(true, false)) {
            const response = await api.get(`/p/favorite`,
                { headers: { "Authorization": `Bearer ${getToken()}` } }
            )
            return response.data
        }
    } catch (error:any) {
        if(error.response) {
            if(error.response.status === 404) {
                return undefined
            } else {
                throw new Error(error)
            }
        }
    }
}