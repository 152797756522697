import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface ModalTermsProps{
  onClose: () => void;
}
export function ModalTerms({ onClose }: ModalTermsProps){
  const [terms, setTerms] = useState('...');

  useEffect(() => {

  },[]);
  return(
    <div className={styles.overlay} onClick={event => {
      let e = event.target;
      // @ts-ignore
      if(e.classList.contains(styles.overlay)) onClose();
    }}>
      <div className={styles.container}>
        <button type="button" onClick={onClose} className={styles.closeModal}>  
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 3L3 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 3L21 21" stroke="#828BA1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <div className={styles.content}>
          <h3>Termos e Condições de Uso</h3>
          <div dangerouslySetInnerHTML={{__html: terms}} />
        </div>
      </div>
    </div>
  );
}