import { Link } from 'react-router-dom';
import { signOut } from '../../../services/AuthService';
import {
    BindIco,
    BoxIco, CoinsIcon,
    CredtCardIco,
    EmailIco,
    ExitIco,
    FileIco,
    GearIco,
    HomeIco,
    WhatsAppIco
} from '../../Icons';
import styles from './styles.module.scss';

interface SidebarProps{
  active?: string
}
export function Sidebar({ active = 'Pagina Inicial'}: SidebarProps){
  return(
    <aside className={styles.container}>
      <h2 className={styles.title}>Minha Conta</h2>
      <ul className={styles.nav}>
        <li className={active === 'Pagina Inicial' ? styles.active: ''}>
          <Link to="/minha-conta">
            <span className={styles.contentIcon}>
              <HomeIco color={active === 'Pagina Inicial' ? '#00C1A5' : '#828BA1'}/>
            </span> Pagina Inicial
          </Link>
        </li>
        <li className={active === 'Como funciona' ? styles.active: ''}>
          <Link to="/minha-conta/como-funciona">
            <span className={styles.contentIcon}>
              <FileIco color={active === 'Como funciona' ? '#00C1A5' : '#828BA1'}/>
            </span> Como funciona
          </Link>
        </li>
        <li className={active === 'Meu cadastro' ? styles.active: ''}>
          <Link to="/minha-conta/meu-cadastro">
            <span className={styles.contentIcon}>
              <GearIco color={active === 'Meu cadastro' ? '#00C1A5' : '#828BA1'}/>
            </span> Meu cadastro
          </Link>
        </li>
        <li className={active === 'Minhas Compras' ? styles.active: ''}>
          <Link to="/minha-conta/minhas-compras">
            <span className={styles.contentIcon}>
              <CoinsIcon color={active === 'Minhas Compras' ? '#00C1A5' : '#828BA1'}/>
            </span> Minhas Compras
          </Link>
        </li>
        {/*<li className={active === 'Meus arremates' ? styles.active: ''}>
          <Link to="/minha-conta/meus-arremates">
            <span className={styles.contentIcon}>
              <BindIco color={active === 'Meus arremates' ? '#00C1A5' : '#828BA1'}/>
            </span> Meus arremates
          </Link>
        </li>*/}
        <li className={active === 'Pagamentos' ? styles.active: ''}>
          <Link to="/minha-conta/pagamentos">
            <span className={styles.contentIcon}>
              <CredtCardIco active={active === 'Pagamentos'}/>
            </span> Formas de Pagamentos
          </Link>
        </li>
        <li className={active === 'Retirada' ? styles.active: ''}>
          <Link to="/minha-conta/retirada">
            <span className={styles.contentIcon}>
              <BoxIco color={active === 'Retirada' ? '#00C1A5' : '#828BA1'}/>
            </span> Retirada
          </Link>
        </li>
        <li className={active === 'Sair' ? styles.active: ''}>
          <a href="javascript:;" onClick={signOut}>
            <span className={styles.contentIcon}><ExitIco/></span> Sair
          </a>
        </li>
      </ul>

      <hr/>

      <div className={styles.info}>
        <p>Atendimento:</p>
        <a href="#" className={styles.socialButton} target="_blank">
          <WhatsAppIco color="#828BA1" size={20}/> <span>Via Whatsapp</span>
        </a>
        <a href="#" className={styles.socialButton} target="_blank">
          <EmailIco color="#828BA1" size={20}/> <span>Via e-mail</span>
        </a>
        <p>
          segunda a domingo: 8h às 20h<br/>
          (exceto feriados)
        </p>

      </div>
    </aside>
  );
}