import {useContext, useEffect, useState} from 'react';
import { CoinsIco, YellowUserIco, CoinIco } from '../../components/Icons';
import { Breadcrumbs } from '../../components/my-account/breadcrumbs';
import { Sidebar } from '../../components/my-account/sidebar';
import styles from './styles.module.scss';
import homeStyles from './home.module.scss';
import { getCustomerByToken } from '../../services/UserService';
import { useHistory } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { signOut } from '../../services/AuthService';
import {Context} from "../../contexts/ContextProvider";

interface User{
  email: string,
  fullName: string,
  nickname: string,
}
export default function MyAccount(){
  const [user, setUser] = useState<User>({
    fullName: '-',
    nickname: '-',
    email: '-',
  });
  const [easyCoins, setEasyCoins] = useState(42);
  const [publicSales, setPublicSales] = useState(4);

  const history = useHistory();
  const context = useContext(Context)

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if(!userToken){
      history.push('/login');
      return;
    }
    getUserData(userToken);
  },[]);

  async function getUserData(userToken: string){
    try{
      const data = await getCustomerByToken(userToken);
      setUser(data);
    }catch(err: any){
      signOut();
      history.push('/login');
    }
  }
  return(
    <div className={styles.container}>
      <ToastContainer/>
      <Breadcrumbs/>
      <div className={homeStyles.content}>
        <Sidebar/>
        <main>
          <h3>Olá {user.fullName} </h3>
          <p className={homeStyles.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum quam, fringilla eget tincidunt nec, finibus a nisi. Maecenas sit amet mauris maximus tortor varius tristique.</p>
          <div className={homeStyles.containerCards}>
            <div className={homeStyles.card}>
              <CoinsIco/>
              <div>
                Você tem<br/>
                <strong>{context.wallet.coins} Easycoins</strong>
              </div>
              <Link to="/easyCoins" className={homeStyles.btnRedirect}>Compre agora</Link>
            </div>
            <div className={homeStyles.card}>
              <YellowUserIco/>
              <div>
                <strong>{user.nickname}</strong><br/>
                {user.email}
              </div>
              <Link to="/minha-conta/meu-cadastro" className={homeStyles.btnRedirect}>Ver meus dados</Link>
            </div>
            <div className={homeStyles.card}>
              <CoinIco/>
              <div>
                Você está participando<br/>
                de <strong>{publicSales} leilões</strong>
              </div>
              <Link to="/minha-conta/meus-arremates" className={homeStyles.btnRedirect}>Compre agora</Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}