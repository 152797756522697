import { Image, getInitialImage } from "./Image";

export interface EasyCoins {
    name: string;
    shortDescription: string;
    type: string;
    image: Image;
    costPrice: number;
    coins: number;
    active: boolean;
    deleted: boolean;
    _id: string;
    fees: number,
    feesDescription: string,
    totalPrice: number,
}

export const getInitialEasyCoins = (): EasyCoins => {
    const initialEasyCoins: EasyCoins = {
        name: '',
        shortDescription: '',
        type: '',
        image: getInitialImage(),
        costPrice: 0,
        coins: 0,
        active: false,
        deleted: false,
        _id: '',
        fees: 0,
        feesDescription: '', 
        totalPrice: 0
    }

    return initialEasyCoins
}