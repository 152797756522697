import React from 'react'
import {
  AuctionBegin,
  AuctionDate,
  BidInfo,
  BidTitle,
  ButtonCustomized,
  ButtonWrapper,
  CardBodyCustomized,
  CardImgCustomized,
  CardImgOverlayContent,
  CardTitleCustomized,
  Container,
  ContentBox,
  CurrentBid,
  DateBox,
  MoneyImg,
  Overlay
} from './styles'

import hammer from '../../assets/hammer.svg'
import calendar from '../../assets/calendar.svg'

import { Heart } from '../Icons';

interface ProductCardProps {
  _id: string;
  date: string;
  hour: string;
  cardTitle: string;
  bid: number;
  img: any;
  handleFavorite?: {
    isFavorite: boolean;
    addFavorite: (id: string) => void;
    deleteFavorite: (id: string) => void;
  }
}

const ProductCard: React.FC<ProductCardProps> = ({ _id, date, hour, cardTitle, bid, img, handleFavorite}) => (
  <Container>
    <CardImgCustomized top width="100" src={img} alt="Card image cap" />
    <Overlay>
      <CardImgOverlayContent>
        <DateBox>
          <img width={16} src={calendar} alt="calendário" />
          <AuctionDate> {date} - <AuctionBegin>Início:</AuctionBegin> {`${hour}h`}</AuctionDate>
        </DateBox>
        { handleFavorite ? (
          <span onClick={() => handleFavorite.isFavorite ? 
            handleFavorite.deleteFavorite(_id) :
            handleFavorite.addFavorite(_id)
          } style={{ cursor: 'pointer' }}>
            <Heart color={ handleFavorite.isFavorite ? "#FD6151" :"#828BA1"}/>
          </span>
        ):''}
      </CardImgOverlayContent>
    </Overlay>
    <CardBodyCustomized>
      <CardTitleCustomized tag="h5">{cardTitle}</CardTitleCustomized>
      <ContentBox>
        <BidInfo>
          <MoneyImg width={49} src={hammer} alt="dóllar" />
          <div>
            <BidTitle>lance atual</BidTitle>
            <CurrentBid>{`R$${bid.toFixed(2)}`}</CurrentBid>
          </div>
        </BidInfo>
        <ButtonWrapper style={{ width: '100%' }} to="/leilao">
          <ButtonCustomized size="lg" color="success" onClick={() => console.log('EEEi')}>Participar</ButtonCustomized>
        </ButtonWrapper>
      </ContentBox>
    </CardBodyCustomized>
  </Container>
)

export default ProductCard