export interface KnowMore {
    title: string;
    subtitle: string;
    shortDescription: string;
    description: string;
    imageUrl: string;
    status: string;
    dateCreated: string;
    lastUpdated: string;
    _id: string;

}

export const getInitialKnowMore = (): KnowMore => {
    const initialKnowMore: KnowMore = {
        title: '',
        subtitle: '',
        shortDescription: '',
        description: '',
        imageUrl: '',
        status: '',
        dateCreated: '',
        lastUpdated: '',
        _id: ''
    }

    return initialKnowMore
}