import React, { useEffect, useState, useRef, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { ModalTerms } from '../../../components/modal-terms';
import { onlyLettersAndNumbers } from '../../../utils/utilities';

import styles from '../styles.module.scss';

interface FinalRegisterProps{
    username: string,
    setUsername: (param: string) => void,
    password: string,
    setPassword: (param: string) => void,
    confPassword: string,
    setConfPassword: (param: string) => void,
    handleRegister: () => Promise<void>,
    goBack: () => void
}
export function FinalRegister({
    username,
    setUsername,
    password,
    setPassword,
    confPassword,
    setConfPassword,
    handleRegister,
    goBack
}: FinalRegisterProps){
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const divConfPassword = useRef<HTMLDivElement>(null);

    async function handleSubmit(event: FormEvent){
        event.preventDefault();
        if(!isValidPassword()) return;
        
        handleRegister();
    }
    function isValidPassword(){
        if(password !== confPassword){
            if(divConfPassword.current){
                divConfPassword.current.classList.add(styles.formError);
            }
            return false;
        }
        if(divConfPassword.current){
            divConfPassword.current.classList.remove(styles.formError);
        }
        return true;
    }

    return (
        <>
            <form className={styles.content} onSubmit={handleSubmit}>
                <h2 className={styles.title}>Para terminar, defina um nome de usuário e uma senha</h2>
                <fieldset>
                    <div className={styles.formGroup}>
                        <input
                            type="text"
                            placeholder="Nome de usuário"
                            value={username}
                            pattern="[a-zA-Z0-9]+"
                            onChange={e => setUsername(onlyLettersAndNumbers(e.target.value))}
                            required
                        />
                        <span>Use letras ou números, mas evite pontos e espaços</span>
                    </div>
                </fieldset>
                <fieldset>
                    <div className={styles.formGroup}>
                        <input
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className={styles.formGroup} ref={divConfPassword}>
                        <input
                            type="password"
                            placeholder="Confirmar senha"
                            value={confPassword}
                            onChange={e => setConfPassword(e.target.value)}
                            required
                        />
                        <span className={styles.errorMessage}>Senha e Validação de Senha devem ser iguais.</span>
                    </div>
                </fieldset>
                <div className={styles.customCheckbox}>
                    <label>
                        <input type="checkbox" onClick={() => setAcceptTerms(!acceptTerms)} required/>
                        <p className={styles.textInfo}>
                            Eu concordo com os <a
                                href="#"
                                onClick={() => setOpenTerms(true)}
                            >termos e condições de uso</a>
                        </p>
                    </label>
                </div>
                <button type="submit" className={styles.btnSubmit}>Concluir</button>
                <a
                    href="#"
                    className={styles.textInfo}
                    style={{ display: 'block' }}
                    onClick={goBack}
                >Voltar</a>

                <p className={styles.textInfo} style={{ marginTop: 52 }}>
                    Já tem cadastro? Faça <Link to="/login">login aqui</Link>
                </p>
            </form>
            {openTerms ? (
                <ModalTerms
                    onClose={() => setOpenTerms(false)}
                />
            ):''}
        </>
    )
}