import axios from 'axios';
const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST

export const findAllProducts = async () => {
    try {
        const url: string = `${BACKEND_HOST}/api/o/product`
        const response: any = await axios.get(url)
        return response
    } catch (err) {
        throw new Error(err)
    }
}

export const findProductsByCategory = async (categoryId: string) => {
    try {
        const url: string = `${BACKEND_HOST}/api/o/category/${categoryId}/products`
        const response: any = await axios.get(url)
        return response
    } catch (err) {
        throw new Error(err)
    }
}


// export const initialProductValue = () => {
//     return {
//         active: false,
//         addValuePerBid: 0,
//         addValuePerBidType: "",
//         auctionTerms: "",
//         category: { name: "", _id: "" },
//         coinsCostPerBid: 0,
//         costPrice: 0,
//         currentPrice: 0,
//         dateCreated: "",
//         description: "",
//         fees: 0,
//         feesDescription: "",
//         images: [],
//         informations: "",
//         lastUpdated: "",
//         minimumCoins: 0,
//         minimumSalePrice: 0,
//         name: "",
//         receivedCoins: 0,
//         shortDescription: "",
//         startAuctionDate: "",
//         startBidDate: "",
//         startPrice: 0,
//         status: "",
//         useFees: false,
//         useMinimumCoins: false,
//         useMinimumSalePrice: false,
//         useWantedCoins: false,
//         useWantedSalePrice: false,
//         valid: false,
//         wantedCoins: 0,
//         wantedSalePrice: 0,
//         _id: ""
//     }
// }

export const getInitialProductValue = () => {
    return {
        images: [{ url: '' }],
        costPrice: 0,
        currentPrice: 0,
        startAuctionDate: '',
        shortDescription: ''
    }
}