import styled from 'styled-components'

export const Button = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 10px 32px;
   
    width: 192px;
    height: 53px;
    
    background: var(--color-success);
    border-radius: 8px;
    border: none;
`
export const ButtonLabel = styled.span`
    font-size: 16px;
    line-height: 33px;
    color: var(--color-text-light);
`