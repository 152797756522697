import axios from 'axios';
import { BasicAuth } from '../modelos/BasicAuth';
import StorageUtils from '../utils/StorageUtils';
import history from "./history";
const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST

export const auth = async (basicAuth: BasicAuth) => {
    try {
        const url: string = `${BACKEND_HOST}/api/o/customer-login/login`
        const response: any = await axios.post(url, basicAuth)
        const token = response.data.token;
        //const expires = decode(token)
        //const expires = (JSON.parse(atob(token.split('.')[1]))).exp;
        //console.log("auth - expires", expires)
        StorageUtils.setStorageItem(StorageUtils.KEYS.USER_TOKEN, response.data.token)
    } catch (err) {
        // @ts-ignore
        throw new Error(err)
    }
}

export function isValidToken(token: any) {
    return !!(token && token.sub && token.id && token.email && token.role && token.status && token.iat && token.exp)
}

export const isAuthenticated = (doSignOut = false, callLogin = false) => {
    
    let isAuthenticated = true
    
    try {
        const token = getDecodedToken()
        
        if (!token) {
            console.log("isAuthenticated: Sem token")
            isAuthenticated = false
        }

        const isObject = typeof token === 'object'
        
        if (!isObject || !isValidToken(token)) {
            console.log("isAuthenticated: Token inválido")
            isAuthenticated = false
            
        } else {
            if (!token.exp || token.exp < Date.now() / 1000) {
                console.log("isAuthenticated: Token expirado")
                isAuthenticated = false
            }
        }
        
    } catch (err) {
        console.error('isAuthenticated: Erro ao verificar se está autenticado', err)
        isAuthenticated = false
    }
    
    if(!isAuthenticated) {
        console.log("isAuthenticated: Não autenticado")
        if(doSignOut) {
            signOut()
            //history.push('/login')
        }
        if(callLogin) {
            history.push('/login')
        }
    }
    
    return isAuthenticated
}

export const getToken = () => {
    return StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
}

export const getDecodedToken = () => {
    const token = getToken()
    
    if (!token) return null
    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    return decodedToken
}

export const signOut = () => {
    StorageUtils.removeStorageItem(StorageUtils.KEYS.USER_TOKEN)
    //localStorage.clear();
}
