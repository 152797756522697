import React, { FormEvent, useState } from 'react'
import { cpfMask } from '../../../utils/utilities';
import { Link } from 'react-router-dom';
import styles from '../styles.module.scss';
import { cpfValidationAlgorithm } from '../../../services/RegisterService';

interface FirstRegisterProps{
    name: string,
    setName: (param: string) => void,
    email: string,
    setEmail: (param: string) => void,
    birthdate: string,
    setBirthdate: (param: string) => void,
    cpf: string,
    setCpf: (param: string) => void,
    next: () => void,
}
export function FirstRegister({
    name,
    setName,
    email,
    setEmail,
    birthdate,
    setBirthdate,
    cpf,
    setCpf,
    next

}: FirstRegisterProps){
    const formErroDefault = {
        name: {
            hasError: false,
            errorMessage: ''
        },
        cpf: {
            hasError: false,
            errorMessage: ''
        }
    };
    const [formError, setFormError] = useState(formErroDefault);

    function handleSubmit(event: FormEvent){
        event.preventDefault();
        setFormError({...formErroDefault});
        
        let validateName = name.trim();
        setName(validateName);
        if(validateName.length ==  0){
            handleFormError('name', "Preencha o campo 'Nome completo'");
            return;
        }
        let arrName = validateName.split(' ');
        if(arrName.length < 2){
            handleFormError('name', "Deve conter nome e sobrenome");
            return;
        }

        if(!cpfValidationAlgorithm(cpf)){
            handleFormError('cpf', "CPF inválido");
            return;
        }

        next();
    }
    function handleFormError(key: 'name' | 'cpf', value: string){
        setFormError(old => {
            let temp = old;
            temp[key].hasError = true;
            temp[key].errorMessage = value;

            return {
                ...temp
            };
        });
    }

    return (
        <form className={styles.content} onSubmit={handleSubmit}>
            <h2 className={styles.title}>Preencha os campos para prosseguir com o cadastro</h2>
            <fieldset>
                <div className={`${styles.formGroup} ${formError.name.hasError ? styles.formError : ''}`}>
                    <input
                        type="text"
                        placeholder="Nome completo"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                    />
                    <span className={styles.errorMessage}>{formError.name.errorMessage}</span>
                </div>
                <div className={styles.formGroup}>
                    <input
                        type="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                </div>
            </fieldset>
            <fieldset>
                <div className={styles.formGroup}>
                    <input
                        type="date"
                        placeholder="Data de nascimento"
                        value={birthdate}
                        onChange={e => setBirthdate(e.target.value)}
                        required
                    />
                </div>
                <div className={`${styles.formGroup} ${formError.cpf.hasError ? styles.formError : ''}`}>
                    <input
                        type="text"
                        placeholder="CPF"
                        value={cpf}
                        onChange={e => setCpf(cpfMask(e.target.value))}
                        maxLength={14}
                        required
                    />
                    <span className={styles.errorMessage}>{formError.cpf.errorMessage}</span>
                </div>
            </fieldset>
            <button type="submit" className={styles.btnSubmit}>Próxima</button>

            <p className={styles.textInfo} style={{ marginTop: 52 }}>
                Já tem cadastro? Faça <Link to="/login">login aqui</Link>
            </p>
        </form>
    )
}