import React, {useEffect, useState} from 'react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import NewsLetterCard from '../../components/news-letter-card/NewsLetterCard'
import {getCategories} from '../../services/CategoryService'
import {getKnowMoreInfo} from '../../services/InfoServices'
import {findAllProducts, findProductsByCategory, getInitialProductValue} from '../../services/ProductService'
import {isMobile} from 'react-device-detect';
import {InformationLink} from '../../components/information/styles'
import {addFavorite, deleteFavorite, Favorite, getFavorites} from '../../services/FavoriteService';
import {SectionTitleCentered} from './styles'
import style from './styles.module.scss';
import {Link, useHistory} from "react-router-dom";
import {Frame, Heart} from '../../components/Icons';
import {getInitialKnowMore, KnowMore} from '../../modelos/KnowMore'
import {TestimonyCard} from '../../components/testimonyCard'
import arrow_right from "../../assets/arrow-right.svg";
import {toast, ToastContainer} from 'react-toastify';
import {isAuthenticated} from "../../services/AuthService";
import {getBannersByType} from "../../services/BannerService";
import {Banner, BannerType} from "../../modelos/Banner";
import Banners from "../../components/banners/Banners";

const Home: React.FC = (props: any) => {
    let initialProductValue: any = getInitialProductValue()
    const [categories, setCategories] = useState([{ name: '', _id: '' }])
    const [products, setProducts] = useState([initialProductValue])
    const [isProductAvaiable, setIsProductAvaiable] = useState(false)
    const [bannersTop, setBannersTop] = useState<Banner[]|undefined>()
    const [activeCategory, setActiveCategory] = useState('')
    const brands = [1, 2, 3, 4, 5]
    const brandsSize = isMobile ? 3 : 4
    const testimonialsSize = isMobile ? [1,2,3] : [1, 2, 3]
    const history = useHistory();
    let initialKnowMore: KnowMore = getInitialKnowMore()
    const [infoList, setInfoList] = useState([initialKnowMore])


    useEffect(() => {
        getCategoryList()
        getAllProducts()
        loadBannersTop()
        getKnowMoreInfos()
    }, [])

    const getCategoryList = async () => {
        try {
            setIsProductAvaiable(false)
            const results: any = await getCategories()
            setCategories(results)
        } catch (e) {
            console.error('Não foi possível carregar as categorias.')
        } finally {
            setIsProductAvaiable(true)
        }
    }

    const getAllProducts = async () => {
        try {
            setIsProductAvaiable(false)
            const result: any = await findAllProducts();
            const products: any = result.data;
            if(isAuthenticated(true, false)) {
                const productsFavoriteds = await handleGetFavorite() as Favorite[];
    
                setProducts(products.map((product: any) =>{
                    return {
                        ...product,
                        is_favorite: !!productsFavoriteds.find(favorited => favorited._id === product._id)
                    };
                }))
            } else {
                setProducts(products)
            }
            
        } catch (e) {
            console.error('Não foi possível carregar os produtos.')
        } finally {
            setIsProductAvaiable(true)
        }

    }

    const getProductsByCategory = async (categoryId: string) => {
        try {
            setIsProductAvaiable(false)
            const result: any = await findProductsByCategory(categoryId)
            const products: any = result.data
            setProducts(products)
            setActiveCategory(categoryId)
        } catch (e) {
            console.error('Não foi possível carregar os produtos.')
        } finally {
            setIsProductAvaiable(true)
        }
    }

    const loadBannersTop = async () => {
        try {
            const banners = await getBannersByType(BannerType.HOME_TOP)
            setBannersTop(banners)
        } catch (e) {
            console.error('Não foi possível carregar os banners - HOME_TOP.')
        }
    }

    const resolveCategoriesQtd = () => {
        console.log("Categorias - QTD" + categories.length)
        return categories.length < 4 ? categories.length + 1 : 4
    }

    const { width } = useWindowDimensions();

    const isDesktopWindow = () => { return width > 700 }
    
    const getKnowMoreInfos = async () => {
        try {
            const results: any = await getKnowMoreInfo()
            setInfoList(results)
        } catch (e) {
            console.error('Não foi possível exibir os cards de informações.')
        }
    }

    async function handleFavorite(id: string){
        const data = await addFavorite(id);

        if(data.noAuth) history.push("/login");

        console.log(data);
        if(!data.result) toast.error(data.response);
        else{
            toast.success(data.response);
            handleToggleFavorite(id);
        }
    }
    async function handleDeleteFavorite(id: string){
        const data = await deleteFavorite(id);

        if(data.noAuth) history.push("/login");

        if(!data.result) toast.error(data.response);
        else{
            toast.success(data.response);
            handleToggleFavorite(id, false);
        }
    }
    function handleToggleFavorite(id: string, favorited = true){
        let index = products.findIndex(product => product._id === id);
        if(index === -1) return;
        let temp = products;
        temp[index].is_favorite = favorited;
        setProducts([...temp]);
    }
    async function handleGetFavorite(){
        const data = await getFavorites();

        if(data.result) return data.response;
        return [];
    }
    return (
        <div className="container">
            <ToastContainer/>
            <div className={style.headerRange}>
            </div>
    
            <Banners type={BannerType.HOME_TOP} />
            
            <div className={style.categoryContainer}>
                {categories.map(content => {
                    return (
                        <div
                            onClick={() => getProductsByCategory(content._id)}
                            key={content._id} className={style.item}
                            style={{
                                backgroundColor: activeCategory === content._id ? '#828BA1' : '',
                                color: activeCategory === content._id ? '#fff' : ''
                            }}
                        >
                            <span className={style.categoryItem}>{content.name}</span>

                        </div>
                    )
                })}
            </div>
            <div className={style.containerAnumciment}>
                <div className={style.row}>
                    {products.map((content, idx) => {
                        let date = new Date(content.startAuctionDate);
                        let day = date.getDate().toString().padStart(2, '0');
                        let month = (date.getMonth() + 1).toString().padStart(2, '0');
                        let year = date.getFullYear();
                        let hour = date.getHours().toString().padStart(2, '0');
                        let minutes = date.getMinutes().toString().padStart(2, '0');

                        return (
                            <div key={idx} className={style.col}>
                                <div className={style.item}>
                                    <div style={{ backgroundImage: `url(${content.images[0].url})` }} className={style.img}>
                                        <div className={style.top}>
                                            <div className={style.date}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6667 2.66699H3.33333C2.59695 2.66699 2 3.26395 2 4.00033V13.3337C2 14.07 2.59695 14.667 3.33333 14.667H12.6667C13.403 14.667 14 14.07 14 13.3337V4.00033C14 3.26395 13.403 2.66699 12.6667 2.66699Z" stroke="#828BA1" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.6667 1.33301V3.99967" stroke="#828BA1" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M5.33331 1.33301V3.99967" stroke="#828BA1" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2 6.66699H14" stroke="#828BA1" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                                <span>{day}/{month}/{year} - {hour}:{minutes}h </span>
                                            </div>
                                            <button onClick={() => content.is_favorite ?
                                                handleDeleteFavorite(content._id) :
                                                handleFavorite(content._id)
                                            }>
                                                <Heart color={ content.is_favorite ? "#FD6151" :"#828BA1"}/>
                                            </button>

                                        </div>
                                    </div>
                                    <strong>{content.name} - {content.shortDescription}</strong>
                                    <div className={style.call}>
                                        <div>
                                            <Frame />
                                            <div>
                                                <span>lance atual</span>
                                                <strong>{content.currentPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                            </div>
                                        </div>
                                        <Link className={style.btn} to={`/leilao/${content._id}`}>
                                                Participar
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}


                </div>

            </div>

            {/* <InformationGroup /> */}
            <div className={style.objectionBreak}>
                {infoList && infoList.map(content => {
                    return (
                        <div className={style.content} key={content._id}>
                            <div className={style.img} >
                                <img src={content.imageUrl} alt={content.title} />
                            </div>
                            <div className={style.txt}>
                                <strong>{content.title}</strong>
                                <p dangerouslySetInnerHTML={{ __html: content.shortDescription }} />
                                <InformationLink to={{pathname: "/saiba-mais", state: content}} >
                                    Saiba mais <img width='16' src={arrow_right} alt="seta para direita" />
                                </InformationLink>
                                {/*<Link to="">
                                    Saiba mais
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.33333 8H12.6667" stroke="#00C1A5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 3.33301L12.6667 7.99968L8 12.6663" stroke="#00C1A5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </Link>*/}
                            </div>
                        </div>
                    )
                })}
            </div>
    
            <Banners type={BannerType.HOME_MIDDLE} />

            <SectionTitleCentered>Marcas Parceiras</SectionTitleCentered>
            <div className={style.containerBrands}>
                {brands.map(item => {
                    return (
                        <span key={item}>
                            Marca {item}
                        </span>

                    )
                })}
            </div>

            <NewsLetterCard
                title={'Assinar newsletter'}
                text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
                btText={isDesktopWindow() ? 'Enviar' : 'Ok'}
            />

            <h3 style={{ textAlign: 'center', marginTop: '2rem', fontSize: '3rem', color: '#262A34' }}>Depoimentos</h3>
            <p style={{color:'#828BA2', textAlign: 'center', fontSize: '1.5rem', marginBottom: '3rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ac nulla id enim vestibulum fringilla at ac quam</p>
            <div className={style.testimonyContainer}>
                {testimonialsSize.map(content => {
                    return (
                        <div className={style.content} key={content}>
                            <TestimonyCard
                                vote={5}
                                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam urna mi, rutrum eget dolor sit amet, consequat faucibus erat. Fusce ullamcorper enim vitae scelerisque dignissim. In vitae feugiat dui. Nullam ligula lectus, elementum quis tortor eu, condimentum venenatis lacus. Praesent auctor finibus hendrerit."
                                name="Sofia Toledo Guimarães"
                            />
                        </div>
                    )
                })}


            </div>


        </div>


    )
}

export default Home
