import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    to{
        transform: rotate(360deg);
    }
`
export const DottedLoading = styled.div`
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    border: dotted 0.3rem;
    border-color: #ffffff16 #b564db #b564db;
    animation: ${spin} 1.8s infinite linear;
`

export const ContainerLoading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`

