import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BinIco, CoinsIco } from "../../../components/Icons";
import style from './style.module.scss';
import api from "../../../services/api";
import { ToastContainer, toast } from 'react-toastify';
import StorageUtils from "../../../utils/StorageUtils";
import {AlertModal, AlertModalType} from "../../../components/alert-modal";
import {isAuthenticated} from "../../../services/AuthService";
import CartUtils from "../../../utils/CartUtils";
import {CartVO, CartItem, CartRequest} from "../../../modelos/Cart";
import {createCart, findCartByID, updateCartByID} from "../../../services/CartService";

type cartItemRow = {
    id: string,
    quantity: number,
    price: number,
    addOrRemoveItemCart: (id: string, add?: boolean) => void,
    total: number,
}

enum NotificationType {
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR"
}

const Qtd = ({ id, quantity, price, addOrRemoveItemCart, total }: cartItemRow) => {

    return (
        <>
            <td>
                {(price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </td>
            <td>
                <div>
                    <button disabled={quantity === 1} onClick={() => addOrRemoveItemCart(id, false)}>-</button>
                    <span>{quantity}</span>
                    <button onClick={() => addOrRemoveItemCart(id)}>+</button>
                </div>
            </td>
            <td>
                {(total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </td>
        </>
    )
}

export function Cart() {
    const [contentCart, setContentCart] = useState<CartVO>();
    const [contentCartItem, setContentCartItem] = useState<CartItem>();
    const [coupon, setCoupon] = useState<string>();
    const [activeCoupon, setActiveCoupon] = useState(false);
    const [access_token, setAccess_token] = useState('');
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [alertModalType, setAlertModalType] = useState(AlertModalType.NONE);
    const [alertModalMessage, setAlertModalMessage] = useState("");

    const Router = useHistory();

    useEffect(() => {
        let userToken = StorageUtils.getStorageItem(StorageUtils.KEYS.USER_TOKEN)
        
        if (userToken) {
            setAccess_token(userToken);
        } else {
            Router.push('/login')
        }
    
        checkCart()
    }, [access_token])
    
    /*useEffect(() => {
        checkCart()
    }, [access_token])*/

    function checkCart() {
        const cartId = CartUtils.getLocalCartId()
        const hasCartItemsLocal = CartUtils.hasCartItemsLocal()
        
        if (cartId && hasCartItemsLocal) {
            updateCart()
        } else if(hasCartItemsLocal) {
            registerCart()
        } else if(cartId) {
            getCartByID()
        }
    }
    
    /*useEffect(() => {
        updateCartByID()
    }, [product])*/
    
    function handleAlertModal(alertType: AlertModalType, message: string) {
        setAlertModalType(alertType)
        setAlertModalMessage(message)
        setAlertModalOpen(true)
    }
    
    const registerCart = async () => {
        if(isAuthenticated(true, true)) {
    
            let cartItemsLocal = CartUtils.getCartItemsLocal()
            
            if (cartItemsLocal?.length > 0) {
    
                let cartRequest : CartRequest = {
                    items: [...cartItemsLocal]
                }
                
                try {
                    const cartCreated : CartVO | undefined = await createCart(cartRequest)
                    if(cartCreated) {
                        handleLocalCart(cartCreated);
                    }
                    
                } catch (error) {
                    handleAlertModal(
                        AlertModalType.ERROR,
                        "Houve um erro ao criar seu carrinho, tente novamente!"
                    )
                }
            }
        }
    }
    
    const updateCart = async () => {
        if(isAuthenticated(true, true)) {
    
            let cartItemsLocal = CartUtils.getCartItemsLocal()
            
            if (cartItemsLocal?.length > 0) {
    
                let cartRequest : CartRequest = {
                    items: [...cartItemsLocal]
                }
                
                try {
                    const cartUpdated : CartVO | undefined = await updateCartByID(CartUtils.getLocalCartId(), cartRequest)
                    if(cartUpdated) {
                        handleLocalCart(cartUpdated);
                    }
                    
                } catch (error) {
                    console.log("Houve um erro ao atualizar o carrinho, estamos buscando o carrinho pelo ID!")
                    getCartByID()
                }
            }
        }
    }
    
    function handleLocalCart(cart: CartVO) {
        setContentCart(cart)
        StorageUtils.setStorageItem(StorageUtils.KEYS.CART_ID, cart?._id ? cart._id : "");
        StorageUtils.setStorageItem(StorageUtils.KEYS.CART, JSON.stringify(cart))
    }
    
    const getCartByID = async () => {
        try {
            if(isAuthenticated(true, true)) {
                let localCartId = CartUtils.getLocalCartId()
                if(localCartId) {
                    const cartFound : CartVO | undefined = await findCartByID(localCartId)
                    if(cartFound) {
                        handleLocalCart(cartFound)
                    } else {
                        CartUtils.clearCartLocal()
                    }
        
                } else {
                    //TODO - Validar se vai exibir a tela de carrinho com a informação de que está vazio com o botão pra EasyCoins
                    CartUtils.clearCartLocal()
                }
            }
            
        } catch (error) {
            CartUtils.clearCartLocal()
            /*handleAlertModal(
                AlertModalType.ERROR,
                "Houve um erro ao localizar seu carrinho, tente novamente!"
            )*/
        }
    }
    
    function removeItemCart(id: string) {
        console.log("Remove Item Cart")
        let cartItemsLocal = CartUtils.getCartItemsLocal();
        console.log("cartItemsLocal", cartItemsLocal)
        
        let idx = cartItemsLocal.map(e => {
            return e.pack._id
        }).indexOf(id)
        console.log("cartItemsLocal - idx", idx)
        
        let temp = cartItemsLocal;
        let temp2 = temp.splice(idx, 1);
        console.log("cartItemsLocal - temp", temp)
        console.log("cartItemsLocal - temp2", temp2)
        
        CartUtils.setCartItemsLocal([...temp])
        handleUpdateCart()
    }
    
    function addOrRemoveItemCart(id: string, add: boolean = true) {
        let cartItemsLocal = CartUtils.getCartItemsLocal();
        let idx = cartItemsLocal.map(e => {
            return e.pack._id
        }).indexOf(id)
        let temp = cartItemsLocal;
        if (add) {
            temp[idx].quantity++;
        } else if (temp[idx].quantity > 1) {
            temp[idx].quantity--;
        } else return;
        CartUtils.setCartItemsLocal([...temp])
        handleUpdateCart()
    }

    const handleUpdateCart = async () => {
        try {
            if(isAuthenticated(true, true)) {
                const cartId = CartUtils.getLocalCartId();
                if(cartId) {
                    let cartItemsLocal = CartUtils.getCartItemsLocal();
                    const cartRequest : CartRequest = {
                        items: [...cartItemsLocal]
                    }
                    const cartUpdated : CartVO | undefined = await updateCartByID(cartId, cartRequest)
                    if(cartUpdated) {
                        setContentCart(cartUpdated)
                    } else {
                        handleAlertModal(
                            AlertModalType.ERROR,
                            "Houve um erro ao atualizar seu carrinho, tente novamente!"
                        )
                    }
                } else {
                    CartUtils.clearCartLocal()
                }
            }

        } catch (error) {
            console.log(error)
            CartUtils.clearCartLocal()
        }
    }
    
    const handleCoupon = async (del: boolean = false) => {
        let id = localStorage.getItem(StorageUtils.KEYS.CART_ID) || '';
        if (coupon && !del) {
            addCoupon(id)
        } else if (del) {
            removeCoupon(id)
        }
    }
    const addCoupon = async (id: string) => {
        try {
            const response = await api.put(`/p/cart/${id}/coupons/${coupon}`, { data: null }, { headers: { "Authorization": `Bearer ${access_token}` } })
            setContentCart(response.data);
            setActiveCoupon(true);

        } catch (error:any) {
            if(error.response) {
                if(error.response.status === 404 || error.response.status === 409) {
                    notify(error.response.data.message, NotificationType.WARNING)
                } else {
                    notify("Houve um erro ao adicionar cupom no carrinho!", NotificationType.ERROR)
                }
            }
        }
    }
    const removeCoupon = async (id: string) => {
        try {
            const response = await api.delete(`p/cart/${id}/coupons/${contentCart?.coupon?.code}`, {
                headers: { "Authorization": `Bearer ${access_token}` }
            })
            setContentCart(response.data);
            setActiveCoupon(false);
            setCoupon('');

        } catch (error) {
            console.log(error)
        }
    }
    
    const notify = (message: string, type: NotificationType) => {
        if(type == NotificationType.SUCCESS) {
            toast.success(message);
        } else if(type == NotificationType.WARNING) {
            toast.warning(message);
        } else if(type == NotificationType.ERROR) {
            toast.error(message);
        }
    }

    return (
        <div className="container">
            <ToastContainer hideProgressBar={true} />
            <main className={style.container}>
                <div className={style.gridContainer}
                    style={{
                        gridTemplateColumns: contentCart ? '' : '1fr'
                    }}
                >
                    {contentCart &&
                        <>
                            <div className={style.itemsCart}>

                                <strong className={style.title}>Sua seleção</strong>
                                <table>
                                    {contentCart?.items?.map((content, index) => {
                                        return (
                                            <tr key={content.pack._id}>
                                                <td onClick={() => removeItemCart(content.pack._id)}>
                                                    <BinIco />
                                                </td>
                                                <td>
                                                    <CoinsIco />
                                                    <div>
                                                        <span>
                                                            {content.pack.name}
                                                        </span><br />
                                                        <span>
                                                            {content.pack.shortDescription}
                                                        </span>
                                                    </div>
                                                </td>

                                                <Qtd
                                                    price={content.price}
                                                    id={content.pack._id}
                                                    quantity={content.quantity}
                                                    addOrRemoveItemCart={addOrRemoveItemCart}
                                                    total={content.subtotal}
                                                />
                                            </tr>
                                        )
                                    })}
                                    <br />
                                </table>
                                <br />
                                <span style={{ fontWeight: 'bolder' }}>Total de Easycoins (somado aos bônus): {contentCart?.earnedCoins} </span>
                            </div>

                            <div className={style.orderSummary}>
                                <strong className={style.title}>Resumo da seleção</strong>

                                <table>
                                    {contentCart && contentCart.items.map((content, index) => {
                                        return (
                                            <tr key={`item-${index}`}>
                                                <th>{content.pack.name} {content.pack.shortDescription}</th>
                                                <td>{content.subtotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                        )
                                    })}
                                </table>
                                <br />

                                <table style={{ border: 'none', marginBottom: '3rem' }}>
                                    <tr>
                                        <th>Sub-total: ({contentCart?.items.length}{contentCart && contentCart.items.length > 1 ? 'produtos' : 'produto'}) </th>
                                        <td>{contentCart?.subTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                    {contentCart?.feesTotal &&
                                    (<tr>
                                        <th>Taxa de serviço</th>
                                        <td>{contentCart?.feesTotal.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</td>
                                    </tr>)
                                    }
                                    {contentCart?.itemDiscount ?
                                        (<tr>
                                            <th>Total de desconto:</th>
                                            <td>{contentCart?.itemDiscount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>) : ''
                                    }
                                    <tr style={{ fontSize: '1.2rem' }}>
                                        <th>Total:</th>
                                        <td>{contentCart?.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>

                                </table>

                                <div className={style.cupomContainer}>
                                    {contentCart.coupon ? (
                                        <div className={style.couponApplied}>
                                            <div>
                                                Código do cupom:
                                            </div>
                                            <div>
                                               {contentCart.coupon.code}
                                            </div>
                                            <div onClick={() => handleCoupon(true)}>
                                                <BinIco />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <span>Possui algum cupom?</span>
                                            <div className={style.buttonGroup}>
                                                <input
                                                    type="text"
                                                    placeholder="digite o código do seu cupom"
                                                    onChange={event => setCoupon(event.target.value)}
                                                    value={coupon}
                                                />
        
                                                <button onClick={() => handleCoupon()}>Aplicar</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <Link to="/formas-de-pagamento" className={style.action}>Avançar</Link>
                                <Link to="/easycoins">Continuar comprando</Link>

                            </div>
                        </>
                    }
                    
                    {!contentCart &&
                        <div className={style.mesageCart}>
                            <strong>Você ainda não adicionou nenhum item ao seu carrinho de compras</strong>
                            <svg width="193" height="192" viewBox="0 0 193 192" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M136.653 51.2822L141.583 30.2632C142.585 25.9911 146.414 22.9688 150.823 22.9688H167.428C172.669 22.9688 176.917 27.1949 176.917 32.4081C176.917 37.6213 172.669 41.8474 167.428 41.8474H158.353L145.91 94.7676L139.13 135.237C139.038 135.787 138.514 136.155 137.966 136.056L35.2006 117.468C34.8261 117.4 34.5225 117.126 34.4174 116.76L15.9784 52.5583C15.7949 51.9191 16.2746 51.2822 16.9396 51.2822H136.653Z" fill="#E1E8F3" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M52.2708 168C58.9328 168 64.3333 162.627 64.3333 156C64.3333 149.373 58.9328 144 52.2708 144C45.6089 144 40.2083 149.373 40.2083 156C40.2083 162.627 45.6089 168 52.2708 168ZM124.646 168C131.308 168 136.708 162.627 136.708 156C136.708 149.373 131.308 144 124.646 144C117.984 144 112.583 149.373 112.583 156C112.583 162.627 117.984 168 124.646 168Z" fill="#E1E8F3" />
                            </svg>
                            <Link to="/easycoins">Voltar à tela de compras</Link>

                        </div>
                    }
                </div>
            </main>
            {alertModalOpen &&
                (
                    <AlertModal type={alertModalType} onClose={() => setAlertModalOpen(false)} message={alertModalMessage} />
                )
            }
        </div>
    )
}