import React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import { isAuthenticated } from '../../services/AuthService'
interface PublicRouteProps extends RouteProps {
    component: any;
}

const PublicRoute = (props: PublicRouteProps) => {
    const { component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(routeProps) => (
                <Component {...routeProps} />
            )}
        />
    );
};

export default PublicRoute;