import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Account } from '../../modelos/Account';
import { UserRegister } from '../../modelos/UserRegister';

import { removePunctuation, userRegister } from '../../services/RegisterService'
import { auth, isAuthenticated } from '../../services/AuthService'

import { FirstRegister } from './first-register/';
import { FinalRegister } from './final-register/';
import { ModalRegister } from './modal-register/';

import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export default function Register() {
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [cpf, setCpf] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  const [step, setStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');
  function nextStep() { setStep(2); }

  useEffect(() => {
    if(isAuthenticated()) history.push("/minha-conta");
  },[]);

  async function handleRegister() {
    try {
      const account: Account = {
        id: email,
        pass: password,
        checkPass: confPassword,
      };

      const user: UserRegister = {
        fullName: name,
        email: email,
        birthday: birthdate,
        cpfCnpj: removePunctuation(cpf),
        nickname: username,
        account: account
      };


      await userRegister(user);
      await auth({
        id: email,
        password
      });
      setModalType('success');
      setOpenModal(true);

    } catch (err: any) {
      let code = err.response.data.httpStatusCode;
      let errorMessage = "";
      switch (code) {
        case 409:
          errorMessage = "<h3>Já existe um cliente cadastrado com este mesmo EMAIL ou CPF</h3>";
          break;
        case 422:
          errorMessage = "Não foi possível efetuar o seu cadastro, veja os erros abaixo:";
          let errors = err.response.data.validationErrors.map(
            (value: { fieldName: string, message: string, restrictionType: string }) => {
              let th = "", td = "";
              switch(value.fieldName){
                case 'customer.cpfCnpj': th = "CPF"; break;
                default: th = value.fieldName.replace('customer.',''); break;
              }
              if(value.restrictionType === 'MATCH') td = "Formato incorreto!";
              else td = value.message;

              return `<tr>
                <th>${th}</th>
                <td>${td}</td>
              </tr>`;
            }
          );
          errorMessage+=`
            <br/>
            <table>
              <tbody>${errors.join('')}</tbody>
            </table>
          `;
          setStep(1);
          break;
        case 400: case 500:
          errorMessage = "<h3>Houve um erro ao efetuar seu cadastro, tente novamente mais tarde</h3>";
          break;
      }
      setMessage(errorMessage);
      setOpenModal(true);
      setModalType('error');
    }
  }

  return (
    <div className="container">
      <div className={styles.container}>
        <header className={styles.breadcrumbs}>
          <ul>
            <li>
              <Link to="/">Pagina Inicial</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "var(--color-text-medium)" }}><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>
            </li>
            <li>
              <Link to="/cadastro">Cadastre-se</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: "var(--color-text-medium)" }}><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg>
            </li>
            <li><a href="javascript:;">Dados Pessoais</a></li>
          </ul>
        </header>
        <div className={styles.stepGroup}>
          <span
            className={`${styles.stepItem} ${step === 1 ? styles.active : ''}`}
            onClick={() => { if(step == 2) setStep(1); }}
            style={{ cursor: step == 2 ? 'pointer' : 'default' }}
          >1</span>
          <hr className={styles.separator} />
          <span className={`${styles.stepItem} ${step === 2 ? styles.active : ''}`}>2</span>
        </div>
        {step == 1 ? (
          <FirstRegister
            name={name} setName={setName}
            email={email} setEmail={setEmail}
            birthdate={birthdate} setBirthdate={setBirthdate}
            cpf={cpf} setCpf={setCpf}
            next={nextStep}
          />
        ) : (
          <FinalRegister
            username={username} setUsername={setUsername}
            password={password} setPassword={setPassword}
            confPassword={confPassword} setConfPassword={setConfPassword}
            handleRegister={handleRegister}
            goBack={() => setStep(1)}
          />
        )}
      </div>
      {openModal && (
        <ModalRegister
          type={modalType}
          onClose={() => setOpenModal(false)}
          content={message}
        />
      )}
    </div>
  )
}