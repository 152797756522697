import axios, {AxiosResponse} from 'axios';

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST

export const getCategories = async () => {
    try {
        const url: string = `${BACKEND_HOST}/api/o/category`
        const response: AxiosResponse = await axios.get(url)
        return response.data
    } catch (err:any) {
        throw new Error(err)
    }
}